<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown v-if="notifications.length > 0" vs-custom-content vs-trigger-click class="cursor-pointer">
    <feather-icon icon="BellIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="notifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ notifications.length }} {{ $t('vue.new') }}</h3>
        <p class="opacity-75">{{ $t('vue.appNotifications') }}</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-12 p-1" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items mb-5">
          <li v-for="ntf in notifications" :key="ntf.index" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex w-full">
              <div class="w-full mx-2">
                <feather-icon style="float: left" :icon="ntf.icon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
                <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{ $t(ntf.title) }}</span>
                <p class="mt-2">
                  <small>{{ ntf.msg }}</small>
                </p>
                <p class="mt-2 ml-2">
                  <i>
                    <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.date) }}</small>
                  </i>
                </p>
              </div>
              <div class="flex flex-column">
                <vs-button color="primary" type="flat" icon="close" @click="remove(ntf)"></vs-button>
                <vs-button class="mt-5 ml-4" color="primary" icon-pack="feather" icon="icon-external-link" @click="goToIncomingRequests(ntf)">{{
                  $t('vue.join')
                }}</vs-button>
              </div>
            </div>
          </li>
        </ul>
      </component>
      <div class="checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer">
        <vs-button type="line" @click="clear()">{{ $t('vue.clear') }}</vs-button>
      </div>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'unreadNotifications'
    }),
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    },
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  methods: {
    async goToIncomingRequests(ntf) {
      await this.$db.collection('visitors').doc(ntf.id).set({ audioPlayed: true }, { merge: true })
      this.$store.commit('NOTIFICATIONS_REMOVE', ntf)
      this.$router.push('/incoming-requests').catch(() => { })
    },
    async joinSurfly(ntf) {
      let surlfyURL = ntf.surlfyURL
      const joinURL = ntf.joinURL
      const id = ntf.id
      const ref = this.$database.ref(`/status/${id}`)
      const event = {}
      event.joinedSession = true
      event.joinURL = joinURL
      if (this.activeUserInfo.displayName) {
        event.agent = this.activeUserInfo.displayName
      } else {
        event.agent = this.activeUserInfo.firstname
      }
      surlfyURL += `?name=${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}&email=${this.activeUserInfo.email}&agent_id=${this.activeUserInfo.uid}`
      event.surlfyURL = surlfyURL
      await ref.update(event)
      const visitorData = {
        connectedAgent: event.agent,
        connectedAgentId: this.activeUserInfo.uid,
        agentId: this.activeUserInfo.uid,
        lastConnectedAgentId: this.activeUserInfo.uid,
        lastConnectedAgent: event.agent,
        lastConnectedAgentImage: this.activeUserInfo.photoURL || null,
        audioPlayed: true,
        callTypeState: 'agent-joined'
      }
      await this.$db.collection('visitors').doc(id).set(visitorData, { merge: true })
      this.$store.commit('NOTIFICATIONS_REMOVE', ntf)

      setTimeout(() => {
        if (this.isSafari) {
          location.href = surlfyURL
        } else {
          const win = window.open(surlfyURL, '_blank')
          if (win) {
            win.focus()
          } else {
            setTimeout(() => {
              if (win) win.focus()
            }, 2000)
          }
        }
      }, 2000)
    },
    elapsedTime(date) {
      return dayjs.unix(date.seconds).tz(this.$defaultTimezone).fromNow()
    },
    remove(ntf) {
      this.$store.commit('NOTIFICATIONS_REMOVE', ntf)
    },
    clear() {
      this.$store.commit('NOTIFICATIONS_CLEAR')
    }
  }
}
</script>
<style>
.flex-column {
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
}
.notification-dropdown,
.cart-dropdown {
  width: 475px;
  max-width: calc(100% - 20px);
}
</style>
