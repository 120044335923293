var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "vx-navbar-wrapper navbar-full p-0",
        class: {
          _locked: _vm.lockedUI,
        },
      },
      [
        _c(
          "vs-navbar",
          {
            staticClass: "navbar-custom navbar-skelton",
            class: _vm.navbarClasses,
            style: _vm.navbarStyle,
            attrs: { color: _vm.navbarColor },
          },
          [
            _vm.windowWidth >= 992
              ? _c("bookmarks", { attrs: { navbarColor: _vm.navbarColor } })
              : _vm._e(),
            _c("router-link", {
              staticClass: "vx-logo cursor-pointer mx-auto flex items-center",
              attrs: { to: "/", custom: "" },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function (ref) {
                    var navigate = ref.navigate
                    var href = ref.href
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "cursor-pointer mx-auto flex items-center",
                          attrs: { href: href },
                          on: { click: navigate },
                        },
                        [
                          _c("img", {
                            staticClass:
                              "mr-1 fill-current navbar-horizontal-logo",
                            attrs: {
                              src: require("@/assets/images/logo/vega_logo.svg"),
                            },
                          }),
                        ]
                      ),
                    ]
                  },
                },
              ]),
            }),
            _c("notification-drop-down"),
            _c("profile-drop-down"),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }