var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.callCenterUserWorkTime
    ? _c("div", { staticClass: "cc-banner", class: _vm.bannerAnimation }, [
        _c("div", { staticClass: "cc-banner__text" }, [
          _vm.canShowStartButton
            ? _c("span", { staticClass: "cc-banner__text_animation" }, [
                _vm._v(_vm._s(_vm.$t("banners.callCenterStartWorkTime"))),
              ])
            : _c("div", [
                _c("span", { staticClass: "uppercase" }, [
                  _vm._v(_vm._s(_vm.$t("vue.totalWorkTime")) + " :"),
                ]),
                _c("span", { staticClass: "cc-banner__text_work-time" }, [
                  _vm._v(" " + _vm._s(_vm.TOTAL_WORK_TIME)),
                ]),
                _c("span", { staticClass: "uppercase ml-2" }, [
                  _vm._v(_vm._s(_vm.$t("vue.totalPausedTime")) + " : "),
                ]),
                _c("span", { staticClass: "cc-banner__text_pause-time" }, [
                  _vm._v(" " + _vm._s(_vm.TOTAL_PAUSED_TIME)),
                ]),
                _vm.callCenterUserWorkTime.totalSessionDuration
                  ? _c("span", { staticClass: "uppercase ml-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.totalSessionTime")) + " : "),
                    ])
                  : _vm._e(),
                _vm.callCenterUserWorkTime.totalSessionDuration
                  ? _c(
                      "span",
                      { staticClass: "cc-banner__text_session-time" },
                      [_vm._v(" " + _vm._s(_vm.TOTAL_SESSION_TIME))]
                    )
                  : _vm._e(),
                _vm.callCenterUserWorkTime.totalReworkTime
                  ? _c("span", { staticClass: "uppercase ml-2" }, [
                      _vm._v(_vm._s(_vm.$t("vue.totalReworkTime")) + " : "),
                    ])
                  : _vm._e(),
                _vm.callCenterUserWorkTime.totalReworkTime
                  ? _c("span", { staticClass: "cc-banner__text_rework-time" }, [
                      _vm._v(" " + _vm._s(_vm.TOTAL_REWORK_TIME)),
                    ])
                  : _vm._e(),
              ]),
        ]),
        _c(
          "div",
          { staticClass: "flex flex-row justify-center items-center" },
          [
            _vm.canShowStartButton
              ? _c(
                  "div",
                  {
                    staticClass:
                      "cc-banner__startstop-button cc-banner__startstop-button-start flex flex-row justify-center items-center",
                    class: _vm.isUpdating
                      ? "cc-banner__startstop-button-start-update-in-progress"
                      : "",
                    on: {
                      click: function ($event) {
                        return _vm.startWorkTime()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cc-banner__startstop-button_text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.start")))]
                    ),
                    _c("div", [_c("play-icon")], 1),
                  ]
                )
              : _vm._e(),
            _vm.canShowPauseButton
              ? _c(
                  "div",
                  {
                    staticClass:
                      "ml-4 flex flex-row justify-center items-center",
                    on: {
                      click: function ($event) {
                        return _vm.pauseWork()
                      },
                    },
                  },
                  [
                    _c("div", { staticClass: "cc-banner__text" }, [
                      _vm._v("AT WORK"),
                    ]),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cc-banner__pause-button flex flex-row justify-center items-center",
                        class: _vm.isUpdating
                          ? "cc-banner__pause-button-update-in-progress"
                          : "",
                      },
                      [_c("div", [_c("pause-icon")], 1)]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.canShowResumeButton
              ? _c(
                  "div",
                  {
                    staticClass:
                      "ml-4 flex flex-row justify-center items-center",
                    on: {
                      click: function ($event) {
                        return _vm.resumeWorkAt()
                      },
                    },
                  },
                  [
                    _vm.callCenterUserWorkTime.currentWorkState ===
                    _vm.WORKTIME_STATUS.PAUSED
                      ? _c("div", { staticClass: "cc-banner__text" }, [
                          _vm._v("IN PAUSE"),
                        ])
                      : _vm._e(),
                    _vm.callCenterUserWorkTime.currentWorkState ===
                    _vm.WORKTIME_STATUS.ENDED
                      ? _c("div", { staticClass: "cc-banner__text" }, [
                          _vm._v("ENDED"),
                        ])
                      : _vm._e(),
                    _c(
                      "div",
                      {
                        staticClass:
                          "cc-banner__resume-button flex justify-center items-center",
                        class: _vm.isUpdating
                          ? "cc-banner__resume-button-update-in-progress"
                          : "",
                      },
                      [_c("div", [_c("play-icon")], 1)]
                    ),
                  ]
                )
              : _vm._e(),
            _vm.canShowEndButton
              ? _c(
                  "div",
                  {
                    staticClass:
                      "ml-4 cc-banner__startstop-button cc-banner__startstop-button-end flex flex-row justify-center items-center",
                    class: _vm.isUpdating
                      ? "cc-banner__startstop-button-end-update-in-progress"
                      : "",
                    on: {
                      click: function ($event) {
                        return _vm.stopWorkTime()
                      },
                    },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "cc-banner__startstop-button_text" },
                      [_vm._v(_vm._s(_vm.$t("inputs.end")))]
                    ),
                    _c(
                      "div",
                      { staticClass: "cc-banner__startstop-button_icon-end" },
                      [_c("stop-icon")],
                      1
                    ),
                  ]
                )
              : _vm._e(),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }