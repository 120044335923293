var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        xmlns: "http://www.w3.org/2000/svg",
        viewBox: "0 0 24 24",
      },
    },
    [_c("path", { attrs: { fill: _vm.color, d: "M18,18H6V6H18V18Z" } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }