var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasVegaTrialExpired && _vm.isPopupDisplayed,
          expression: "hasVegaTrialExpired && isPopupDisplayed",
        },
      ],
      key: _vm.updateKey,
      staticClass: "vte-overlay",
    },
    [
      _c("div", { staticClass: "vte-overlay-content" }, [
        _c("div", { staticClass: "vte-overlay-content-main" }, [
          _c("div", { staticClass: "vte-overlay-content-main--message" }, [
            _vm._v(_vm._s(_vm.$t("vega.expired.header"))),
          ]),
          _c("div", { staticClass: "vte-overlay-content-main--description" }, [
            _vm._v(_vm._s(_vm.$t("vega.expired.description"))),
          ]),
          _c(
            "div",
            {
              staticClass: "vte-overlay-content-main-action--activate",
              on: { click: _vm.onActivateVega },
            },
            [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.$t("vega.activateVega")) +
                  "\n        "
              ),
              _c("img", {
                staticClass: "vte-overlay-content-main-action--activate--icon",
                attrs: {
                  src: require("@/assets/images/register-funnel/forward-arrow.png"),
                  width: "18px",
                  height: "16.2px",
                  alt: "Next",
                },
              }),
            ]
          ),
          _c(
            "div",
            {
              staticClass: "vte-overlay-content-main-action--deactivate",
              on: { click: _vm.onDeactivateVega },
            },
            [_vm._v(_vm._s(_vm.$t("vega.deactivateVega")))]
          ),
          _vm._m(0),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "vte-overlay-content-main--analyze" }, [
      _c("img", {
        attrs: {
          src: require("@/assets/images/register-funnel/vega-analyze.png"),
          width: "285",
          height: "207",
          alt: "Vega analyze",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }