/*=========================================================================================
  File Name: sidebarItems.js
  Description: Sidebar Items list. Add / Remove menu items from here.
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
==========================================================================================*/

export default [
  {
    url: '/dashboard',
    name: 'routes.home',
    slug: 'home',
    icon: 'DashboardIcon',
    id: 'nmi_Home',
    featherIcon: false,
    iconPack: 'custom-icons'
  },
  {
    url: '/visitors',
    name: 'routes.visitors',
    slug: 'visitors',
    icon: 'MonitorIcon',
    id: 'nmi_Visitors',
    iconPack: 'feather-icons',
    featherIcon: true,
    disallowedRoles: ['view-only']
  },
  {
    url: '/incoming-requests',
    name: 'routes.incomingRequests',
    slug: 'incoming-requests',
    icon: 'PhoneCallIcon',
    iconPack: 'feather-icons',
    id: 'nmi_IncomingRequests',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze']
  },
  {
    url: '/lead-overview',
    name: 'routes.leadoverview',
    slug: 'lead-overview',
    icon: 'TrendingUpIcon',
    iconPack: 'feather-icons',
    id: 'nmi_LeadOverview',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze']
  },
  {
    url: '/online-meeting',
    name: 'routes.onlineMeetings',
    slug: 'online-meeting',
    iconPack: 'feather-icons',
    icon: 'MousePointerIcon',
    id: 'nmi_OnlineMeeting',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze']
  },
  {
    url: '/web-connect',
    name: 'routes.dialog',
    slug: 'dialog',
    icon: 'CodeIcon',
    id: 'nmi_Dialog',
    iconPack: 'feather-icons',
    featherIcon: true,
    disallowedRoles: ['agent', 'view-only', 'vega-analyze']
  },
  {
    url: '/vega-measurement',
    name: 'routes.vegaMeasurement',
    slug: 'vega-measurement',
    id: 'nmi_Vega',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze'],
    icon: 'StarIcon',
    iconPack: 'feather-icons'
  },
  {
    url: '/vega',
    name: 'routes.vega',
    slug: 'vega',
    id: 'nmi_Vega',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze'],
    icon: 'StarIcon',
    iconPack: 'feather-icons'
  },
  {
    url: '/qr-connect',
    name: 'routes.campaigns',
    slug: 'campaigns',
    icon: 'qr_code',
    iconPack: 'material-icons-outlined',
    id: 'nmi_Campaigns',
    featherIcon: false,
    disallowedRoles: ['view-only', 'vega-analyze']
  },
  {
    url: '/gpt-connect',
    name: 'routes.gptConnect',
    slug: 'gpt-connect',
    id: 'nmi_GptConnect',
    featherIcon: true,
    disallowedRoles: ['view-only', 'vega-analyze'],
    icon: 'GptConnectIcon',
    iconPack: 'custom-icons'
  },
  {
    url: '/translations',
    name: 'routes.translations',
    slug: 'translations',
    iconPack: 'feather-icons',
    icon: 'GlobeIcon',
    id: 'nmi_Translations',
    featherIcon: true,
    disallowedRoles: ['agent', 'supervisor', 'view-only', 'vega-analyze']
  },
  /*{
    url: '/chat',
    name: 'Chat',
    slug: 'chat',
    icon: 'MessageSquareIcon',
  },
   {
    url: '/screensharing',
    name: 'routes.screensharing',
    slug: 'screensharing',
    icon: 'MonitorIcon',
  }, */
  {
    url: '/companies',
    name: 'routes.companies',
    slug: 'companies',
    icon: 'list_alt',
    iconPack: 'material-icons-outlined',
    id: 'nmi_Companies',
    featherIcon: false
  },
  {
    url: '/accounts',
    name: 'routes.accounts',
    slug: 'accounts',
    icon: 'supervised_user_circle',
    iconPack: 'material-icons-outlined',
    id: 'nmi_Accounts',
    featherIcon: false
  },
  {
    url: '/in-app-messaging',
    name: 'routes.inAppMessaging',
    slug: 'in-app-messaging',
    icon: 'all_inbox',
    iconPack: 'material-icons-outlined',
    id: 'nmi_InAppMessaging',
    featherIcon: false
  },
  {
    url: '/invoices',
    name: 'routes.invoices',
    slug: 'invoices',
    icon: 'receipt',
    iconPack: 'material-icons-outlined',
    id: 'nmi_Invoices',
    featherIcon: false
  },
  {
    url: '/promo-codes',
    name: 'routes.promoCodes',
    slug: 'promo-codes',
    icon: 'school',
    iconPack: 'material-icons-outlined',
    id: 'nmi_PromoCodes',
    featherIcon: false
  }
]
