<template>
  <!-- NOTIFICATIONS -->
  <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer" v-if="notifications.length > 0">
    <feather-icon icon="ArchiveIcon" class="cursor-pointer mt-1 sm:mr-6 mr-2" :badge="notifications.length" />

    <vs-dropdown-menu class="notification-dropdown dropdown-custom vx-navbar-dropdown">
      <div class="notification-top text-center p-5 bg-primary text-white">
        <h3 class="text-white">{{ notifications.length }} New</h3>
        <p class="opacity-75">Download Notifications</p>
      </div>

      <component :is="scrollbarTag" ref="mainSidebarPs" class="scroll-area--nofications-dropdown p-0 mb-10" :settings="settings" :key="$vs.rtl">
        <ul class="bordered-items">
          <li v-for="ntf in notifications" :key="ntf.id" class="flex justify-between px-4 py-4 notification cursor-pointer">
            <div class="flex items-start">
              <feather-icon :icon="ntf.icon" :svgClasses="[`text-${ntf.category}`, 'stroke-current mr-1 h-6 w-6']"></feather-icon>
              <div class="mx-2">
                <span class="font-medium block notification-title" :class="[`text-${ntf.category}`]">{{ ntf.title }}</span>
                <small>{{ ntf.msg }}</small>
                <small>{{ ntf.filename }}</small>
              </div>

              <div class="flex">
                <vs-button class="mr-2" color="success" type="gradient" icon="download" @click="downloadFile(ntf)"></vs-button>
                <vs-button class="mr-5" color="danger" type="gradient" icon="delete_forever" @click="deleteFile(ntf)"></vs-button>
              </div>
            </div>
            <small class="mt-1 whitespace-no-wrap">{{ elapsedTime(ntf.time) }}</small>
          </li>
        </ul>
      </component>
    </vs-dropdown-menu>
  </vs-dropdown>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import { mapGetters, mapActions } from 'vuex'

const dayjs = require('dayjs')

export default {
  components: {
    VuePerfectScrollbar
  },
  data() {
    return {
      unsubscribeNotifications: null,

      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.6
      }
    }
  },
  computed: {
    ...mapGetters({
      notifications: 'invoice/notifications'
    }),
    scrollbarTag() {
      return this.$store.getters.scrollbarTag
    }
  },
  created() {
    this.subscribeNotifications()
  },
  beforeDestroy() {
    if (this.unsubscribeNotifications) {
      this.unsubscribeNotifications()
    }
  },
  methods: {
    ...mapActions({
      addNotification: 'invoice/addNotification',
      updateNotification: 'invoice/updateNotification',
      removeNotification: 'invoice/removeNotification',
      clearNotifications: 'invoice/clearNotifications'
    }),
    downloadFile(notification) {
      const link = document.createElement('a')
      link.href = notification.link
      link.click()
    },
    async deleteFile(notification) {
      await this.$vs.loading()
      await this.$db.collection('tempfile-notifications').doc(notification.id).delete()
      await this.$vs.loading.close()
    },
    subscribeNotifications() {
      const vm = this
      if (vm.unsubscribeNotifications) {
        vm.unsubscribeNotifications()
      }
      const query = vm.$db.collection('tempfile-notifications').orderBy('time', 'desc').limit(500)
      vm.unsubscribeNotifications = query.onSnapshot(async (ref) => {
        vm.clearNotifications()
        ref.docs.forEach((doc) => {
          const notification = doc.data()
          notification.id = doc.id
          vm.addNotification(notification)
        })
      })
    },
    elapsedTime(startTime) {
      return dayjs.unix(startTime.seconds).utc().fromNow()
    },
    // Method for creating dummy notification time
    randomDate({ hr, min, sec }) {
      const date = new Date()

      if (hr) date.setHours(date.getHours() - hr)
      if (min) date.setMinutes(date.getMinutes() - min)
      if (sec) date.setSeconds(date.getSeconds() - sec)

      return date
    }
  }
}
</script>
