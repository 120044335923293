<template>
  <svg :width="width" :height="height" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24">
    <path :fill="color" d="M18,18H6V6H18V18Z" />
  </svg>
</template>

<script>
export default {
  name: 'StopIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 15
    },
    height: {
      type: Number,
      default: 15
    }
  }
}
</script>
