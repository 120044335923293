<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  name: 'WidgetsAssigned',
  render() {
    // This component does not render anything.
    return null
  },

  data() {
    return {
      unsubscribeWidgetsAssigned: null,
      widgetsAssignedLC: []
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      widgetsAssigned: 'widgetsAssigned'
    })
  },

  mounted() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeWidgetsAssigned()
    }
  },

  beforeDestroy() {
    if (this.unsubscribeWidgetsAssigned) {
      this.unsubscribeWidgetsAssigned()
    }
  },

  watch: {
    'activeUserInfo.company'() {
      if (this.activeUserInfo && this.activeUserInfo.company) {
        this.subscribeWidgetsAssigned()
      }
    }
  },
  methods: {
    ...mapMutations({
      updateWidgetsAssigned: 'UPDATE_WIDGETS_ASSIGNED'
    }),
    subscribeWidgetsAssigned() {
      const vm = this
      if (vm.unsubscribeWidgetsAssigned) {
        vm.unsubscribeWidgetsAssigned()
      }
      const query = this.$db.collection('company').doc(this.activeUserInfo.company).collection('user-groups')
      vm.unsubscribeWidgetsAssigned = query.onSnapshot((snapshot) => {
        this.widgetsAssignedLC = []
        snapshot.docs.forEach((doc) => {
          const usergroup = doc.data()
          if (
            usergroup &&
            usergroup.widgets &&
            usergroup.widgets.length > 0 &&
            usergroup.users &&
            usergroup.users.find((x) => x.id === this.activeUserInfo.uid)
          ) {
            this.widgetsAssignedLC = [...new Set(this.widgetsAssignedLC.concat(usergroup.widgets))]
          }
        })
        this.updateWidgetsAssigned(this.widgetsAssignedLC)
      })
    }
  }
}
</script>
