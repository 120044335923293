<template>
  <div></div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import { muser } from '@/mixins/muser'
export default {
  data() {
    return {
      unsubscribeUsers: null,
      unsubscribeUser: null,
      users: []
    }
  },
  mixins: [muser],
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      callCenterSettings: 'call_center_settings'
    })
  },
  watch: {
    callCenterSettings() {
      if (this.callCenterSettings) {
        this.subscribeUsers()
      }
    },
    company() {
      if (this.company) {
        this.subscribeUsers()
      }
    }
  },
  created() {
    const vm = this
    if (vm.activeUserInfo && vm.activeUserInfo.company) {
      vm.subscribeUsers()
      vm.subscribeUser()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeUsers) {
      this.unsubscribeUsers()
    }
    if (this.unsubscribeUser) {
      this.unsubscribeUser()
    }
  },
  methods: {
    ...mapMutations({
      updateUserInfo: 'UPDATE_USER_INFO',
      addUpdateUser: 'ADD_UPDATE_USER',
      removeUser: 'REMOVE_USER',
      updateAppSumoDeleteUsers: 'UPDATE_APPSUMO_DELETE_USERS'
    }),
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeUsers() {
      const vm = this
      if (!vm.activeUserInfo) {
        return
      }
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(vm.activeUserInfo.role)) {
        return
      }

      if (vm.unsubscribeUsers) {
        vm.unsubscribeUsers()
      }
      let query = vm.$db.collection('users').where('isActive', '==', true).orderBy('created', 'desc').limit(100)

      if (vm.company && vm.company.isCallCenter && vm.callCenterSettings) {
        const companies = vm.callCenterSettings.filter((x) => x.approved === 'confirmed').map((x) => x.customerId)
        companies.push(vm.activeUserInfo.company)
        query = query.where('company', 'in', companies)
      } else {
        const companies = vm.callCenterSettings.filter((x) => x.approved === 'confirmed').map((x) => x.callCenterId)
        companies.push(vm.activeUserInfo.company)
        query = query.where('company', 'in', companies)
      }

      vm.unsubscribeUsers = query.onSnapshot((snapshot) => {
        snapshot.docChanges().forEach((change) => {
          const userData = change.doc.data()
          userData.id = change.doc.id
          if (change.type === 'added' || change.type === 'modified') {
            let displayName = userData.displayName
            if (displayName.indexOf('@') > -1 && userData.firstname) {
              displayName = `${userData.firstname} ${userData.lastname}`
            }

            const _role = userData.role ? userData.role : userData.isAgent ? 'agent' : userData.isSupervisor ? 'supervisor' : 'admin'

            let _created = userData.created && userData.created.seconds ? userData.created.seconds : 0
            if (change.doc.id === vm.activeUserInfo.uid) {
              _created = Number.POSITIVE_INFINITY
            }

            let sort = 0
            if (vm.company && vm.company.createdBy === change.doc.id) {
              sort = -2
            }
            if (vm.activeUserInfo && vm.activeUserInfo.uid === change.doc.id) {
              sort = -1
            }

            const user = {
              id: change.doc.id,
              company: userData.company,
              name: displayName,
              image: userData.photoURL,
              availableTimeSlots: userData.availableTimeSlots || null,
              isActive: userData.isActive,
              isSynced: userData.isSyncedCalendar,
              supervisorId: userData.supervisorId || null,
              emailPending: userData.emailPending,
              displayName: userData.displayName,
              firstname: userData.firstname,
              lastname: userData.lastname,
              email: userData.email,
              photoURL: userData.photoURL,
              hasSetPassword: userData.hasSetPassword,
              provider: userData.provider,
              userRole: _role,
              created: _created,
              inviteStatus: userData.inviteStatus,
              isActiveConsultant: typeof userData.isActiveConsultant === 'undefined' ? true : userData.isActiveConsultant,
              sort
            }
            vm.addUpdateUser(user)
          }

          if (change.type === 'removed') {
            vm.removeUser(userData)
          }
        })
      })
    },
    subscribeUser() {
      const vm = this
      if (vm.unsubscribeUser) {
        vm.unsubscribeUser()
      }
      const query = vm.$db.collection('users').doc(this.activeUserInfo.uid)

      vm.unsubscribeUser = query.onSnapshot(async (ref) => {
        if (ref && ref.data()) {
          const user = ref.data()
          if (typeof user.isQuickGuideStarted === 'undefined') {
            user.isQuickGuideStarted = false
          }
          if (typeof user.isQuickGuideInProgress === 'undefined') {
            user.isQuickGuideInProgress = false
          }
          if (typeof user.isQuickGuideFinished === 'undefined') {
            user.isQuickGuideFinished = false
          }

          if (user && user.isDeleted) {
            await this.USER_LOGOUT()
          }
          this.updateUserInfo(user)
          this.$serverBus.$emit('user-updated')
        }
      })
    }
  }
}
</script>
