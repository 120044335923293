<template>
  <div class="in-app-messaging__wrapper">
    <div
      v-show="bannersCarouselSettings && (canShowSwiper || canShowCCBanner || canShowTrialBanner || canShowFreePlanBanner)"
      class="swiper"
      ref="inAppMessagingCarousel"
      :options="bannersCarouselSettings"
    >
      <div class="swiper-wrapper">
        <div v-if="canShowCCBanner && activeUserInfo" class="swiper-slide" :key="'cc-banner'">
          <call-center-worktime-banner />
        </div>
        <div v-if="(canShowTrialBanner || canShowFreePlanBanner) && !isMobileApp" class="swiper-slide" :key="'upgrade-banner'">
          <upgrade-banner />
        </div>
        <template v-for="banner in banners">
          <div v-if="canShowBanner(banner)" class="swiper-slide" :key="banner.id">
            <in-app-messaging-banner :banner="banner" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import _ from 'underscore'

import Swiper, { Autoplay, EffectFade, EffectFlip, EffectCoverflow } from 'swiper'
import 'swiper/swiper-bundle.min.css'
Swiper.use([Autoplay, EffectFade, EffectFlip, EffectCoverflow])

import { Device } from '@capacitor/device'

import UpgradeBanner from '@/components/banners/UpgradeBanner.vue'
import CallCenterWorktimeBanner from '@/components/banners/CCWorktimeBanner.vue'
import InAppMessagingBanner from '@/views/pages/in-app-messaging/InAppMessagingBanner'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')
const isBetween = require('dayjs/plugin/isBetween')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)
dayjs.extend(isBetween)

export default {
  components: {
    UpgradeBanner,
    CallCenterWorktimeBanner,
    InAppMessagingBanner
  },
  data() {
    return {
      isMobileApp: false,
      currentDay: dayjs(),
      swiper: null,
      swiperHeight: 44,
      showSlider: false
    }
  },
  watch: {
    swiper: {
      deep: true,
      handler(e) {
        if (e && e.slides && e.slides.length && e.snapIndex && e.slides[e.snapIndex].clientHeight && e.snapIndex === 0) {
          this.swiperHeight = e.slides[e.snapIndex].clientHeight || 44
          this.$serverBus.$emit('swiper-height-updated-on-init', this.swiperHeight)
        }
      }
    },
    banners(newVal, oldVal) {
      if (newVal !== oldVal) {
        const isAnyEnabled = newVal.some((el) => el.isEnabled)
        if (!isAnyEnabled) {
          this.showSlider = false
        } else {
          this.showSlider = true
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      banners: 'banners',
      bannersCarouselSettings: 'swiperOptions',
      company: 'company',
      activeUserInfo: 'activeUser'
    }),
    canShowSwiper: {
      get() {
        return this.showSlider
      },
      set(val) {
        this.showSlider = val
      }
    },
    canShowCCBanner() {
      if (!this.company) {
        return false
      }

      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      if (
        claims.includes('call-center') &&
        this.company.expiryDate &&
        dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds)) &&
        this.company.isCallCenter
      ) {
        return true
      }
      return false
    },
    canShowTrialBanner() {
      if (this.company && this.company.paymentStatus) {
        switch (this.company.paymentStatus) {
          case 'trial':
          case 'expired':
            return true

          case 'on-trial':
            return this.company && !this.company.currentInvoiceId && !this.company.mollieSubscriptionId

          default:
            return false
        }
      }
      return false
    },
    canShowFreePlanBanner() {
      if (this.company && this.company.paymentStatus && this.company.planType) {
        if (
          (this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'deleted') &&
          !this.company.subscriptionPlanId &&
          this.company.planType === 'free'
        ) {
          return true
        }
      }
      return false
    }
  },

  created() {
    this.checkIsMobileApp()
  },

  mounted() {
    const debouncedInit = _.debounce(this.initSwiper, 1000)
    this.$serverBus.$on('in-app-messaging-carousel-updated', () => {
      this.destroySwiper()
      debouncedInit()
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('in-app-messaging-carousel-updated')
    this.destroySwiper()
  },
  methods: {
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    destroySwiper(deleteInstance = true, cleanStyles = true) {
      if (this.swiper) {
        this.swiper.destroy(deleteInstance, cleanStyles)
      }
    },
    initSwiper() {
      this.swiper = new Swiper(this.$refs.inAppMessagingCarousel, this.bannersCarouselSettings)
      this.swiper.on('slideChange', (e) => {
        if (e && e.slides && e.slides.length && e.snapIndex >= 0 && e.slides[e.snapIndex].clientHeight) {
          this.swiperHeight = e.slides[e.snapIndex].clientHeight || 44
          this.$serverBus.$emit('swiper-height-updated', this.swiperHeight)
        }
      })
    },
    canShowBanner(banner) {
      const { selectedAudience, dateStart, dateEnd, isEnabled } = banner

      if (selectedAudience && selectedAudience.length && this.company && dateEnd && isEnabled) {
        const startedAt = dayjs(dateStart)
        const expiresAt = dayjs(dateEnd)

        const isExpired = dayjs().isSameOrAfter(expiresAt, 'day')
        const selectedAudienceIds = selectedAudience.map((el) => el.ids)
        const normalizedSelectedAudienceIds = [].concat(...selectedAudienceIds)

        const isFreeOrTrial =
          this.company.paymentStatus &&
          (this.company.paymentStatus === 'trial' || this.company.paymentStatus === 'free') &&
          (normalizedSelectedAudienceIds.includes('13890') || normalizedSelectedAudienceIds.includes(13890))

        const isMollieTrial =
          this.company.paymentStatus && this.company.paymentStatus === 'on-trial' && normalizedSelectedAudienceIds.includes('M_MOLLIE_TRIAL')

        if (!isExpired) {
          if (normalizedSelectedAudienceIds.includes(this.company.subscriptionPlanId || +this.company.subscriptionPlanId) || isFreeOrTrial || isMollieTrial) {
            if (startedAt) {
              const isBetween = this.currentDay.isBetween(startedAt, expiresAt)
              if (isBetween) {
                return true
              }
              return false
            }
            return true
          }
        }

        return false
      }

      return false
    }
  }
}
</script>

<style lang="scss">
.in-app-messaging {
  &__wrapper {
    @media (min-width: 1200px) {
      width: calc(100vw - 265px) !important;
    }
    overflow: hidden;
    width: calc(100vw);
    /* height: 100%; */
    margin-top: -1.3rem;
    margin-left: -2.2rem;
  }
}
</style>
