<template>
  <div></div>
</template>
<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  data() {
    return {
      unsubscribeMeeting: null,
      unsubscribeBookedMeeting: null,
      meetings: []
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },

  created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeMeeting()
    }
  },

  beforeDestroy() {
    this.unsubscribeListeners()
  },

  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },

    unsubscribeListeners() {
      if (this.unsubscribeMeeting) {
        this.unsubscribeMeeting()
      }

      if (this.unsubscribeBookedMeeting) {
        this.unsubscribeBookedMeeting()
      }
    },

    async subscribeMeeting() {
      if (!this.activeUserInfo) {
        return
      }
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(this.activeUserInfo.role)) {
        return
      }

      this.unsubscribeListeners()

      const queryMeetings = await this.$db
        .collection('meetings')
        .where('companyId', '==', this.activeUserInfo.company)
        .where('agentId', '==', this.activeUserInfo.uid)
        .orderBy('created', 'desc')
        .limit(100)

      this.unsubscribeBookedMeeting = queryMeetings.onSnapshot(async (ref) => {
        this.meetings = this.meetings.filter((meeting) => !meeting.companyId)

        ref.docs.forEach((doc) => {
          const meeting = doc.data()
          meeting.id = doc.id
          meeting.backgroundColor = '#275D73'
          meeting.borderColor = '#275D73'
          meeting.textColor = '#ffffff'
          this.meetings.push(meeting)
        })

        this.$store.commit('UPDATE_MEETINGS', this.meetings)
      })
    }
  }
}
</script>
