var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeUserInfo && _vm.activeUserInfo.company
    ? _c("div", [
        _c("audio", {
          attrs: {
            id: "visitor-request-audio",
            src: _vm.defaultRingTone,
            loop: "",
          },
        }),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }