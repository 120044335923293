var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.activeUserInfo && _vm.activeUserInfo.company
    ? _c(
        "div",
        {
          staticClass: "flex user-online",
          class: { "user-online--mobile": _vm.isMobile },
        },
        [
          _vm._l(_vm.previewListOfOnlineUsers, function (user, index) {
            return _c(
              "div",
              { key: index, staticClass: "user-online-avatar-wrapper" },
              [
                _c(
                  "vx-tooltip",
                  {
                    staticClass: "user-online-avatar",
                    attrs: { text: user.displayName, position: "bottom" },
                  },
                  [
                    [
                      _c("Avatar", {
                        attrs: {
                          size: _vm.isMobile ? "44px" : "40px",
                          photoURL: _vm.getUserImage(user.userId),
                          name: user.displayName,
                        },
                      }),
                      _c(
                        "div",
                        {
                          staticClass: "user-online-status",
                          class: {
                            _online: user.state === "online",
                            _offline: user.state === "offline",
                          },
                        },
                        [
                          _vm._v(
                            "\n          " + _vm._s(user.state) + "\n        "
                          ),
                        ]
                      ),
                    ],
                  ],
                  2
                ),
              ],
              1
            )
          }),
          _vm.isMobile && _vm.onlineUsers.length > 4
            ? _c(
                "div",
                {
                  staticClass: "user-online-button-more",
                  on: {
                    click: function ($event) {
                      _vm.isOpenedOnlineAgentsOverview = true
                    },
                  },
                },
                [
                  _c("feather-icon", {
                    attrs: {
                      icon: "PlusIcon",
                      svgClasses: "stroke-current w-5 h-5",
                    },
                    on: {
                      click: function ($event) {
                        _vm.isOpenedOnlineAgentsOverview = true
                      },
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm.isOpenedOnlineAgentsOverview
            ? _c("div", { staticClass: "user-online-overview" }, [
                _c("div", { staticClass: "user-online-overview__header" }, [
                  _c(
                    "button",
                    {
                      staticClass: "user-online-overview__header__button-back",
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          _vm.isOpenedOnlineAgentsOverview = false
                        },
                      },
                    },
                    [
                      _c("arrow-icon", {
                        attrs: {
                          color: "#275D73",
                          direction: "left",
                          width: 18,
                          height: 28,
                        },
                        on: {
                          click: function ($event) {
                            _vm.isOpenedOnlineAgentsOverview = false
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "user-online-overview__header__title" },
                    [_vm._v(_vm._s(_vm.$t("vue.liveAgents")))]
                  ),
                ]),
                _c(
                  "div",
                  { staticClass: "user-online-overview__list" },
                  _vm._l(_vm.onlineUsers, function (user, index) {
                    return _c(
                      "div",
                      { key: index, staticClass: "user-online-avatar-wrapper" },
                      [
                        _c(
                          "vx-tooltip",
                          {
                            staticClass: "user-online-avatar",
                            attrs: {
                              text: user.displayName,
                              position: "bottom",
                            },
                          },
                          [
                            [
                              _c("Avatar", {
                                attrs: {
                                  size: _vm.isMobile ? "44px" : "40px",
                                  photoURL: _vm.getUserImage(user.userId),
                                  name: user.displayName,
                                },
                              }),
                              _c(
                                "div",
                                {
                                  staticClass: "user-online-status",
                                  class: {
                                    _online: user.state === "online",
                                    _offline: user.state === "offline",
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(user.state) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                          ],
                          2
                        ),
                      ],
                      1
                    )
                  }),
                  0
                ),
              ])
            : _vm._e(),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }