<template>
  <connect-modal :show="showNotification" :width="'450px'" :height="'200px'" bkcolor="#F7F7F7">
    <div class="flex flex-row browser-push-notification-delete">
      <div>
        <h3>{{ $t('vue.addBrowserPushNotification') }}</h3>
      </div>
      <div disabled style="margin-left: auto; cursor: pointer" @click="showNotification = false">
        <close-circle-icon class="pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
    </div>
    <div class="mt-8">{{ $t('vue.addBrowserPushNotificationMessage') }}</div>
    <div class="mt-8 flex flex-row space-between justify-center">
      <div class="browser-push-notification-delete-button delete-button mr-2" @click="addBrowserPushNotification">{{ $t('vue.yes') }}</div>
      <div class="browser-push-notification-delete-button cancel-button ml-2" @click="hideNotificationMessage">{{ $t('vue.no') }}</div>
    </div>
  </connect-modal>
</template>
<script>
import { mapGetters } from 'vuex'
import ConnectModal from '../../../../components/ConnectModal.vue'
import CloseCircleIcon from '../../../../components/icons/CloseCircleIcon.vue'
import chromeNotifications from '@/assets/utils/chrome-notifications.js'
export default {
  components: {
    ConnectModal,
    CloseCircleIcon
  },
  data() {
    return {
      showNotification: false,
      endpoints: []
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },
  mounted() {
    if (
      this.activeUserInfo &&
      !!this.activeUserInfo.subscriptions &&
      !!this.activeUserInfo.subscriptions.push &&
      !!this.activeUserInfo.endpoints &&
      !this.activeUserInfo.impersonated
    ) {
      this.endpoints = this.activeUserInfo.endpoints
      this.showNotification = this.shouldRequestToAddBrowserPushNotificationEndpoint()
    }
  },
  methods: {
    /*
        This method checks whether the current browser subscription endpoint is stored in user notifications endpoint.
        If not then popup a message to add browser to notification endpoint
    */
    shouldRequestToAddBrowserPushNotificationEndpoint() {
      if (!localStorage.getItem('pa-push-notification')) {
        return true
      }
      const endpoint = JSON.parse(localStorage.getItem('pa-push-notification'))
      if (this.activeUserInfo.endpoints.find((x) => x.id === endpoint.id)) {
        return false
      }
      return true
    },
    async addBrowserPushNotification() {
      this.showNotification = false
      chromeNotifications.checkSupported()
      const subData = await chromeNotifications.subscribeUserToPush()
      if (subData) {
        const endpoint = {
          id: window.btoa(JSON.stringify(subData)),
          subscription: JSON.stringify(subData)
        }
        this.removePreviousSubscription(endpoint)
        /* Add subscription to the front of the array */
        this.endpoints.unshift(endpoint)

        await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ endpoints: this.endpoints }, { merge: true })

        localStorage.setItem('pa-push-notification', JSON.stringify(endpoint))
      }
    },
    hideNotificationMessage() {
      this.showNotification = false
      localStorage.removeItem('pa-push-notification')
    },
    removePreviousSubscription(pEndpoint) {
      /*  
          Check if subscription exists in localstorage. If it exists in localstorage, check if it exists in the endpoints array.
          If it  exists in endpoints array then remove it.
      */
      if (localStorage.getItem('pa-push-notification')) {
        const endpoint = JSON.parse(localStorage.getItem('pa-push-notification'))
        this.endpoints = this.endpoints.filter((x) => x.id !== endpoint.id)
      }
      this.endpoints = this.endpoints.filter((x) => x.id !== pEndpoint.id)

      /* 
          Check if total items in array is equal or greater than 10. 
          If there are 10 items then remove the last one.
     */
      if (this.endpoints.length >= 10) {
        this.endpoints.pop()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.browser-push-notification {
  &-delete-button {
    background: #e6ecf1;
  }
  &-delete-button:hover {
    background: rgba(240, 85, 65, 1);
    color: #fff !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  &-cancel-button {
    background: #eaeaea;
  }
  &-cancel-button:hover {
    background: #fff;
    color: #000 !important;
    box-shadow: 0px 4px 9px rgba(0, 0, 0, 0.3);
  }

  &-delete {
    border-color: rgba(240, 85, 65, 0.8) !important;
    border-top: none !important;
    border-left: none;
    border-right: none;
    border-style: solid;
    border-width: 1.5px;
    &-button {
      cursor: pointer;
      padding: 10px;
      width: 100px;
      text-align: center;
      color: rgba(var(--vs-secondary), 1) !important;
      font-weight: 600;
      border-radius: 10px;
    }
  }
}
</style>
