<template>
  <div></div>
</template>
<script>
import _ from 'underscore'
import { mapActions } from 'vuex'
export default {
  data() {
    return {
      unsubscribeBanners: null,
      banners: [],
      unsubscribeBannersCarouselSettings: null,
      swiperOptions: null
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeBanners()
      this.subscribeBannersCarouselSettings()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeBanners) {
      this.unsubscribeBanners()
    }
    if (this.unsubscribeBannersCarouselSettings) {
      this.unsubscribeBannersCarouselSettings()
    }
  },
  methods: {
    ...mapActions({
      updateBanners: 'updateBanners',
      updateBannersCarouselSettings: 'updateBannersCarouselSettings'
    }),
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeBanners() {
      if (this.unsubscribeBanners) {
        this.unsubscribeBanners()
      }
      const query = this.$db.collection('banners').orderBy('created', 'desc').limit(100)
      this.unsubscribeBanners = query.onSnapshot(async (ref) => {
        this.banners = []
        ref.docs.forEach((doc) => {
          const banner = doc.data()
          banner.id = doc.id
          this.banners.push(banner)
        })
        this.banners = _.sortBy(this.banners, 'position')
        this.updateBanners(this.banners)
        this.$serverBus.$emit('in-app-messaging-carousel-updated')
      })
    },
    subscribeBannersCarouselSettings() {
      if (this.unsubscribeBannersCarouselSettings) {
        this.unsubscribeBannersCarouselSettings()
      }
      const query = this.$db.collection('settings').doc('in-app-messaging-carousel-settings')
      this.unsubscribeBannersCarouselSettings = query.onSnapshot(async (ref) => {
        this.swiperOptions = ref.data()
        this.updateBannersCarouselSettings(this.swiperOptions)
        this.$serverBus.$emit('in-app-messaging-carousel-updated')
      })
    }
  }
}
</script>
