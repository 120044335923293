var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "layout--main",
      class: [
        _vm.layoutTypeClass,
        _vm.navbarClasses,
        _vm.footerClasses,
        { "no-scroll": _vm.isAppPage },
      ],
      on: { click: _vm.interactionWithDom },
    },
    [
      _c("vega-trial-expired"),
      _c(
        "vs-popup",
        {
          attrs: {
            title: _vm.$t("vue.updateAvailable"),
            active: _vm.newVersionAvailable,
          },
          on: {
            "update:active": function ($event) {
              _vm.newVersionAvailable = $event
            },
          },
        },
        [
          _c("p", [_vm._v(_vm._s(_vm.$t("vue.updateAvailableMessage")))]),
          _c("br"),
          _c(
            "p",
            [
              _c(
                "vs-button",
                { attrs: { type: "border" }, on: { click: _vm.reloadNow } },
                [
                  _vm._v(
                    _vm._s(_vm.$t("vue.reloadNow")) +
                      " (" +
                      _vm._s(_vm.newVersionUpdateTimeout / 1000) +
                      ")"
                  ),
                ]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.newCallCenterRequest &&
      _vm.isActiveAdmin &&
      _vm.currentCallCenterSettings
        ? _c(
            "vs-popup",
            {
              attrs: {
                "button-close-hidden": "",
                title: _vm.$t("info.callCenter"),
                active: _vm.newCallCenterRequest,
              },
              on: {
                "update:active": function ($event) {
                  _vm.newCallCenterRequest = $event
                },
              },
            },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm
                      .$t("info.accpetingRequestFromTheCallCenter")
                      .replace(
                        "<company-name>",
                        _vm.currentCallCenterSettings.callCenterName
                      )
                  ),
                },
              }),
              _c("br"),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-5",
                      attrs: { type: "border" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCallCenterRequest(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inputs.acceptCCRequest")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "border", color: "#e8546f" },
                      on: {
                        click: function ($event) {
                          return _vm.submitCallCenterRequest(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inputs.denyCCRequest")))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _vm.newAgencyRequest && _vm.isActiveAdmin && _vm.currentAgencySettings
        ? _c(
            "vs-popup",
            {
              attrs: {
                "button-close-hidden": "",
                title: "Agency",
                active: _vm.newAgencyRequest,
              },
              on: {
                "update:active": function ($event) {
                  _vm.newAgencyRequest = $event
                },
              },
            },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm
                      .$t("info.accpetingRequestFromTheCallCenter")
                      .replace(
                        "<company-name>",
                        _vm.currentAgencySettings.agencyName
                      )
                  ),
                },
              }),
              _c("br"),
              _c(
                "p",
                { staticClass: "text-center" },
                [
                  _c(
                    "vs-button",
                    {
                      staticClass: "mr-5",
                      attrs: { type: "border" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAgencyRequest(true)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inputs.acceptCCRequest")))]
                  ),
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "border", color: "#e8546f" },
                      on: {
                        click: function ($event) {
                          return _vm.submitAgencyRequest(false)
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("inputs.denyCCRequest")))]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c("notify-users"),
      _vm.subscriptionCancelled
        ? _c("UICancelledSubscriptionStatus")
        : _vm.appSumoSubscriptionRefunded
        ? _c("UIAppSumoRefundedStatus")
        : _vm.appSumoSubscriptionDeleteUsers
        ? _c("UIAppSumoDeleteUsers")
        : [
            _c("v-nav-menu", {
              attrs: {
                navMenuItems: _vm.navMenuItems,
                isMobile: _vm.isMobile,
                title: "PATHADVICE",
                parent: ".layout--main",
              },
            }),
            _c(
              "div",
              {
                class: [
                  _vm.contentAreaClass,
                  { "show-overlay": _vm.bodyOverlay },
                ],
                attrs: { id: "content-area" },
              },
              [
                _c("div", { attrs: { id: "content-overlay" } }),
                _vm.mainLayoutType === "horizontal" && _vm.windowWidth >= 1200
                  ? [
                      _c("the-navbar-horizontal", {
                        class: [
                          {
                            "text-white": _vm.isNavbarDark && !_vm.isThemeDark,
                          },
                          { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                        ],
                        attrs: {
                          isMobile: _vm.isMobile,
                          navbarType: _vm.navbarType,
                        },
                      }),
                      _vm.navbarType === "static"
                        ? _c("div", { staticStyle: { height: "62px" } })
                        : _vm._e(),
                      _c("h-nav-menu", {
                        class: [
                          {
                            "text-white": _vm.isNavbarDark && !_vm.isThemeDark,
                          },
                          { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                        ],
                        attrs: { navMenuItems: _vm.navMenuItems },
                      }),
                    ]
                  : [
                      _c("the-navbar-vertical", {
                        class: [
                          {
                            "text-white": _vm.isNavbarDark && !_vm.isThemeDark,
                          },
                          { "text-base": !_vm.isNavbarDark && _vm.isThemeDark },
                        ],
                        attrs: {
                          isMobile: _vm.isMobile,
                          navbarColor: _vm.navbarColor,
                        },
                      }),
                    ],
                _c("div", { staticClass: "content-wrapper" }, [
                  _c("div", { staticClass: "router-view pa-router-view" }, [
                    _c(
                      "div",
                      {
                        staticClass: "router-content pa-router-content",
                        style: _vm.routerContentStyles,
                      },
                      [
                        _c(
                          "transition",
                          { attrs: { name: _vm.routerTransition } },
                          [
                            _vm.$route.meta.breadcrumb ||
                            _vm.$route.meta.pageTitle
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "router-header flex flex-wrap items-center mb-6",
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "content-area__heading",
                                        class: {
                                          "pr-4 border-0 md:border-r border-solid border-grey-light":
                                            _vm.$route.meta.breadcrumb,
                                        },
                                      },
                                      [
                                        _c(
                                          "h2",
                                          {
                                            staticClass: "mb-1 secondary-color",
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(_vm.$t(_vm.routeTitle))
                                            ),
                                          ]
                                        ),
                                      ]
                                    ),
                                    _vm.$route.meta.breadcrumb
                                      ? _c("vx-breadcrumb", {
                                          staticClass: "ml-4 md:block hidden",
                                          attrs: {
                                            route: _vm.$route,
                                            isRTL: _vm.$vs.rtl,
                                          },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        ),
                        _c(
                          "div",
                          { staticClass: "content-area__content" },
                          [
                            !_vm.hideScrollToTop
                              ? _c(
                                  "back-to-top",
                                  {
                                    attrs: {
                                      bottom: "5%",
                                      right: _vm.$vs.rtl
                                        ? "calc(100% - 2.2rem - 38px)"
                                        : "30px",
                                      visibleoffset: "500",
                                    },
                                  },
                                  [
                                    _c("vs-button", {
                                      staticClass: "shadow-lg btn-back-to-top",
                                      attrs: {
                                        "icon-pack": "feather",
                                        icon: "icon-arrow-up",
                                      },
                                    }),
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _c(
                              "transition",
                              {
                                attrs: {
                                  name: _vm.routerTransition,
                                  mode: "out-in",
                                },
                              },
                              [
                                _c("router-view", {
                                  on: {
                                    changeRouteTitle: _vm.changeRouteTitle,
                                    setAppClasses: function (classesStr) {
                                      return _vm.$emit(
                                        "setappclasses",
                                        classesStr
                                      )
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]),
                _c("the-footer"),
              ],
              2
            ),
          ],
      _c("EmbedWidget", { attrs: { isAuth: true } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }