<!-- =========================================================================================
    File Name: Main.vue
    Description: Main layout
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
    Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->
<template>
  <div class="layout--main" :class="[layoutTypeClass, navbarClasses, footerClasses, { 'no-scroll': isAppPage }]" @click="interactionWithDom">
    <vega-trial-expired />
    <vs-popup :title="$t('vue.updateAvailable')" :active.sync="newVersionAvailable">
      <p>{{ $t('vue.updateAvailableMessage') }}</p>
      <br />
      <p>
        <vs-button type="border" @click="reloadNow">{{ $t('vue.reloadNow') }} ({{ newVersionUpdateTimeout / 1000 }})</vs-button>
      </p>
    </vs-popup>

    <vs-popup
      v-if="newCallCenterRequest && isActiveAdmin && currentCallCenterSettings"
      button-close-hidden
      :title="$t('info.callCenter')"
      :active.sync="newCallCenterRequest"
    >
      <p v-html="$t('info.accpetingRequestFromTheCallCenter').replace('<company-name>', currentCallCenterSettings.callCenterName)"></p>

      <br />

      <p class="text-center">
        <vs-button class="mr-5" type="border" @click="submitCallCenterRequest(true)">{{ $t('inputs.acceptCCRequest') }}</vs-button>
        <vs-button type="border" color="#e8546f" @click="submitCallCenterRequest(false)">{{ $t('inputs.denyCCRequest') }}</vs-button>
      </p>
    </vs-popup>

    <vs-popup v-if="newAgencyRequest && isActiveAdmin && currentAgencySettings" button-close-hidden title="Agency" :active.sync="newAgencyRequest">
      <p v-html="$t('info.accpetingRequestFromTheCallCenter').replace('<company-name>', currentAgencySettings.agencyName)"></p>

      <br />

      <p class="text-center">
        <vs-button class="mr-5" type="border" @click="submitAgencyRequest(true)">{{ $t('inputs.acceptCCRequest') }}</vs-button>
        <vs-button type="border" color="#e8546f" @click="submitAgencyRequest(false)">{{ $t('inputs.denyCCRequest') }}</vs-button>
      </p>
    </vs-popup>

    <notify-users />

    <UICancelledSubscriptionStatus v-if="subscriptionCancelled" />
    <UIAppSumoRefundedStatus v-else-if="appSumoSubscriptionRefunded" />
    <UIAppSumoDeleteUsers v-else-if="appSumoSubscriptionDeleteUsers" />
    <template v-else>
      <v-nav-menu :navMenuItems="navMenuItems" :isMobile="isMobile" title="PATHADVICE" parent=".layout--main" />

      <div id="content-area" :class="[contentAreaClass, { 'show-overlay': bodyOverlay }]">
        <div id="content-overlay" />

        <!-- Navbar -->
        <template v-if="mainLayoutType === 'horizontal' && windowWidth >= 1200">
          <the-navbar-horizontal
            :isMobile="isMobile"
            :navbarType="navbarType"
            :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]"
          />

          <div style="height: 62px" v-if="navbarType === 'static'"></div>

          <h-nav-menu :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]" :navMenuItems="navMenuItems" />
        </template>

        <template v-else>
          <the-navbar-vertical
            :isMobile="isMobile"
            :navbarColor="navbarColor"
            :class="[{ 'text-white': isNavbarDark && !isThemeDark }, { 'text-base': !isNavbarDark && isThemeDark }]"
          />
        </template>
        <!-- /Navbar -->

        <div class="content-wrapper">
          <div class="router-view pa-router-view">
            <div class="router-content pa-router-content" :style="routerContentStyles">
              <transition :name="routerTransition">
                <div v-if="$route.meta.breadcrumb || $route.meta.pageTitle" class="router-header flex flex-wrap items-center mb-6">
                  <div class="content-area__heading" :class="{ 'pr-4 border-0 md:border-r border-solid border-grey-light': $route.meta.breadcrumb }">
                    <h2 class="mb-1 secondary-color">{{ $t(routeTitle) }}</h2>
                  </div>

                  <!-- BREADCRUMB -->
                  <vx-breadcrumb class="ml-4 md:block hidden" v-if="$route.meta.breadcrumb" :route="$route" :isRTL="$vs.rtl" />
                </div>
              </transition>

              <div class="content-area__content">
                <back-to-top bottom="5%" :right="$vs.rtl ? 'calc(100% - 2.2rem - 38px)' : '30px'" visibleoffset="500" v-if="!hideScrollToTop">
                  <vs-button icon-pack="feather" icon="icon-arrow-up" class="shadow-lg btn-back-to-top" />
                </back-to-top>

                <transition :name="routerTransition" mode="out-in">
                  <router-view @changeRouteTitle="changeRouteTitle" @setAppClasses="(classesStr) => $emit('setappclasses', classesStr)" />
                </transition>
              </div>
            </div>
          </div>
        </div>
        <the-footer />
      </div>
    </template>
    <EmbedWidget :isAuth="true" />
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters, mapMutations } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

import firebase from 'firebase/app'
import 'firebase/auth'

import BackToTop from 'vue-backtotop'
import HNavMenu from '@/layouts/components/horizontal-nav-menu/HorizontalNavMenu.vue'
import navMenuItems from '@/layouts/components/vertical-nav-menu/navMenuItems.js'
import TheNavbarHorizontal from '@/layouts/components/navbar/TheNavbarHorizontal.vue'
import TheNavbarVertical from '@/layouts/components/navbar/TheNavbarVertical.vue'
import TheFooter from '@/layouts/components/TheFooter.vue'
import themeConfig from '@/../themeConfig.js'
import VNavMenu from '@/layouts/components/vertical-nav-menu/VerticalNavMenu.vue'
import EmbedWidget from '@/layouts/components/EmbedWidget.vue'

import NotifyUsers from '@/layouts/components/navbar/components/NotifyUsers.vue'
import UICancelledSubscriptionStatus from '@/components/UICancelledSubscriptionStatus'
import UIAppSumoRefundedStatus from '@/components/UIAppSumoRefundedStatus'
import UIAppSumoDeleteUsers from '@/components/UIAppSumoDeleteUsers'
import VegaTrialExpired from '@/components/vega/VegaTrialExpired.vue'

import { mobile_check } from '@/mixins/mobile_check'

export default {
  components: {
    BackToTop,
    HNavMenu,
    TheFooter,
    TheNavbarHorizontal,
    TheNavbarVertical,
    VNavMenu,
    EmbedWidget,
    UICancelledSubscriptionStatus,
    UIAppSumoRefundedStatus,
    UIAppSumoDeleteUsers,
    NotifyUsers,
    VegaTrialExpired
  },
  mixins: [mobile_check],
  data() {
    return {
      isMobile: false,
      additionalRouterContentHeight: 0,
      footerType: themeConfig.footerType || 'static',
      hideScrollToTop: themeConfig.hideScrollToTop,
      isNavbarDark: false,
      navbarColor: themeConfig.navbarColor || '#fff',
      navbarType: themeConfig.navbarType || 'floating',
      navMenuItems,
      routerTransition: themeConfig.routerTransition || 'none',
      routeTitle: this.$i18n.t(this.$route.meta.pageTitle),
      steps: [],
      tours: [
        {
          name: 'mainTour',
          restart: false
        }
      ],
      hasAddedDialog: false,
      isTourRestarted: false,

      unsubscribeVersion: null,
      newVersionAvailable: false,
      newVersionUpdateTimeout: 0,
      newVersionUpdateInterval: null,

      newCallCenterRequest: false,
      unsubscribeCallCenter: null,
      currentCallCenterSettings: null,

      newAgencyRequest: false,
      unsubscribeAgency: null,
      currentAgencySettings: null,

      unsubscribeCompany: null,
      unsubscribeApiKeys: null
    }
  },
  watch: {
    $route() {
      this.routeTitle = this.$i18n.t(this.$route.meta.pageTitle)
    },
    isThemeDark(val) {
      const color = this.navbarColor === '#fff' && val ? '#10163a' : '#fff'
      this.updateNavbarColor(color)
    },
    '$store.state.mainLayoutType'(val) {
      this.setNavMenuVisibility(val)
    },
    newVersionAvailable() {
      const vm = this
      if (vm.newVersionAvailable) {
        vm.newVersionUpdateInterval = setInterval(() => {
          if (vm.newVersionUpdateTimeout > 0) {
            vm.newVersionUpdateTimeout = vm.newVersionUpdateTimeout - 1000
          } else {
            clearInterval(vm.newVersionUpdateInterval)
          }
        }, 1000)
      }
    },
    HAS_SUPPORT_ACCESS() {
      if (!this.HAS_SUPPORT_ACCESS) {
        // const supportChatIframe = document.getElementById('paConnectDialogIFrame')
        // if (supportChatIframe) {
        //   localStorage.removeItem('pa-visitorId')
        //   sessionStorage.removeItem('pa-button-prompted')
        //   sessionStorage.removeItem('pa-json')
        //   supportChatIframe.remove()
        // }
        if (window.fcWidget) {
          window.fcWidget.destroy()
        }
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      subscriptionCancelled: 'subscriptionCancelled',
      appSumoSubscriptionRefunded: 'appSumoSubscriptionRefunded',
      appSumoSubscriptionDeleteUsers: 'appSumoSubscriptionDeleteUsers',
      hasUserInteractedWithDOM: 'hasUserInteractedWithDOM'
    }),
    routerContentStyles() {
      const onTrial = this.company && ['on-trial', 'trial', 'expired'].includes(this.company.paymentStatus)
      const margin = onTrial ? '8rem' : '6rem'
      return {
        marginTop: `calc(${margin} + ${this.additionalRouterContentHeight}px)`,
        transition: 'all 0.3s ease-in-out'
      }
    },
    bodyOverlay() {
      return this.$store.state.bodyOverlay
    },
    contentAreaClass() {
      if (this.mainLayoutType === 'vertical') {
        if (this.verticalNavMenuWidth === 'default') return 'content-area-reduced'
        else if (this.verticalNavMenuWidth === 'reduced') return 'content-area-lg'
        else return 'content-area-full'
      } else return 'content-area-full'
    },
    footerClasses() {
      return {
        'footer-hidden': this.footerType === 'hidden',
        'footer-sticky': this.footerType === 'sticky',
        'footer-static': this.footerType === 'static'
      }
    },
    isAppPage() {
      return this.$route.meta.no_scroll
    },
    isThemeDark() {
      return this.$store.state.theme === 'dark'
    },
    layoutTypeClass() {
      return `main-${this.mainLayoutType}`
    },
    mainLayoutType() {
      return this.$store.state.mainLayoutType
    },
    navbarClasses() {
      return {
        'navbar-hidden': this.navbarType === 'hidden',
        'navbar-sticky': this.navbarType === 'sticky',
        'navbar-static': this.navbarType === 'static',
        'navbar-floating': this.navbarType === 'floating'
      }
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },
    version() {
      return parseFloat(this.$store.state.version).toFixed(2)
    },

    isActiveAdmin() {
      return this.activeUserInfo.isActive && !(this.activeUserInfo.isAgent || this.activeUserInfo.isSupervisor)
    },

    HAS_SUPPORT_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]
      const hasAccess = claims.includes('support-chat') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
      return hasAccess
    }
  },
  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY',
      updateUserInfo: 'UPDATE_USER_INFO',
      updateUserInteractionWithDom: 'UPDATE_USER_INTERACTION_WITH_DOM',
      updateApiKeys: 'UPDATE_API_KEYS'
    }),
    interactionWithDom() {
      if (!this.hasUserInteractedWithDOM) {
        this.updateUserInteractionWithDom(true)
      }
    },
    reloadNow() {
      location.reload(true)
    },
    changeRouteTitle(title) {
      this.routeTitle = title
    },
    updateNavbarColor(val) {
      this.navbarColor = val
      if (val === '#fff') this.isNavbarDark = false
      else this.isNavbarDark = true
    },
    setNavMenuVisibility(layoutType) {
      if ((layoutType === 'horizontal' && this.windowWidth >= 1200) || (layoutType === 'vertical' && this.windowWidth < 1200)) {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', false)
        this.$store.dispatch('updateVerticalNavMenuWidth', 'no-nav-menu')
      } else {
        this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
      }
    },
    async getAppVersion() {
      const vm = this
      const app = await vm.$db.collection('settings').doc('app').get()
      if (app && app.data() && app.data().version) {
        vm.$store.commit('UPDATE_VERSION', app.data().version)
      } else {
        vm.$store.commit('UPDATE_VERSION', '1.00')
      }
    },
    subscribeVersion() {
      const vm = this
      if (vm.unsubscribeVersion) {
        vm.unsubscribeVersion()
      }
      const query = vm.$db.collection('settings').doc('app')
      vm.unsubscribeVersion = query.onSnapshot(async (ref) => {
        if (ref && ref.data() && ref.data().version) {
          if (vm.version !== ref.data().version) {
            vm.newVersionUpdateTimeout = 60000
            vm.newVersionAvailable = true
            setTimeout(() => {
              location.reload(true)
            }, vm.newVersionUpdateTimeout)
          }
          vm.$store.commit('UPDATE_VERSION', ref.data().version)
        }
      })
    },

    subscribeCallCenter() {
      if (!this.isActiveAdmin) {
        return
      }

      if (this.unsubscribeCallCenter) {
        this.unsubscribeCallCenter()
      }

      const query = this.$db.collection('call-center-settings').where('customerId', '==', this.activeUserInfo.company).where('approved', '==', 'waiting')

      this.unsubscribeCallCenter = query.onSnapshot(async (ref) => {
        ref.docs.map(async (doc) => {
          if (doc && doc.data() && !this.newCallCenterRequest) {
            this.currentCallCenterSettings = doc.data()
            this.currentCallCenterSettings.uid = doc.id

            const callCenterCompanyRef = await this.$db.collection('company').doc(this.currentCallCenterSettings.callCenterId).get()
            this.currentCallCenterSettings.callCenterName = callCenterCompanyRef.data().name

            this.newCallCenterRequest = true
          }
        })
      })
    },

    async submitCallCenterRequest(isAgree) {
      let data = null

      if (!isAgree) {
        data = {
          approved: 'canceled',
          canceled: new Date(),
          canceledBy: this.activeUserInfo.uid
        }
      } else {
        data = {
          approved: 'confirmed',
          confirmed: new Date(),
          confirmedBy: this.activeUserInfo.uid
        }
      }

      await this.$db.collection('call-center-settings').doc(this.currentCallCenterSettings.uid).set(data, { merge: true })

      this.newCallCenterRequest = false
      this.currentCallCenterSettings = null
    },

    subscribeAgency() {
      if (!this.isActiveAdmin) {
        return
      }

      if (this.unsubscribeAgency) {
        this.unsubscribeAgency()
      }

      const query = this.$db.collection('agency-settings').where('customerId', '==', this.activeUserInfo.company).where('approved', '==', 'waiting')

      this.unsubscribeAgency = query.onSnapshot(async (ref) => {
        ref.docs.map(async (doc) => {
          if (doc && doc.data() && !this.newAgencyRequest) {
            this.currentAgencySettings = doc.data()
            this.currentAgencySettings.uid = doc.id

            const agencyCompanyRef = await this.$db.collection('company').doc(this.currentAgencySettings.agencyId).get()
            this.currentAgencySettings.agencyName = agencyCompanyRef.data().name

            this.newAgencyRequest = true
          }
        })
      })
    },

    async submitAgencyRequest(isAgree) {
      let data = null

      if (!isAgree) {
        data = {
          approved: 'canceled',
          canceled: new Date(),
          canceledBy: this.activeUserInfo.uid
        }
      } else {
        data = {
          approved: 'confirmed',
          confirmed: new Date(),
          confirmedBy: this.activeUserInfo.uid
        }
      }

      await this.$db.collection('agency-settings').doc(this.currentAgencySettings.uid).set(data, { merge: true })

      this.newAgencyRequest = false
      this.currentAgencySettings = null
    },

    subscribeCompany() {
      if (this.activeUserInfo && this.activeUserInfo.company) {
        if (this.unsubscribeCompany) {
          this.unsubscribeCompany()
        }
        const query = this.$db.collection('company').doc(this.activeUserInfo.company)
        this.unsubscribeCompany = query.onSnapshot(async (ref) => {
          if (ref && ref.data()) {
            const company = ref.data()
            this.updateCompany({ ...company, id: ref.id })
          }
        })
      }
    },

    async updateUserRoleClaims(data) {
      if (!(data.isAgent && data.role === 'agent') || !(data.isSupervisor && data.role === 'supervisor')) {
        const firebaseCurrentUser = firebase.auth().currentUser

        await firebaseCurrentUser.getIdToken(true)

        const idTokenResult = await firebaseCurrentUser.getIdTokenResult()

        if (idTokenResult && idTokenResult.claims.company) {
          this.updateUserInfo({
            role: idTokenResult.claims.role
          })
        }
      }
    },

    subscribeApiKeys() {
      const vm = this
      if (vm.unsubscribeApiKeys) {
        vm.unsubscribeApiKeys()
      }

      const query = this.$db.collection('api-keys').doc(this.activeUserInfo.company)

      vm.unsubscribeApiKeys = query.onSnapshot(async (ref) => {
        if (ref && ref.data()) {
          const apiKeys = ref.data()
          this.updateApiKeys(apiKeys)
        }
      })
    }
  },

  async mounted() {
    await this.getAppVersion()
    this.subscribeVersion()
    this.subscribeAgency()
    this.subscribeCallCenter()
    this.subscribeCompany()
    this.subscribeApiKeys()
    this.$serverBus.$on('swiper-height-updated', (swiperHeight) => {
      this.additionalRouterContentHeight = swiperHeight
    })
    this.$serverBus.$once('swiper-height-updated-on-init', (swiperHeight) => {
      this.additionalRouterContentHeight = swiperHeight
    })
  },
  async created() {
    this.isMobile = this.mobileCheck()
    this.updateUserRoleClaims(this.activeUserInfo)

    const vm = this
    this.$serverBus.$on('quick-guide-restart', async () => {
      await this.$db.collection('users').doc(this.activeUserInfo.uid).set(
        {
          isQuickGuideStarted: true,
          isQuickGuideInProgress: true,
          isQuickGuideFinished: false
        },
        { merge: true }
      )
      this.$router.push('/web-connect').catch(() => {})
      document.getElementById('pa-overlay').style.display = 'block'
    })
    this.$serverBus.$on('new-dialog-added', () => {
      vm.hasAddedDialog = true
    })
    this.$serverBus.$on('route-title-update', () => {
      vm.routeTitle = this.$i18n.t(this.$route.meta.pageTitle)
    })

    const color = this.navbarColor === '#fff' && this.isThemeDark ? '#10163a' : this.navbarColor
    this.updateNavbarColor(color)
    this.setNavMenuVisibility(this.$store.state.mainLayoutType)

    this.$vs.loading.close()
  },
  beforeDestroy() {
    this.$serverBus.$off('swiper-height-updated')
    this.$serverBus.$off('swiper-height-updated-on-init')
    this.$serverBus.$off('quick-guide-restart')
    if (this.unsubscribeVersion) {
      this.unsubscribeVersion()
    }

    if (this.unsubscribeCallCenter) {
      this.unsubscribeCallCenter()
    }

    if (this.unsubscribeAgency) {
      this.unsubscribeAgency()
    }

    if (this.unsubscribeCompany) {
      this.unsubscribeCompany()
    }
  }
}
</script>

<style lang="scss">
.pa-router-view {
  @media only screen and (max-width: 1024px) {
    padding: 2.2rem 20px 2.2rem 20px !important;
  }
}
.pa-router-content {
  @media only screen and (max-width: 1024px) {
    margin-top: 60px !important;
  }
}
</style>
