<template>
  <div v-if="activeUserInfo && activeUserInfo.company">
    <audio id="visitor-request-audio" :src="defaultRingTone" loop></audio>
  </div>
</template>
<script>
import _ from 'underscore'
import { mapGetters, mapMutations } from 'vuex'
import NotifyVisitorToast from './NotifyVisitorToast'
import { mvisitors } from '@/mixins/mvisitors'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  name: 'NotifyVisitors',
  mixins: [mvisitors],
  data() {
    return {
      speakerPlayer: null,
      hasAccessToSpeaker: false,
      unsubscribeVisitors: null,
      unsubscribeIncoming: null,
      unsubscribeAgent: null,
      unsubscribeGptChats: null,
      visitors: [],
      incomingAdded: false,
      currentTabKey: Math.random().toString(36).substring(2, 15),
      companies: [],
      gptChats: [],
      maxIncomingRequestsCount: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app' ? 30 : 20,
      maxVisitorsCount: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app' ? 100 : 50,
      maxStaticIncomingCount: process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'pathadvice-app' ? 100 : 50
    }
  },
  created() {
    this.$serverBus.$on('check-if-audio-needs-to-be-played', () => {
      /* eslint-disable no-console */
      // console.log(
      //   `check-if-audio-needs-to-be-played : amIConnectedWithVisitor : ${this.amIConnectedWithVisitor}, areThereIncomingRequests: ${this.areThereIncomingRequests}`
      // )
      if (!this.amIConnectedWithVisitor && this.areThereIncomingRequests) {
        this.playIncomingRequestAudio()
      }
    })

    this.$serverBus.$on('pause-speaker-audio', () => {
      if (this.speakerPlayer && !this.speakerPlayer.paused) {
        this.speakerPlayer.pause()
      }
    })
  },
  watch: {
    defaultRingTone() {
      setTimeout(() => {
        if (!this.amIConnectedWithVisitor && this.areThereIncomingRequests) {
          this.playIncomingRequestAudio()
        }
      }, 500)
    },
    'customRingtone.url'() {
      if (this.speakerPlayer && this.customRingtone.url) {
        this.speakerPlayer.src = this.customRingtone.url
      }
    },
    isSearchVisitorsOn(newValue, oldValue) {
      if (typeof newValue !== 'undefined' && newValue !== oldValue) {
        if (newValue && this.unsubscribeIncoming) {
          this.unsubscribeIncoming()
        }
        if (!newValue) {
          this.subscribeIncoming()
        }
      }
    },
    hasUserInteractedWithDOM(newValue, oldValue) {
      if (newValue && !oldValue && !this.amIConnectedWithVisitor && this.areThereIncomingRequests) {
        this.playIncomingRequestAudio()
      }
    },
    widgetsAssignedKey() {
      if (!this.amIConnectedWithVisitor && this.activeUserInfo && this.activeUserInfo.role === 'agent') {
        this.clearIncomingRequests()
        this.subscribeVisitors()
        this.subscribeIncoming()
      }
    },
    b2bEnabled(after) {
      if (typeof after === 'undefined') {
        return
      }
      this.subscribeVisitors()
    },
    showOnlineVisitors() {
      this.subscribeVisitors()
    },
    dateRange() {
      this.subscribeVisitors()
    },
    dateRangeIncoming() {
      this.subscribeIncoming()
    },
    '$i18n.locale'() {
      /* Localization of time for Incoming Requests */
      const incoming = [
        ...this.incoming.map((data) => {
          return { ...data }
        })
      ]
      incoming.forEach((data) => {
        if (data.disconnect) {
          if (data.lastRequestDate) {
            data.lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).fromNow()
          } else {
            data.lastSeen = dayjs(data.disconnect.toDate()).locale(this.$i18n.locale).fromNow()
          }
        }
        const lastSeen = data.lastRequestDate || data.disconnect || data.modified || data.created
        if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
          data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
        if (typeof lastSeen === 'string' && lastSeen.length > 0) {
          data.lastSeenTimestamp = dayjs(lastSeen.toDate()).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs(lastSeen.toDate()).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
      })
      this.$store.commit('UPDATE_INCOMING_REQUESTS', incoming)

      /* Localization of time for Static Visitors */
      const staticIncoming = [
        ...this.staticIncoming.map((data) => {
          return { ...data }
        })
      ]
      staticIncoming.forEach((data) => {
        if (data.disconnect) {
          if (data.lastRequestDate) {
            data.lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).fromNow()
          } else {
            data.lastSeen = dayjs(data.disconnect.toDate()).locale(this.$i18n.locale).fromNow()
          }
        }
        const lastSeen = data.lastRequestDate || data.disconnect || data.modified || data.created
        if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
          data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
        if (typeof lastSeen === 'string' && lastSeen.length > 0) {
          data.lastSeenTimestamp = dayjs(lastSeen.toDate()).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs(lastSeen.toDate()).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
      })

      /* Localization of time for Visitors List */
      const visitors = [
        ...this.visitors.map((data) => {
          return { ...data }
        })
      ]
      visitors.forEach((data) => {
        if (data.disconnect) {
          if (data.lastRequestDate) {
            data.lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(this.$i18n.locale).fromNow()
          } else {
            data.lastSeen = dayjs(data.disconnect.toDate()).locale(this.$i18n.locale).fromNow()
          }
        }
        const lastSeen = data.lastRequestDate || data.disconnect || data.modified || data.created
        if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
          data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
        if (typeof lastSeen === 'string' && lastSeen.length > 0) {
          data.lastSeenTimestamp = dayjs(lastSeen.toDate()).locale(this.$i18n.locale).format('LLL')
          //data.lastSeenTimestamp = dayjs(lastSeen.toDate()).tz(this.$defaultTimezone).locale(this.$i18n.locale).format('LLL')
        }
      })
      this.$store.commit('UPDATE_VISITORS', visitors)
    },
    company() {
      if (this.company && this.company.isCallCenter) {
        this.subscribeIncoming()
      }
    },
    'company.isCallCenter'() {
      this.subscribeIncoming()
    },
    '$route.name'() {
      if (this.unsubscribeVisitors) {
        this.unsubscribeVisitors()
        this.$store.commit('UPDATE_VISITORS', [])
      }
      if (this.$route && this.$route.name === 'visitors') {
        this.subscribeVisitors()
      }
    },
    'activeUserInfo.subscriptions.audioOutputDevice.value'() {
      if (
        this.activeUserInfo.subscriptions &&
        this.activeUserInfo.subscriptions.audioOutputDevice &&
        this.activeUserInfo.subscriptions.audioOutputDevice.value
      ) {
        const audioOutputLabel =
          this.activeUserInfo.subscriptions.audioOutputDevice.text === 'Default Speaker' ? '' : this.activeUserInfo.subscriptions.audioOutputDevice.text
        const audioOutputValue = this.activeUserInfo.subscriptions.audioOutputDevice.value
        const vm = this
        navigator.mediaDevices
          .enumerateDevices()
          .then((devices) => {
            devices.forEach((device) => {
              if (device.kind === 'audiooutput') {
                if (device.label.toLowerCase() === audioOutputLabel || device.deviceId === audioOutputValue) {
                  vm.speakerPlayer.setSinkId(device.deviceId)
                  vm.hasAccessToSpeaker = true
                }
              }
            })
          })
          .catch((err) => {
            /* eslint-disable no-console */
            vm.hasAccessToSpeaker = false
            console.log(err.message)
          })
      }
    },
    widgetsFilterForVisitor() {
      this.subscribeVisitors()
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      dialogs: 'dialogs',
      b2bEnabled: 'b2bEnabled',
      showOnlineVisitors: 'showOnlineVisitors',
      dateRange: 'dateRange',
      dateRangeIncoming: 'dateRangeIncoming',
      widgetsAssigned: 'widgetsAssigned',
      widgetsAssignedKey: 'widgetsAssignedKey',
      activeUserInfo: 'activeUser',
      hasUserInteractedWithDOM: 'hasUserInteractedWithDOM',
      visitorsLastUpdated: 'visitorsLastUpdated',
      widgetsFilterForVisitor: 'widgetsFilterForVisitor',
      isSearchVisitorsOn: 'isSearchVisitorsOn'
    }),
    defaultRingTone() {
      if (this.activeUserInfo && this.activeUserInfo.ringtones) {
        const ringtone = this.activeUserInfo.ringtones.find((x) => x.isDefault)

        if (ringtone && ringtone.isPathadviceDefault) {
          return require('@/assets/visitor_request.mp3')
        }

        if (ringtone) {
          return ringtone.url
        } else {
          return 'https://storage.googleapis.com/pathadvice-app.appspot.com/manual_uploads/ringtones/visitor_request.mp3'
        }
      }
      return require('@/assets/visitor_request.mp3')
    },
    customRingtone() {
      if (this.activeUserInfo && this.activeUserInfo.ringtones) {
        const ringtone = this.activeUserInfo.ringtones.find((x) => x.isDefault)
        return ringtone
      }
      return null
    },
    widgets() {
      if (!this.dialogs) return []
      return this.dialogs.filter((x) => x.isVegaWidget).map((x) => x.id)
    },
    amIConnectedWithVisitor() {
      return !!this.incoming.find((x) => x.connectedAgentId === this.activeUserInfo.uid)
    },
    areThereIncomingRequests() {
      return !!this.incoming.filter((y) => !y.audioPlayed).find((x) => x.agentRequest)
    },
    isAudioNotificationEnabled() {
      let isAudioNotificationEnabled = true
      if (this.activeUserInfo && this.activeUserInfo.subscriptions && typeof this.activeUserInfo.subscriptions.audio !== 'undefined') {
        isAudioNotificationEnabled = this.activeUserInfo.subscriptions.audio
      }
      return isAudioNotificationEnabled
    },
    isActiveConsultant() {
      let isActiveConsultant = true
      if (this.activeUserInfo && typeof this.activeUserInfo.isActiveConsultant !== 'undefined') {
        isActiveConsultant = this.activeUserInfo.isActiveConsultant
      }
      return isActiveConsultant
    },
    isSpeakerNotificationEnabled() {
      let isSpeakerNotificationEnabled = true
      if (this.activeUserInfo && this.activeUserInfo.subscriptions && typeof this.activeUserInfo.subscriptions.audioOutputDevice !== 'undefined') {
        isSpeakerNotificationEnabled = true
      }
      return isSpeakerNotificationEnabled
    }
  },
  async beforeMount() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.$store.commit('UPDATE_VISITORS_B2B_ENABLED', this.activeUserInfo.filterB2B)
      this.$store.commit('UPDATE_VISITORS_SHOW_ONLINE_VISITORS', this.activeUserInfo.filterOnlineVisitors ? this.activeUserInfo.filterOnlineVisitors : false)

      this.$serverBus.$on('toast-join-surfly', async (visitor) => {
        await this.$db.collection('visitors').doc(visitor.id).set({ audioPlayed: true }, { merge: true })
        this.$store.commit('NOTIFICATIONS_REMOVE', visitor)
        this.$router.push('/incoming-requests').catch(() => {})
      })

      await this.getCompanies()

      this.subscribeVisitors()
      this.subscribeIncoming()
      this.subscribeGptChats()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeVisitors) {
      this.unsubscribeVisitors()
    }
    if (this.unsubscribeIncoming) {
      this.unsubscribeIncoming()
    }
    if (this.unsubscribeAgent) {
      this.unsubscribeAgent()
    }
    if (this.unsubscribeGptChats) {
      this.unsubscribeGptChats()
    }
    this.$serverBus.$off('check-if-audio-needs-to-be-played')
    this.$serverBus.$off('pause-speaker-audio')
    this.$serverBus.$off('toast-join-surfly')
    if (this.speakerPlayer && !this.speakerPlayer.paused) {
      this.speakerPlayer.pause()
    }
  },
  mounted() {
    const vm = this
    vm.speakerPlayer = new Audio()
    vm.speakerPlayer.loop = true
    vm.speakerPlayer.src = this.defaultRingTone

    localStorage.setItem('currentTabKey', this.currentTabKey)

    //when main tab closes, remove item from localStorage
    window.addEventListener('unload', this.removeCurrentTabKey)
  },
  destroyed() {
    window.removeEventListener('unload', this.removeCurrentTabKey)
  },
  methods: {
    ...mapMutations({
      updateVisitorsLastUpdated: 'UPDATE_VISITORS_LAST_UPDATED'
    }),
    removeCurrentTabKey() {
      if (localStorage.getItem('currentTabKey') === this.currentTabKey) {
        localStorage.removeItem('currentTabKey')
      }
    },
    async getCompanies() {
      if (this.activeUserInfo.allowedCompanies && this.activeUserInfo.allowedCompanies.length) {
        await Promise.all(
          this.activeUserInfo.allowedCompanies.map(async (id) => {
            const companyRef = await this.$db.collection('company').doc(id).get()

            const companyData = {
              id,
              name: companyRef.data().name
            }

            this.companies.push(companyData)
          })
        )
      }
    },

    translate(code) {
      return this.$i18n.t(code)
    },
    async joinSurfly(surlfyURL, joinURL, id, surlfySessionId, dialogId, abTestId, location) {
      const ref = this.$database.ref(`/status/${id}`)
      const event = {}
      event.joinedSession = true
      event.joinURL = joinURL
      if (this.activeUserInfo.firstname) {
        event.agent = `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
      } else {
        event.agent = this.activeUserInfo.displayName
      }
      surlfyURL += `?name=${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}&email=${this.activeUserInfo.email}&agent_id=${this.activeUserInfo.uid}`
      event.surlfyURL = surlfyURL
      await ref.update(event)
      const visitorData = {
        connectedAgent: event.agent,
        connectedAgentId: this.activeUserInfo.uid,
        agentId: this.activeUserInfo.uid,
        lastConnectedAgentId: this.activeUserInfo.uid,
        lastConnectedAgent: event.agent,
        lastConnectedAgentImage: this.activeUserInfo.photoURL || null,
        audioPlayed: true,
        callTypeState: 'agent-joined'
      }
      await this.$db.collection('visitors').doc(id).set(visitorData, { merge: true })

      this.$store.commit('NOTIFICATIONS_REMOVE', { id, type: 'visitor-request' })

      setTimeout(() => {
        if (this.isSafari) {
          location.href = surlfyURL
        } else {
          const win = window.open(surlfyURL, '_blank')
          if (win) {
            win.focus()
          } else {
            setTimeout(() => {
              if (win) win.focus()
            }, 2000)
          }
        }
      }, 2000)
    },
    notifyVisitorRequest(visitor) {
      const vm = this
      const name = visitor.companyName ? visitor.companyName : visitor.city
      /* Check if agent needs to be notified */
      if (
        (visitor.type === 'campaign' &&
          (!visitor.recipients || visitor.recipients.length === 0 || !visitor.recipients.map((x) => x.id).includes(vm.activeUserInfo.uid))) ||
        !this.isActiveConsultant
      ) {
        return
      }

      const content = {
        component: NotifyVisitorToast,
        props: {
          visitor: name,
          requestType: visitor.agentRequestType,
          visitorId: visitor.id,
          surlfyURL: visitor.surlfyURL,
          joinURL: visitor.joinURL,
          surlfySessionId: visitor.surlfySessionId,
          dialogId: visitor.dialogId,
          abTestId: visitor.abTestId || null, // in case of campaigns there no abTestId field
          location: visitor.location,
          joinButtonText: vm.translate('vue.join'),
          acceptButtonText: vm.translate('inputs.acceptCCRequest'),
          declineButtonText: vm.translate('inputs.denyCCRequest'),
          visitorRequestText: vm.translate('vue.visitorRequest')
        }
      }

      vm.$toast.update(
        visitor.id,
        {
          content,
          options: {
            position: 'bottom-left',
            timeout: 0,
            closeOnClick: false,
            draggable: false,
            showCloseButtonOnHover: false,
            closeButton: 'button',
            toastClassName: ['pathadvice-toast'],
            icon: false,
            rtl: false,
            preventDuplicates: true
          }
        },
        true
      )

      visitor.title = 'vue.visitorRequest'
      vm.$store.commit('NEW_VISITORS_ADD', visitor)
      vm.$db.collection('visitors').doc(visitor.id).set({ shouldNotifyAgents: false }, { merge: true })
    },

    playIncomingRequestAudio() {
      /* eslint-disable no-console */
      // console.log(
      //   `playIncomingRequestAudio ( isAudioNotificationEnabled : ${this.isAudioNotificationEnabled}, checkIfShouldPlayAudio: ${this.checkIfShouldPlayAudio()} )`
      // )
      if ((this.isAudioNotificationEnabled || this.isSpeakerNotificationEnabled) && this.checkIfShouldPlayAudio() && this.isActiveConsultant) {
        this.playAudio()
      }
    },

    setPlayerVolume(player, volume) {
      player.volume = volume
    },

    playAudio() {
      if (this.activeUserInfo && this.activeUserInfo.subscriptions && this.activeUserInfo.subscriptions.audioOutputDevice && this.hasAccessToSpeaker) {
        if (this.speakerPlayer.paused && this.hasUserInteractedWithDOM && this.isSpeakerNotificationEnabled) {
          const audioElement = document.getElementById('visitor-request-audio')
          if (audioElement && !audioElement.paused) {
            audioElement.pause()
          }

          if (this.customRingtone) {
            this.setPlayerVolume(this.speakerPlayer, this.customRingtone.loudness / 100)
          }
          this.speakerPlayer.play()
          //console.log('Played Incoming Audio in Speaker')
        }
      } else {
        const audioElement = document.getElementById('visitor-request-audio')
        if (audioElement.paused && this.hasUserInteractedWithDOM && this.isAudioNotificationEnabled) {
          if (this.customRingtone) {
            this.setPlayerVolume(audioElement, this.customRingtone.loudness / 100)
          }

          audioElement.play()
          //console.log('Played Incoming Audio')
        }
      }
    },

    pauseIncomingRequestAudio() {
      const audioElement = document.getElementById('visitor-request-audio')
      if (audioElement && !audioElement.paused) {
        audioElement.pause()
      }
      if (this.speakerPlayer && !this.speakerPlayer.paused) {
        this.speakerPlayer.pause()
      }
    },

    checkIfShouldPlayAudio() {
      let shouldPlayAudio = false
      if (localStorage.getItem('currentTabKey') === null) {
        localStorage.setItem('currentTabKey', this.currentTabKey)
      }

      if (localStorage.getItem('currentTabKey') === this.currentTabKey) {
        shouldPlayAudio = true
      }
      return shouldPlayAudio
    },
    async getVisitorsData() {
      try {
        const vm = this
        if (vm.activeUserInfo) {
          const disallowedRoles = ['view-only']
          if (disallowedRoles.includes(vm.activeUserInfo.role)) {
            return
          }

          let query = vm.$db.collection('visitors').where('company', '==', vm.activeUserInfo.company).orderBy('modified', 'desc').limit(this.maxVisitorsCount)
          query = query.where('type', '==', 'embed')
          if (this.b2bEnabled) {
            query = query.where('b2b', '==', true)
          }
          if (this.showOnlineVisitors) {
            query = query.where('online', '==', true)
          }
          if (this.dateRange && this.dateRange.startDate && this.dateRange.endDate) {
            const startQueryDate = dayjs(this.dateRange.startDate).tz(this.$defaultTimezone).startOf('day').toDate()
            const endQueryDate = dayjs(this.dateRange.endDate).tz(this.$defaultTimezone).endOf('day').toDate()
            query = query.where('modified', '>=', startQueryDate).where('modified', '<=', endQueryDate)
          }

          if (this.activeUserInfo.role === 'agent' && this.widgetsAssigned && this.widgetsAssigned.length > 0) {
            query = query.where('dialogId', 'in', this.widgetsAssigned)
          }

          if (this.activeUserInfo.role === 'admin' && this.widgetsFilterForVisitor && this.widgetsFilterForVisitor.length > 0) {
            query = query.where('dialogId', 'in', this.widgetsFilterForVisitor)
          }

          vm.visitors = []
          const today = dayjs().tz(vm.$defaultTimezone)
          const _visitorsRef = await query.get()
          _visitorsRef.docs.map(async (visitorRef) => {
            const data = visitorRef.data()
            data.id = visitorRef.id
            data.name = data.owner || data.isp || data.netname
            data.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
            //data.city = data.region ? `${data.city}, ${data.region.toUpperCase()}` : 'Inkognito'
            if (data.disconnect) {
              if (data.lastRequestDate) {
                data.lastSeen = dayjs.unix(data.lastRequestDate.seconds).locale(vm.$i18n.locale).fromNow()
              } else {
                data.lastSeen = dayjs(data.disconnect.toDate()).locale(vm.$i18n.locale).fromNow()
              }
            }

            if (data.disconnect) {
              if (data.lastRequestDate) {
                data.lastSeenTimestamp = dayjs.unix(data.lastRequestDate.seconds).locale(vm.$i18n.locale).format('LLL')
                //data.lastSeenTimestamp = dayjs.unix(data.lastRequestDate.seconds).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
              } else if (today.format('YYYY-MM-DD') !== dayjs(data.disconnect.toDate()).tz(vm.$defaultTimezone).format('YYYY-MM-DD')) {
                data.lastSeenTimestamp = dayjs(data.disconnect.toDate()).locale(vm.$i18n.locale).format('LLL')
                //data.lastSeenTimestamp = dayjs(data.disconnect.toDate()).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
              } else {
                data.lastSeenTimestamp = ''
              }
            }

            if (typeof data.online === 'undefined') {
              return
            }
            data.sortOrder = data.online && data.surlfyURL ? 0 : data.online ? 1 : 2

            if (typeof data.type === 'undefined' || data.type === 'embed' || data.type === 'campaign') {
              const isAgentsToBeNotified = data.agentsToBeNotified && data.agentsToBeNotified.length > 0
              const isRecipientsWithActiveUserId =
                data.recipients && data.recipients.length > 0 && data.recipients.map((x) => x.id).includes(vm.activeUserInfo.uid)

              if (isAgentsToBeNotified || isRecipientsWithActiveUserId) {
                if (isAgentsToBeNotified && data.agentsToBeNotified.includes(this.activeUserInfo.uid) && !vm.visitors.find((x) => x.id === data.id)) {
                  vm.visitors.push(data)
                }

                if (isRecipientsWithActiveUserId && !vm.visitors.find((x) => x.id === data.id)) {
                  vm.visitors.push(data)
                }
              } else if (!vm.visitors.find((x) => x.id === data.id)) {
                vm.visitors.push(data)
              }
            }
          })

          vm.visitors = _.sortBy(vm.visitors, 'sortOrder', 'modified')
          let itemNumber = 0
          vm.visitors.forEach((v) => {
            v.itemNumber = ++itemNumber
          })

          vm.$store.commit('UPDATE_VISITORS', vm.visitors)

          vm.updateVisitorsLastUpdated(dayjs().utc())
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log('Notify Visitors', error.message)
      }
    },
    processStaticIncoming(data) {
      const vm = this

      if (
        vm.widgetsAssigned &&
        vm.widgetsAssigned.length > 0 &&
        vm.activeUserInfo &&
        vm.activeUserInfo.role === 'agent' &&
        !this.widgetsAssigned.includes(data.dialogId)
      ) {
        return
      }

      data.name = data.owner || data.isp || data.netname
      data.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
      //data.city = data.region ? `${data.city}, ${data.region.toUpperCase()}` : 'Inkognito'
      const lastSeen = data.lastRequestDate || data.disconnect || data.modified || data.created
      data.lastSeen = dayjs(lastSeen.toDate()).fromNow()
      data.currentTime = new Date().getTime()

      /* For Incoming Request not attended by agent */
      if (data.agentRequest && !data.connectedAgentId && data.online) {
        data.orderIncomingRequests = -Number.MAX_SAFE_INTEGER
      } else if (!data.agentRequest && data.connectedAgentId && data.connectedAgentId.length > 0 && data.online) {
        /* For Ongoing Incoming Request attended by agent */
        data.orderIncomingRequests = -Number.MAX_SAFE_INTEGER / 2
      } else if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
        data.orderIncomingRequests = dayjs().diff(dayjs.unix(lastSeen.seconds))
      } else if (typeof lastSeen === 'string' && lastSeen.length > 0) {
        data.orderIncomingRequests = dayjs().diff(dayjs(lastSeen.toDate()))
      } else {
        data.orderIncomingRequests = Number.MAX_SAFE_INTEGER
      }

      if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
        data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).locale(vm.$i18n.locale).format('LLL')
        //  data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
      }

      if (typeof lastSeen === 'string' && lastSeen.length > 0) {
        data.lastSeenTimestamp = dayjs(lastSeen.toDate()).locale(vm.$i18n.locale).format('LLL')
        //data.lastSeenTimestamp = dayjs(lastSeen.toDate()).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
      }

      if (typeof data.online === 'undefined') {
        return
      }
      data.sortOrder = data.online && data.surlfyURL ? 0 : data.online ? 1 : 2

      const index = vm.staticIncoming.findIndex((v) => v.id === data.id)
      if (index !== -1 && vm.staticIncoming[index].id === data.id) {
        vm.staticIncoming.splice(index, 1, data)
      } else {
        vm.staticIncoming.push(data)
      }
    },

    subscribeGptChats() {
      try {
        if (this.unsubscribeGptChats) {
          this.unsubscribeGptChats()
          this.unsubscribeGptChats = null
        }

        const query = this.$db.collection('gpt-chats').where('companyId', '==', this.activeUserInfo.company)

        this.unsubscribeGptChats = query.onSnapshot(async (ref) => {
          this.gptChats = []

          ref.docs.forEach((doc) => {
            const data = doc.data()

            const visitor = {
              ...data,
              id: doc.id
            }

            this.gptChats.push(visitor)
          })

          this.$store.commit('UPDATE_GPT_CHATS', this.gptChats)
        })
      } catch (error) {
        /* eslint-disable no-console */
        console.log('Notify Gpt Chats', error.message)
      }
    },

    subscribeVisitors() {
      try {
        if (this.unsubscribeVisitors) {
          this.unsubscribeVisitors()
          this.unsubscribeVisitors = null
        }

        const vm = this
        if (vm.activeUserInfo) {
          const disallowedRoles = ['view-only']
          if (disallowedRoles.includes(vm.activeUserInfo.role)) {
            return
          }

          let query = vm.$db.collection('visitors').where('company', '==', vm.activeUserInfo.company)
          query = query.where('type', '==', 'embed')
          if (vm.b2bEnabled) {
            query = query.where('b2b', '==', true)
          }
          if (vm.showOnlineVisitors) {
            query = query.where('online', '==', true)
          }
          if (vm.dateRange && vm.dateRange.startDate && vm.dateRange.endDate) {
            const startQueryDate = dayjs(vm.dateRange.startDate).tz(vm.$defaultTimezone).startOf('day').toDate()
            const endQueryDate = dayjs(vm.dateRange.endDate).tz(vm.$defaultTimezone).endOf('day').toDate()
            query = query.where('modified', '>=', startQueryDate).where('modified', '<=', endQueryDate)
          }

          if (vm.activeUserInfo.role === 'agent' && vm.widgetsAssigned && vm.widgetsAssigned.length > 0) {
            query = query.where('dialogId', 'in', vm.widgetsAssigned)
          }

          if (vm.activeUserInfo.role === 'admin' && vm.widgetsFilterForVisitor && vm.widgetsFilterForVisitor.length > 0) {
            query = query.where('dialogId', 'in', vm.widgetsFilterForVisitor)
          }

          query = query.orderBy('modified', 'desc')
          query = query.limit(vm.maxVisitorsCount)
          vm.visitors = []

          vm.unsubscribeVisitors = query.onSnapshot(async (ref) => {
            vm.visitors = []
            ref.docs.forEach((doc) => {
              const data = doc.data()
              const visitor = {}
              if (vm.b2bEnabled && !data.b2b) {
                return
              }

              visitor.id = doc.id
              visitor.name = data.owner || data.isp || data.netname
              visitor.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
              visitor.score = data.score

              const lastModifed = dayjs().utc().diff(dayjs.unix(data.modified.seconds).utc(), 'second')
              visitor.lastModifed = lastModifed
              if (data.online && lastModifed < 600) {
                visitor.online = true
              } else {
                visitor.online = false
              }
              const scoreHighest = data.scoreHighest || data.score
              visitor.score = visitor.online ? data.score : scoreHighest

              visitor.sortOrder = visitor.online && data.isVegaWidget ? 0 : visitor.online ? 1 : 2
              visitor.isVegaWidget = data.isVegaWidget
              visitor.isVegaWidgetEnabled = data.isVegaWidgetEnabled

              const dialog = this.dialogs.find((x) => x.id === data.dialogId)
              if (dialog) {
                visitor.widgetName = dialog.name
              } else {
                visitor.widgetName = `${vm.$t('vue.widgetNotFound')} (${data.dialogId})`
                visitor.score = null
                visitor.isVegaWidgetEnabled = false
              }

              visitor.country = data.country
              visitor.street = data.street
              visitor.zipcode = data.zipcode

              visitor.citylatlong = data.citylatlong || null
              if (data.dialogId) {
                visitor.dialogId = data.dialogId
              }
              if (data.abTestId) {
                visitor.abTestId = data.abTestId
              }
              if (data.campaignId) {
                visitor.campaignId = data.campaignId
              }
              if (vm.showOnlineVisitors && !visitor.online) {
                return
              }
              visitor.b2b = data.b2b
              visitor.companyName = data.companyName
              visitor.location = data.location
              visitor.origin = data.origin
              visitor.owner = data.owner
              visitor.netname = data.netname
              visitor.isp = data.isp
              visitor.userAgent = data.userAgent
              visitor.isCallStarted = data.isCallStarted
              visitor.disconnect = data.disconnect
              visitor.firstSeen = data.firstSeen
              visitor.chats = data.chats

              if (typeof data.type === 'undefined' || data.type === 'embed' || data.type === 'campaign') {
                const isAgentsToBeNotified = data.agentsToBeNotified && data.agentsToBeNotified.length > 0
                const isRecipientsWithActiveUserId =
                  data.recipients && data.recipients.length > 0 && data.recipients.map((x) => x.id).includes(vm.activeUserInfo.uid)

                if (isAgentsToBeNotified || isRecipientsWithActiveUserId) {
                  if (isAgentsToBeNotified && data.agentsToBeNotified.includes(this.activeUserInfo.uid) && !vm.visitors.find((x) => x.id === data.id)) {
                    vm.visitors.push(visitor)
                  }

                  if (isRecipientsWithActiveUserId && !vm.visitors.find((x) => x.id === data.id)) {
                    vm.visitors.push(visitor)
                  }
                } else if (!vm.visitors.find((x) => x.id === data.id)) {
                  vm.visitors.push(visitor)
                }
              }
            })

            let itemNumber = 0
            vm.visitors.forEach((v) => {
              v.itemNumber = ++itemNumber
            })

            vm.$store.commit('UPDATE_VISITORS', vm.visitors)

            vm.updateVisitorsLastUpdated(dayjs().utc())
          })
        } else {
          vm.$store.commit('UPDATE_VISITORS', [])
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log('Notify Visitors', error.message)
      }
    },
    subscribeIncoming() {
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(this.activeUserInfo.role)) {
        return
      }

      const vm = this
      if (vm.unsubscribeIncoming) {
        vm.unsubscribeIncoming()
      }

      if (vm.unsubscribeAgent) {
        vm.unsubscribeAgent()
      }

      let query = vm.$db.collection('visitors')
      /* Call Center will accept incoming requests from multiple accounts */
      if (vm.company && vm.company.isCallCenter && vm.activeUserInfo.allowedCompanies && vm.activeUserInfo.allowedCompanies.length) {
        query = query.where('company', 'in', vm.activeUserInfo.allowedCompanies)
      } else {
        query = query.where('company', '==', vm.activeUserInfo.company)
        if (vm.activeUserInfo.role === 'agent' && vm.widgetsAssigned && vm.widgetsAssigned.length > 0) {
          query = query.where('dialogId', 'in', vm.widgetsAssigned)
        }
      }

      query = query.where('callType', '==', 'incoming-request')
      query = query.orderBy('lastRequestDate', 'desc').limit(this.maxIncomingRequestsCount)

      if (vm.activeUserInfo.role === 'agent') {
        /* Performing Logical OR Operation in Firestore */
        let agentQuery = vm.$db.collection('visitors')
        if (vm.company && vm.company.isCallCenter && vm.activeUserInfo.allowedCompanies && vm.activeUserInfo.allowedCompanies.length) {
          agentQuery = agentQuery.where('company', 'in', vm.activeUserInfo.allowedCompanies)
        } else {
          agentQuery = agentQuery.where('company', '==', vm.activeUserInfo.company)
          if (vm.widgetsAssigned && vm.widgetsAssigned.length > 0) {
            agentQuery = agentQuery.where('dialogId', 'in', vm.widgetsAssigned)
          }
        }
        agentQuery = agentQuery.where('agentRequest', '==', false)
        agentQuery = agentQuery.where('connectedAgentIds', 'array-contains', vm.activeUserInfo.uid)
        agentQuery = agentQuery.orderBy('lastRequestDate', 'desc').limit(this.maxIncomingRequestsCount)

        vm.unsubscribeAgent = agentQuery.onSnapshot(async (ref) => {
          ref.docs.forEach((doc) => {
            const data = doc.data()
            data.id = doc.id
            this.processIncomingData(data, false)
          })
          this.updateIncomingRequests(this.incoming)
        })
      }

      let key = null
      vm.unsubscribeIncoming = query.onSnapshot(async (ref) => {
        ref.docChanges().forEach((change) => {
          if (vm.incomingAdded) {
            if (change.type === 'modified') {
              if (!change.doc.data().agentRequest) {
                vm.$toast.dismiss(change.doc.id)
              }
              if (change.doc.data().agentId) {
                vm.$store.commit('NOTIFICATIONS_REMOVE', { id: change.doc.id, type: 'visitor-request' })
              }
            }

            if (change.type === 'modified' && change.doc.data().agentRequest && change.doc.data().audioPlayed === false) {
              const data = change.doc.data()
              data.id = change.doc.id
              if (!key || key !== data.key) {
                key = data.key

                if (data.shouldNotifyAgents && data.agentRequest) {
                  if (vm.company && vm.company.isCallCenter) {
                    if (data.company !== vm.company.id) {
                      if (data.agentsToBeNotified && data.agentsToBeNotified.length > 0) {
                        if (data.agentsToBeNotified.includes(this.activeUserInfo.uid)) {
                          this.notifyVisitorRequest(data)
                        }
                      }
                    } else if (data.agentsToBeNotified && data.agentsToBeNotified.length > 0) {
                      if (data.agentsToBeNotified.includes(this.activeUserInfo.uid)) {
                        this.notifyVisitorRequest(data)
                      }
                    } else {
                      this.notifyVisitorRequest(data)
                    }
                  } else if (data.agentsToBeNotified && data.agentsToBeNotified.length > 0) {
                    if (data.agentsToBeNotified.includes(this.activeUserInfo.uid)) {
                      this.notifyVisitorRequest(data)
                    }
                  } else {
                    this.notifyVisitorRequest(data)
                  }
                }
              }
            }
            if (change.type === 'removed') {
              const data = change.doc.data()
              data.id = change.doc.id
              this.processStaticIncoming(data)
            }
          }
        })

        vm.clearIncomingRequests()

        ref.docs.forEach((doc) => {
          const data = doc.data()
          data.id = doc.id
          this.processIncomingData(data, true)
        })

        this.updateIncomingRequests(this.incoming)
        vm.incomingAdded = true

        if (!this.amIConnectedWithVisitor && this.areThereIncomingRequests) {
          this.playIncomingRequestAudio()
        } else {
          this.pauseIncomingRequestAudio()
        }
      })
    },

    processIncomingData(data, isAgentQuery) {
      const vm = this

      if (
        vm.widgetsAssigned &&
        vm.widgetsAssigned.length > 0 &&
        vm.activeUserInfo &&
        vm.activeUserInfo.role === 'agent' &&
        !this.widgetsAssigned.includes(data.dialogId)
      ) {
        return
      }

      const disallowedRoles = ['agent', 'supervisor', 'view-only']
      if (
        vm.activeUserInfo &&
        disallowedRoles.includes(vm.activeUserInfo.role) &&
        data.agentsToBeNotified &&
        data.agentsToBeNotified.length > 0 &&
        !data.agentsToBeNotified.includes(vm.activeUserInfo.uid)
      ) {
        return
      }

      data.name = data.owner || data.isp || data.netname
      data.city = data.city ? data.city.charAt(0).toUpperCase() + data.city.slice(1) : null
      //data.city = data.region ? `${data.city}, ${data.region.toUpperCase()}` : 'Inkognito'
      const lastSeen = data.lastRequestDate || data.disconnect || data.modified || data.created

      data.lastSeen = dayjs(lastSeen.toDate()).fromNow()

      data.currentTime = new Date().getTime()

      /* For Incoming Request not attended by agent */
      if (data.agentRequest && !data.connectedAgentId && data.online) {
        data.orderIncomingRequests = -Number.MAX_SAFE_INTEGER
      } else if (!data.agentRequest && data.connectedAgentId && data.connectedAgentId.length > 0 && data.online) {
        /* For Ongoing Incoming Request attended by agent */
        data.orderIncomingRequests = -Number.MAX_SAFE_INTEGER / 2
      } else if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
        data.orderIncomingRequests = dayjs().diff(dayjs.unix(lastSeen.seconds))
      } else if (typeof lastSeen === 'string' && lastSeen.length > 0) {
        data.orderIncomingRequests = dayjs().diff(dayjs(lastSeen.toDate()))
      } else {
        data.orderIncomingRequests = Number.MAX_SAFE_INTEGER
      }

      if (typeof lastSeen === 'object' && lastSeen.seconds !== null) {
        data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).locale(vm.$i18n.locale).format('LLL')
        //  data.lastSeenTimestamp = dayjs.unix(lastSeen.seconds).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
      }

      if (typeof lastSeen === 'string' && lastSeen.length > 0) {
        data.lastSeenTimestamp = dayjs(lastSeen.toDate()).locale(vm.$i18n.locale).format('LLL')
        //data.lastSeenTimestamp = dayjs(lastSeen.toDate()).tz(vm.$defaultTimezone).locale(vm.$i18n.locale).format('LLL')
      }

      if (typeof data.online === 'undefined') {
        return
      }

      data.sortOrder = data.online ? 1 : 2
      data.sortOrder = data.requestType === 'outgoing-request' ? 0 : data.sortOrder

      if (typeof data.type === 'undefined' || data.type === 'embed' || data.type === 'campaign') {
        if (vm.company && vm.company.isCallCenter && vm.company === data.callCenterId) {
          vm.incoming.push({
            ...data,
            companyName: data.dialogCompanyName
          })
          vm.addToChatVisitorsIfRequired(data)
        } else if (
          (data.agentsToBeNotified && data.agentsToBeNotified.length > 0 && data.agentsToBeNotified.includes(this.activeUserInfo.uid)) ||
          (data.recipients && data.recipients.length > 0 && data.recipients.map((x) => x.id).includes(vm.activeUserInfo.uid)) ||
          (data.agentsToBeNotified && data.agentsToBeNotified.length === 0)
        ) {
          {
            vm.incoming.push(data)
            vm.addToChatVisitorsIfRequired(data)
          }
        } else if (!data.agentRequest) {
          if (vm.activeUserInfo.role !== 'agent') {
            vm.incoming.push(data)
            vm.addToChatVisitorsIfRequired(data)
          }
          if (vm.activeUserInfo.role === 'agent') {
            if (
              data.connectedAgents &&
              (data.connectedAgents.find((x) => x.agentId === vm.activeUserInfo.uid) || data.connectedAgents.filter((x) => x.type !== 'missed').length === 0)
            ) {
              vm.incoming.push(data)
              vm.addToChatVisitorsIfRequired(data)
            }
            if (isAgentQuery) {
              /* Show missed call of agents */
              if (!data.connectedAgentId && !data.connectedAgents) {
                vm.incoming.push(data)
                vm.addToChatVisitorsIfRequired(data)
              }
            }
          } else if (!isAgentQuery) {
            vm.incoming.push(data)
            vm.addToChatVisitorsIfRequired(data)
          }
        }
      }
    }
  }
}
</script>
<style lang="scss">
.vx-card.secondary-color {
  .vx-card__header {
    .vx-card__title {
      h4 {
        color: #12598d !important;
      }
    }
  }
}

.join-surfly {
  padding: 5px 10px;
  border-radius: 5px;
  border-color: transparent;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.4);
  margin-top: 10px;
  cursor: pointer;
}

.bg-white {
  background-color: white !important;
}

.primary-color {
  color: rgba(var(--vs-primary), 1) !important;
}

.secondary-color {
  color: #262629;
}

.Vue-Toastification__container {
  z-index: 999999;

  @media (max-width: 767px) {
    width: 320px !important;
    max-width: 100%;
  }

  @media only screen and (max-width: 600px) {
    left: 20px !important;
    bottom: 13px !important;

    .t-container-action {
      &:first-child {
        margin-right: 10px !important;
      }
    }

    .Vue-Toastification__close-button {
      padding-left: 0px !important;
    }
  }
}

.pathadvice-toast {
  margin-right: 0px !important;
  width: 336px;
  height: 140px;
  background: #1c8439;
  box-shadow: 0 0 0 rgba(28, 132, 57, 1);
  animation: pulse-toast 2s infinite;
}

@-webkit-keyframes pulse-toast {
  0% {
    -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
  }

  70% {
    -webkit-box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
  }

  100% {
    -webkit-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
  }
}

@keyframes pulse-toast {
  0% {
    -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 1);
    box-shadow: 0 0 0 0 rgba(28, 132, 57, 0.4);
  }

  70% {
    -moz-box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
    box-shadow: 0 0 0 10px rgba(28, 132, 57, 0);
  }

  100% {
    -moz-box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
    box-shadow: 0 0 0 0 rgba(28, 132, 57, 0);
  }
}
</style>
