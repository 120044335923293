<template>
  <div></div>
</template>
<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
  data() {
    return {
      unsubscribeAssignmentRules: null,
      assignment_rules: []
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },
  created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeAssignmentRules()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeAssignmentRules) {
      this.unsubscribeAssignmentRules()
    }
  },
  methods: {
    ...mapMutations({
      updateAssignmentRules: 'UPDATE_ASSIGNMENT_RULES'
    }),
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeAssignmentRules() {
      const vm = this
      if (vm.unsubscribeAssignmentRules) {
        vm.unsubscribeAssignmentRules()
      }
      const query = vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('rules')
      vm.unsubscribeAssignmentRules = query.onSnapshot(async (ref) => {
        vm.assignment_rules = []

        ref.docs.forEach((doc) => {
          const assignment_rule = {
            id: doc.id,
            name: doc.data().ruleName,
            enabled: doc.data().enabled,
            position: typeof doc.data().position !== undefined ? doc.data().position : Number.MAX_SAFE_INTEGER
          }

          vm.assignment_rules.push(assignment_rule)
        })
        vm.updateAssignmentRules(vm.assignment_rules)
      })
    }
  }
}
</script>
