<template>
  <svg :width="width" :height="height" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1 1L8.77778 6L1 11V1Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
  </svg>
</template>

<script>
export default {
  name: 'PlayIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 7.78
    },
    height: {
      type: Number,
      default: 10
    }
  }
}
</script>
