<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      unsubscribeUserGroups: null,
      user_groups: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    const vm = this
    if (vm.activeUserInfo && vm.activeUserInfo.company) {
      vm.subscribeUserGroups()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeUserGroups) {
      this.unsubscribeUserGroups()
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeUserGroups() {
      const vm = this
      if (vm.unsubscribeUserGroups) {
        vm.unsubscribeUserGroups()
      }
      const query = vm.$db.collection('company').doc(vm.activeUserInfo.company).collection('user-groups')
      vm.unsubscribeUserGroups = query.onSnapshot(async (ref) => {
        vm.user_groups = []
        ref.docs.forEach((doc) => {
          const user_group = {
            id: doc.id,
            name: doc.data().name,
            users: doc.data().users,
            widgets: doc.data().widgets
          }
          vm.user_groups.push(user_group)
        })
        vm.$store.commit('UPDATE_USER_GROUPS', vm.user_groups)
      })
    }
  }
}
</script>
