<template>
  <div class="t-container">
    <div class="t-container-header fc-white heading">
      <div>{{ visitorRequestText }}</div>
      <div v-if="requestType === 'chat'">
        <chat-icon :width="20" :height="20"></chat-icon>
      </div>
      <div v-if="requestType === 'audio'">
        <microphone-icon :width="20" :height="20"></microphone-icon>
      </div>
      <div v-if="requestType === 'video'">
        <video-call-icon :width="20" :height="20"></video-call-icon>
      </div>
    </div>
    <div class="t-container-place fc-white mt-2 mb-2">{{ visitor }}</div>

    <div class="t-container-action mt-2 mb-2">
      <button class="t-container-action--cancel" @click="declineCall">{{ declineButtonText }}</button>
      <button class="t-container-action--accept" ref="acceptCallButton" :disabled="isButtonDisabled" @click="acceptCall">{{ acceptButtonText }}</button>
    </div>
  </div>
</template>
<script>
import store from '../../../../store/store'
import router from '../../../../router'
import { mvisitors } from '@/mixins/mvisitors'
import ChatIcon from '@/components/icons/ChatIcon.vue'
import MicrophoneIcon from '@/components/icons/MicrophoneIcon.vue'
import VideoCallIcon from '@/components/icons/VideoCallIcon.vue'

export default {
  store,
  router,
  mixins: [mvisitors],
  props: [
    'visitor',
    'requestType',
    'visitorId',
    'surlfyURL',
    'joinURL',
    'visitorRequestText',
    'joinButtonText',
    'surlfySessionId',
    'dialogId',
    'abTestId',
    'location',
    'declineButtonText',
    'acceptButtonText'
  ],
  components: {
    ChatIcon,
    MicrophoneIcon,
    VideoCallIcon
  },
  data() {
    return {
      isButtonDisabled: false
    }
  },
  methods: {
    async declineCall() {
      await this.declineVisitorCall(this.visitorId)
      this.$toast.dismiss(this.visitorId)
    },
    acceptCall() {
      if (this.$refs.acceptCallButton.disabled) return
      this.isButtonDisabled = true
      if (this.$route.name !== 'incoming-requests') {
        this.$router.push({ name: 'incoming-requests', params: { id: this.visitorId, action: 'accept-call' } })
      } else {
        this.$serverBus.$emit('on-agent-accept-call', this.visitorId)
      }
      setTimeout(() => {
        this.isButtonDisabled = false
      }, 10000)
    },
    joinSurfly() {
      const vm = this
      const visitor = {
        id: vm.visitorId,
        surlfyURL: vm.surlfyURL,
        joinURL: vm.joinURL,
        surlfySessionId: vm.surlfySessionId,
        dialogId: vm.dialogId,
        abTestId: vm.abTestId,
        location: vm.location
      }
      vm.$serverBus.$emit('toast-join-surfly', visitor)
    }
  }
}
</script>
<style lang="scss" scoped>
.t-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  font-family: 'Lato', Helvetica, Arial, sans-serif;
  &-header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    div {
      margin-right: 10px;
    }
  }
  &-place {
    text-transform: capitalize;
  }
  &-action {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    button {
      box-sizing: border-box;
      width: 129px;
      height: 38px;
      border-radius: 6px;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #ffffff;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.5);
      }
    }

    &--cancel {
      background: #ed0d0d;
      border: 1px solid #ed0d0d;
    }
    &--accept {
      background: rgba(255, 255, 255, 0.1);
      border: 1px solid #ffffff;
    }
  }
}
.heading {
  font-size: 1.1rem;
  font-weight: bold;
}
.fc-white {
  color: white;
}
</style>
