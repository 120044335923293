<template>
  <div v-if="callCenterUserWorkTime" class="cc-banner" :class="bannerAnimation">
    <div class="cc-banner__text">
      <span v-if="canShowStartButton" class="cc-banner__text_animation">{{ $t('banners.callCenterStartWorkTime') }}</span>
      <div v-else>
        <span class="uppercase">{{ $t('vue.totalWorkTime') }} :</span>
        <span class="cc-banner__text_work-time"> {{ TOTAL_WORK_TIME }}</span>
        <span class="uppercase ml-2">{{ $t('vue.totalPausedTime') }} : </span>
        <span class="cc-banner__text_pause-time"> {{ TOTAL_PAUSED_TIME }}</span>

        <span v-if="callCenterUserWorkTime.totalSessionDuration" class="uppercase ml-2">{{ $t('vue.totalSessionTime') }} : </span>
        <span v-if="callCenterUserWorkTime.totalSessionDuration" class="cc-banner__text_session-time"> {{ TOTAL_SESSION_TIME }}</span>

        <span v-if="callCenterUserWorkTime.totalReworkTime" class="uppercase ml-2">{{ $t('vue.totalReworkTime') }} : </span>
        <span v-if="callCenterUserWorkTime.totalReworkTime" class="cc-banner__text_rework-time"> {{ TOTAL_REWORK_TIME }}</span>
      </div>
    </div>
    <div class="flex flex-row justify-center items-center">
      <div
        v-if="canShowStartButton"
        class="cc-banner__startstop-button cc-banner__startstop-button-start flex flex-row justify-center items-center"
        :class="isUpdating ? 'cc-banner__startstop-button-start-update-in-progress' : ''"
        @click="startWorkTime()"
      >
        <div class="cc-banner__startstop-button_text">{{ $t('inputs.start') }}</div>
        <div><play-icon /></div>
      </div>
      <div v-if="canShowPauseButton" class="ml-4 flex flex-row justify-center items-center" @click="pauseWork()">
        <div class="cc-banner__text">AT WORK</div>
        <div class="cc-banner__pause-button flex flex-row justify-center items-center" :class="isUpdating ? 'cc-banner__pause-button-update-in-progress' : ''">
          <div><pause-icon /></div>
        </div>
      </div>
      <div v-if="canShowResumeButton" class="ml-4 flex flex-row justify-center items-center" @click="resumeWorkAt()">
        <div class="cc-banner__text" v-if="callCenterUserWorkTime.currentWorkState === WORKTIME_STATUS.PAUSED">IN PAUSE</div>
        <div class="cc-banner__text" v-if="callCenterUserWorkTime.currentWorkState === WORKTIME_STATUS.ENDED">ENDED</div>
        <div class="cc-banner__resume-button flex justify-center items-center" :class="isUpdating ? 'cc-banner__resume-button-update-in-progress' : ''">
          <div><play-icon /></div>
        </div>
      </div>
      <div
        v-if="canShowEndButton"
        class="ml-4 cc-banner__startstop-button cc-banner__startstop-button-end flex flex-row justify-center items-center"
        :class="isUpdating ? 'cc-banner__startstop-button-end-update-in-progress' : ''"
        @click="stopWorkTime()"
      >
        <div class="cc-banner__startstop-button_text">{{ $t('inputs.end') }}</div>
        <div class="cc-banner__startstop-button_icon-end"><stop-icon /></div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import PlayIcon from '@/components/icons/PlayIcon.vue'
import PauseIcon from '@/components/icons/PauseIcon.vue'
import StopIcon from '@/components/icons/StopIcon.vue'

const WORKTIME_STATUS = {
  ENDED: 'ended',
  STARTED: 'started',
  PAUSED: 'paused',
  RESUMED: 'resumed'
}

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)

export default {
  name: 'CCWorktimeBanner',
  components: {
    PlayIcon,
    PauseIcon,
    StopIcon
  },
  data() {
    return {
      currentTime: dayjs(),
      dateId: dayjs().format('YYYYMMDD'),
      unsubscribeWorkTime: null,
      isUpdating: false,
      WORKTIME_STATUS
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company',
      callCenterUserWorkTime: 'call_center_user_worktime'
    }),
    bannerAnimation() {
      if (
        this.callCenterUserWorkTime &&
        (!this.callCenterUserWorkTime.currentWorkState ||
          this.callCenterUserWorkTime.currentWorkState === 'paused' ||
          this.callCenterUserWorkTime.currentWorkState === 'ended')
      ) {
        return 'cc-banner_animation'
      } else {
        return ''
      }
    },
    TOTAL_WORK_TIME_SECONDS() {
      let workTime = ''
      const _state = this.callCenterUserWorkTime.currentWorkState

      if (_state === this.WORKTIME_STATUS.STARTED || _state === this.WORKTIME_STATUS.PAUSED || _state === this.WORKTIME_STATUS.RESUMED) {
        const startedWorkAt = dayjs.unix(this.callCenterUserWorkTime.startedWorkAt.seconds).utc()
        workTime = this.currentTime.utc().diff(startedWorkAt, 'second') - this.TOTAL_PAUSED_TIME_SECONDS
      }
      if (_state === this.WORKTIME_STATUS.ENDED) {
        const startedWorkAt = dayjs.unix(this.callCenterUserWorkTime.startedWorkAt.seconds).utc()
        const endedWorkAt = dayjs.unix(this.callCenterUserWorkTime.endedWorkAt.seconds).utc()
        workTime = endedWorkAt.utc().diff(startedWorkAt, 'second') - this.TOTAL_PAUSED_TIME_SECONDS
      }

      return workTime
    },
    TOTAL_WORK_TIME() {
      const totalWorkTime = this.TOTAL_WORK_TIME_SECONDS
      if (totalWorkTime < 60) {
        return dayjs().startOf('day').add(this.TOTAL_WORK_TIME_SECONDS, 's').format('ss')
      } else if (totalWorkTime < 3600) {
        return dayjs().startOf('day').add(this.TOTAL_WORK_TIME_SECONDS, 's').format('mm:ss')
      } else {
        return dayjs().startOf('day').add(this.TOTAL_WORK_TIME_SECONDS, 's').format('HH:mm:ss')
      }
    },
    TOTAL_SESSION_TIME() {
      const totalSessionDuration = this.callCenterUserWorkTime.totalSessionDuration || 0
      if (totalSessionDuration < 60) {
        return dayjs().startOf('day').add(totalSessionDuration, 's').format('ss')
      } else if (totalSessionDuration < 3600) {
        return dayjs().startOf('day').add(totalSessionDuration, 's').format('mm:ss')
      } else {
        return dayjs().startOf('day').add(totalSessionDuration, 's').format('HH:mm:ss')
      }
    },
    TOTAL_REWORK_TIME() {
      const totalReworkTime = this.callCenterUserWorkTime.totalReworkTime
      if (totalReworkTime < 60) {
        return dayjs().startOf('day').add(totalReworkTime, 's').format('ss')
      } else if (totalReworkTime < 3600) {
        return dayjs().startOf('day').add(totalReworkTime, 's').format('mm:ss')
      } else {
        return dayjs().startOf('day').add(totalReworkTime, 's').format('HH:mm:ss')
      }
    },
    TOTAL_PAUSED_TIME_SECONDS() {
      let totalPausedTime = 0
      const pauseTimeInDb = this.callCenterUserWorkTime.totalPausedTime || 0
      if (
        this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.STARTED ||
        this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.RESUMED ||
        this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.ENDED
      ) {
        totalPausedTime = pauseTimeInDb
      } else {
        if (this.callCenterUserWorkTime.pausedWorkAt) {
          const pausedWorkAt = dayjs.unix(this.callCenterUserWorkTime.pausedWorkAt.seconds).utc()
          totalPausedTime = this.currentTime.utc().diff(pausedWorkAt, 'second')
        }
        totalPausedTime += pauseTimeInDb
      }
      return totalPausedTime
    },
    TOTAL_PAUSED_TIME() {
      const totalPausedTime = this.TOTAL_PAUSED_TIME_SECONDS
      if (totalPausedTime < 60) {
        return dayjs().startOf('day').add(this.TOTAL_PAUSED_TIME_SECONDS, 's').format('ss')
      } else if (totalPausedTime < 3600) {
        return dayjs().startOf('day').add(this.TOTAL_PAUSED_TIME_SECONDS, 's').format('mm:ss')
      } else {
        return dayjs().startOf('day').add(this.TOTAL_PAUSED_TIME_SECONDS, 's').format('HH:mm:ss')
      }
    },
    canShowStartButton() {
      return this.callCenterUserWorkTime && !this.callCenterUserWorkTime.startedWorkAt
    },
    canShowPauseButton() {
      return (
        this.callCenterUserWorkTime &&
        this.callCenterUserWorkTime.startedWorkAt &&
        (this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.STARTED ||
          this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.RESUMED) &&
        !this.callCenterUserWorkTime.endedWorkAt
      )
    },
    canShowResumeButton() {
      return (
        this.callCenterUserWorkTime &&
        this.callCenterUserWorkTime.startedWorkAt &&
        (this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.PAUSED ||
          this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.ENDED)
      )
    },
    canShowEndButton() {
      return this.callCenterUserWorkTime && this.callCenterUserWorkTime.startedWorkAt && !this.callCenterUserWorkTime.endedWorkAt
    }
  },
  watch: {
    dateId() {
      this.subscribeWorkTime()
    },
    'callCenterUserWorkTime.currentWorkState'() {
      this.updateAgentOnlineStatus()
    },
    'company.isCallCenter'() {
      if (this.company && this.company.isCallCenter) {
        this.updateAgentOnlineStatus()
      }
    }
  },
  created() {
    const vm = this
    setInterval(function () {
      vm.currentTime = dayjs()
      vm.dateId = dayjs().format('YYYYMMDD')
    }, 1000)
  },
  beforeMount() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeWorkTime()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeWorkTime) {
      this.unsubscribeWorkTime()
    }
  },

  methods: {
    ...mapMutations({
      updateCallCenterUserWorktime: 'UPDATE_CALL_CENTER_USER_WORKTIME'
    }),
    updateAgentOnlineStatus() {
      if (
        this.activeUserInfo &&
        this.activeUserInfo.company &&
        this.activeUserInfo.uid &&
        this.callCenterUserWorkTime &&
        this.company &&
        this.company.isCallCenter
      ) {
        const ref = this.$firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
        const agentState = {
          displayName: this.activeUserInfo.displayName,
          last_changed: this.$firebase.database.ServerValue.TIMESTAMP,
          state: 'online'
        }

        if (
          this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.STARTED ||
          this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.RESUMED
        ) {
          agentState.ccstate = 'online'
        } else {
          agentState.ccstate = 'offline'
        }
        ref.update(agentState)
      }
    },
    subscribeWorkTime() {
      const vm = this
      if (vm.unsubscribeWorkTime) {
        vm.unsubscribeWorkTime()
      }
      const date = this.dateId
      const query = vm.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date)

      vm.unsubscribeWorkTime = query.onSnapshot(async (ref) => {
        if (ref && ref.data()) {
          const workTime = ref.data()
          this.updateCallCenterUserWorktime(workTime)
        } else {
          const workTime = {
            currentWorkState: null,
            startedWorkAt: null,
            resumedWorkAt: null,
            pausedWorkAt: null,
            endedWorkAt: null
          }
          this.updateCallCenterUserWorktime(workTime)
        }
      })
    },

    async startWorkTime() {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      try {
        const date = this.dateId
        await this.$db
          .collection('users')
          .doc(this.activeUserInfo.uid)
          .collection('work-times')
          .doc(date)
          .set(
            { startedWorkAt: new Date(), currentWorkState: this.WORKTIME_STATUS.STARTED, totalPausedTime: 0, callCenterId: this.activeUserInfo.company },
            { merge: true }
          )
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
      this.isUpdating = false
    },
    async pauseWork() {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      try {
        const date = this.dateId
        const workTimeDoc = await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).get()
        const workTime = workTimeDoc.data()
        if (workTime) {
          const workState = {
            pausedWorkAt: new Date(),
            resumedWorkAt: this.$firebase.firestore.FieldValue.delete(),
            currentWorkState: this.WORKTIME_STATUS.PAUSED
          }
          await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).set(workState, { merge: true })
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
      this.isUpdating = false
    },
    async resumeWorkAt() {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      try {
        const date = this.dateId
        const workTimeDoc = await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).get()
        const workTime = workTimeDoc.data()
        if (workTime) {
          const pauseTimeInDb = this.callCenterUserWorkTime.totalPausedTime
          const workState = {
            resumedWorkAt: new Date(),
            currentWorkState: this.WORKTIME_STATUS.RESUMED
          }
          if (this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.PAUSED) {
            const pausedWorkAt = dayjs.unix(this.callCenterUserWorkTime.pausedWorkAt.seconds).utc()
            workState.totalPausedTime = this.currentTime.utc().diff(pausedWorkAt, 'second') + pauseTimeInDb
          }

          if (this.callCenterUserWorkTime.currentWorkState === this.WORKTIME_STATUS.ENDED) {
            const endedWorkAt = dayjs.unix(this.callCenterUserWorkTime.endedWorkAt.seconds).utc()
            workState.totalPausedTime = this.currentTime.utc().diff(endedWorkAt, 'second') + pauseTimeInDb
            workState.endedWorkAt = this.$firebase.firestore.FieldValue.delete()
          }

          await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).set(workState, { merge: true })
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }

      this.isUpdating = false
    },
    async stopWorkTime() {
      if (this.isUpdating) {
        return
      }
      this.isUpdating = true
      try {
        const date = this.dateId
        const workTimeDoc = await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).get()
        const workTime = workTimeDoc.data()
        if (workTime) {
          await this.$db
            .collection('users')
            .doc(this.activeUserInfo.uid)
            .collection('work-times')
            .doc(date)
            .set({ endedWorkAt: new Date(), currentWorkState: this.WORKTIME_STATUS.ENDED }, { merge: true })
        }
      } catch (error) {
        /* eslint-disable no-console */
        console.log(error.message)
      }
      this.isUpdating = false
    }
  }
}
</script>

<style lang="scss" scoped>
.cc-banner {
  &_animation {
    box-shadow: 0 0 0 rgba(59, 134, 247, 1);
  }
  width: 100%;
  background: #3b86f7;
  padding: 7px 25px 7px 40px;
  min-height: 44px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-width: 576px) {
    padding: 7px 1.2rem 7px 1.2rem;
    margin-left: -1.2rem;
    width: calc(100% + 2.4rem);
  }
  &__text {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 20px;
    max-width: 950px;
    margin-right: 12px;
    &_work-time {
      color: #7df836;
      font-weight: 600;
    }
    &_pause-time {
      color: #ffec44;
      font-weight: 600;
    }
    &_session-time {
      color: #12598d;
      font-weight: 600;
    }
    &_rework-time {
      color: #b2b2b2;
      font-weight: 600;
    }
    &_animation {
      color: #fff;
      text-align: center;
      -webkit-animation: glow 1s ease-in-out infinite alternate;
      -moz-animation: glow 1s ease-in-out infinite alternate;
      animation: glow 1s ease-in-out infinite alternate;
    }
  }
  &__startstop-button {
    border-radius: 6px;
    cursor: pointer;
    &-start {
      background: #5bb328;
      box-shadow: 0 0 0 rgba(91, 179, 40, 1);
      padding-left: 10px;
      padding-right: 10px;
      animation: wiggle 2s linear infinite;
      height: 30px;
      &-update-in-progress {
        background: #ccc !important;
        box-shadow: 0 0 0 rgba(204, 204, 204, 1);
        cursor: default;
      }
    }
    &-end {
      background: #f05541;
      box-shadow: 0 0 0 rgba(240, 85, 65, 1);
      padding-left: 10px;
      padding-right: 10px;
      animation: pulse 2s infinite;
      height: 30px;
      &-update-in-progress {
        background: #ccc !important;
        box-shadow: 0 0 0 rgba(204, 204, 204, 1);
        cursor: default;
      }
    }

    &_text {
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      font-weight: 600;
      margin-right: 5px;
      color: #fff;
    }
    &_icon-end {
      height: 15px;
    }
  }
  &__pause-button {
    width: 38px;
    height: 30px;
    background: #f0a941;
    animation: pulse 2s infinite;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(240, 169, 65, 1);
    &-update-in-progress {
      background: #ccc !important;
      box-shadow: 0 0 0 rgba(204, 204, 204, 1);
      cursor: default;
    }
  }
  &__resume-button {
    width: 38px;
    height: 30px;
    background: #5bb328;
    animation: wiggle 2s linear infinite;
    border-radius: 6px;
    cursor: pointer;
    box-shadow: 0 0 0 rgba(91, 179, 40, 1);
    &-update-in-progress {
      background: #ccc !important;
      box-shadow: 0 0 0 rgba(204, 204, 204, 1);
      cursor: default;
    }
  }
}

@keyframes pulse {
  to {
    box-shadow: 0 0 0 8px rgba(232, 76, 61, 0);
  }
}

@-webkit-keyframes glow {
  from {
    text-shadow: 0 0 5px #fff;
  }
  to {
    text-shadow: 0 0 5x #fff;
  }
}

@keyframes glow {
  from {
    text-shadow: 0 0 5px #fff;
  }
  to {
    text-shadow: 0 0 5x #fff;
  }
}

/* Keyframes */
@keyframes wiggle {
  0%,
  7% {
    transform: rotateZ(0);
  }
  15% {
    transform: rotateZ(-15deg);
  }
  20% {
    transform: rotateZ(10deg);
  }
  25% {
    transform: rotateZ(-10deg);
  }
  30% {
    transform: rotateZ(6deg);
  }
  35% {
    transform: rotateZ(-4deg);
  }
  40%,
  100% {
    transform: rotateZ(0);
  }
}
</style>
