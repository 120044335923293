const plansModule = require('@/assets/plans/appsumo_plans.js')
const plans = plansModule.plans

export const appsumo_subscription = {
  data() {
    return {}
  },
  computed: {
    appSumoPlans() {
      if (plans && plans.length) {
        return plans
      }
      return []
    }
  },
  methods: {}
}
