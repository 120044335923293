<template>
  <div></div>
</template>
<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  name: 'NotifyLeads',
  data() {
    return {
      unsubscribeLeads: null,
      leads: []
    }
  },

  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      widgetsAssigned: 'widgetsAssigned',
      widgetsAssignedKey: 'widgetsAssignedKey'
    })
  },

  created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.subscribeLeads()
    }
  },

  beforeDestroy() {
    this.unsubscribeListeners()
  },

  watch: {
    widgetsAssignedKey() {
      if (this.activeUserInfo && this.activeUserInfo.role === 'agent') {
        this.subscribeLeads()
      }
    }
  },

  methods: {
    unsubscribeListeners() {
      if (this.unsubscribeLeads) {
        this.unsubscribeLeads()
      }
    },

    async subscribeLeads() {
      if (!this.activeUserInfo) {
        return
      }
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(this.activeUserInfo.role)) {
        return
      }

      this.unsubscribeListeners()

      const baseQuery = this.$db
        .collection('visitor-contacts')
        .where('companyId', '==', this.activeUserInfo.company)
        .where('type', 'in', ['lead-form-saved', 'form-saved'])
        .orderBy('created', 'desc')
        .limit(500)

      if (this.activeUserInfo && this.activeUserInfo.role === 'agent' && this.widgetsAssigned && this.widgetsAssigned.length > 0) {
        const dialogIdQuery = this.$db.collection('visitor-contacts').where('dialogId', 'in', this.widgetsAssigned).orderBy('created', 'desc').limit(500)

        const processSnapshots = (baseLeads, dialogLeads) => {
          const baseLeadDocs = baseLeads.docs.map((doc) => {
            const data = doc.data()
            data.id = doc.id
            return data
          })
          const dialogLeadDocs = dialogLeads.docs.map((doc) => {
            const data = doc.data()
            data.id = doc.id
            return data
          })

          // Combining and deduplicating leads
          const combinedLeads = [...baseLeadDocs, ...dialogLeadDocs]
          const uniqueLeadsMap = combinedLeads.reduce((acc, lead) => {
            acc[lead.id] = lead
            return acc
          }, {})
          const finalLeads = Object.values(uniqueLeadsMap)

          this.updateLeads(finalLeads)
        }

        const baseUnsub = baseQuery.onSnapshot((baseLeads) => {
          dialogIdQuery.get().then((dialogLeads) => {
            processSnapshots(baseLeads, dialogLeads)
          })
        })

        const dialogUnsub = dialogIdQuery.onSnapshot((dialogLeads) => {
          baseQuery.get().then((baseLeads) => {
            processSnapshots(baseLeads, dialogLeads)
          })
        })

        this.unsubscribeLeads = () => {
          baseUnsub()
          dialogUnsub()
        }
      } else {
        const unsub = baseQuery.onSnapshot((snapshot) => {
          const finalLeads = snapshot.docs.map((doc) => {
            const leadData = doc.data()
            leadData.id = doc.id
            return leadData
          })
          this.updateLeads(finalLeads)
        })

        this.unsubscribeLeads = unsub
      }
    },

    updateLeads(leads) {
      this.leads = leads.map((lead) => {
        if (['string', 'number'].includes(typeof lead.created)) {
          lead.created = dayjs(lead.created).toDate()
        }
        if (lead.created.seconds) {
          lead.created = dayjs.unix(lead.created.seconds).toDate()
        }
        return lead
      })

      this.$store.commit('UPDATE_LEADS', this.leads)
    }
  }
}
</script>
