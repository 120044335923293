var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notifications.length > 0
    ? _c(
        "vs-dropdown",
        {
          staticClass: "cursor-pointer",
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
        },
        [
          _c("feather-icon", {
            staticClass: "cursor-pointer mt-1 sm:mr-6 mr-2",
            attrs: { icon: "ArchiveIcon", badge: _vm.notifications.length },
          }),
          _c(
            "vs-dropdown-menu",
            {
              staticClass:
                "notification-dropdown dropdown-custom vx-navbar-dropdown",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "notification-top text-center p-5 bg-primary text-white",
                },
                [
                  _c("h3", { staticClass: "text-white" }, [
                    _vm._v(_vm._s(_vm.notifications.length) + " New"),
                  ]),
                  _c("p", { staticClass: "opacity-75" }, [
                    _vm._v("Download Notifications"),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  ref: "mainSidebarPs",
                  tag: "component",
                  staticClass: "scroll-area--nofications-dropdown p-0 mb-10",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "bordered-items" },
                    _vm._l(_vm.notifications, function (ntf) {
                      return _c(
                        "li",
                        {
                          key: ntf.id,
                          staticClass:
                            "flex justify-between px-4 py-4 notification cursor-pointer",
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "flex items-start" },
                            [
                              _c("feather-icon", {
                                attrs: {
                                  icon: ntf.icon,
                                  svgClasses: [
                                    "text-" + ntf.category,
                                    "stroke-current mr-1 h-6 w-6",
                                  ],
                                },
                              }),
                              _c("div", { staticClass: "mx-2" }, [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-medium block notification-title",
                                    class: ["text-" + ntf.category],
                                  },
                                  [_vm._v(_vm._s(ntf.title))]
                                ),
                                _c("small", [_vm._v(_vm._s(ntf.msg))]),
                                _c("small", [_vm._v(_vm._s(ntf.filename))]),
                              ]),
                              _c(
                                "div",
                                { staticClass: "flex" },
                                [
                                  _c("vs-button", {
                                    staticClass: "mr-2",
                                    attrs: {
                                      color: "success",
                                      type: "gradient",
                                      icon: "download",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.downloadFile(ntf)
                                      },
                                    },
                                  }),
                                  _c("vs-button", {
                                    staticClass: "mr-5",
                                    attrs: {
                                      color: "danger",
                                      type: "gradient",
                                      icon: "delete_forever",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.deleteFile(ntf)
                                      },
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "small",
                            { staticClass: "mt-1 whitespace-no-wrap" },
                            [_vm._v(_vm._s(_vm.elapsedTime(ntf.time)))]
                          ),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }