var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-cancelled-sub" },
    [
      _c(
        "div",
        { staticClass: "ui-cancelled-sub__logout-wrapper" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.logout },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.logout")))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-col items-center w-full" }, [
        _c("div", { staticClass: "ui-cancelled-sub__title" }, [
          _vm._v(_vm._s(_vm.$t("vue.yourAccountHasExpired"))),
        ]),
        _c("div", { staticClass: "ui-cancelled-sub__subtitle" }, [
          _vm._v(_vm._s(_vm.$t("vue.pleaseChoosePlansToProceed"))),
        ]),
      ]),
      _c("MolliePricingPlans", {
        attrs: {
          isOpenedFeaturesListOnMobile: _vm.isOpenedFeaturesListOnMobile,
          isOpenedFeaturesList: _vm.isOpenedFeaturesList,
          isOpenedBasicFeaturesList: _vm.isOpenedBasicFeaturesList,
          isOpenedProFeaturesList: _vm.isOpenedProFeaturesList,
          isOpenedEnterpriseFeaturesList: _vm.isOpenedEnterpriseFeaturesList,
          featuresList: _vm.featuresList,
        },
        on: { "select-plan": _vm.selectPlan },
      }),
      _c(
        "connect-modal",
        {
          attrs: {
            show: _vm.showMollieComponent,
            width: "505px",
            bkcolor: "#F7F7F7",
          },
        },
        [
          _c(
            "div",
            {
              staticClass: "ui-cancelled-sub-mollie-close-button",
              staticStyle: { "margin-left": "auto" },
              on: { click: _vm.onMollieFormClose },
            },
            [
              _c("close-circle-icon", {
                staticClass:
                  "ui-cancelled-sub-mollie-close-button-icon pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
          _vm.selectedPlan
            ? _c("mollie", {
                attrs: {
                  selectedPlan: _vm.selectedPlan,
                  subscriptionPlanId: _vm.selectedPlan.id,
                  isModal: true,
                  supportedPaymentMethods: _vm.supportedPaymentMethods,
                  redirectUrl: _vm.mollieRedirectUrl,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }