<template>
  <button class="quick-guide-button" v-if="displayHelp && activeUserInfo && activeUserInfo.role !== 'financial'" @click.prevent="startQuickGuide">
    <img :src="require('@/assets/images/quick-guide/question-mark.svg')" alt="question-mark" />
  </button>
</template>

<script>
export default {
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    displayHelp() {
      return this.activeUserInfo && this.activeUserInfo.company
    }
  },
  methods: {
    startQuickGuide() {
      this.$serverBus.$emit('quick-guide-restart')
    }
  }
}
</script>

<style>
.quick-guide-button {
  width: 40px;
  min-width: 40px;
  height: 40px;
  background: #275d73;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 50%;
  cursor: pointer;
  outline: none;
  border: none;
}
@media only screen and (max-width: 1200px) {
  .tour-restart {
    display: none;
  }
  .v-tour {
    display: none;
  }
}
</style>
