var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "relative" }, [
    _c(
      "div",
      {
        staticClass: "vx-navbar-wrapper pa-vx-navbar-wrapper",
        class: {
          classObj: _vm.classObj,
          _locked: _vm.lockedUI,
        },
      },
      [
        _c("in-app-messaging-carousel"),
        _c(
          "vs-navbar",
          {
            staticClass: "vx-navbar navbar-custom navbar-skelton",
            class: _vm.textColor,
            attrs: { color: _vm.navbarColorLocal },
          },
          [
            _c("call-center-settings"),
            _c("agency-settings"),
            _c("push-notification"),
            _c("feather-icon", {
              staticClass: "sm:inline-flex xl:hidden cursor-pointer p-2",
              attrs: { icon: "MenuIcon" },
              on: {
                click: function ($event) {
                  $event.stopPropagation()
                  return _vm.showSidebar.apply(null, arguments)
                },
              },
            }),
            _c("notify-in-app-messaging"),
            _vm.activeUserInfo && _vm.activeUserInfo.company
              ? _c("notify-pathadvice-invoices")
              : _vm._e(),
            _c("notify-visitors"),
            _c("notify-meetings"),
            _c("notify-campaigns"),
            _c("notify-leads"),
            !_vm.isMobile ? _c("online-users") : _vm._e(),
            _c("assignment-rules"),
            _c("user-groups"),
            _c("dialogs"),
            _vm.windowWidth >= 992
              ? _c("bookmarks", { attrs: { navbarColor: _vm.navbarColor } })
              : _vm._e(),
            _c("vs-spacer"),
            _c("tour-restart", { staticClass: "mr-4" }),
            _c("visitor-notifications", { staticClass: "mr-4" }),
            _vm.activeUserInfo && _vm.activeUserInfo.superadmin
              ? _c("download-notifications", { staticClass: "mr-4" })
              : _vm._e(),
            _c("profile-drop-down", { attrs: { isMobile: _vm.isMobile } }),
            _vm.activeUserInfo &&
            _vm.activeUserInfo.role === "agent" &&
            _vm.activeUserInfo.company
              ? _c("widgets-assigned")
              : _vm._e(),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }