<template>
  <div class="ui-cancelled-sub">
    <div class="ui-cancelled-sub__logout-wrapper">
      <vs-button color="primary" type="filled" @click="logout"> {{ $t('vue.logout') }}</vs-button>
    </div>
    <div class="flex flex-col items-center w-full">
      <div class="ui-cancelled-sub__title">{{ $t('vue.yourAccountHasExpired') }}</div>
      <div class="ui-cancelled-sub__subtitle">{{ $t('vue.pleaseChoosePlansToProceed') }}</div>
    </div>
    <MolliePricingPlans
      :isOpenedFeaturesListOnMobile="isOpenedFeaturesListOnMobile"
      :isOpenedFeaturesList="isOpenedFeaturesList"
      :isOpenedBasicFeaturesList="isOpenedBasicFeaturesList"
      :isOpenedProFeaturesList="isOpenedProFeaturesList"
      :isOpenedEnterpriseFeaturesList="isOpenedEnterpriseFeaturesList"
      :featuresList="featuresList"
      @select-plan="selectPlan"
    />
    <connect-modal :show="showMollieComponent" :width="'505px'" bkcolor="#F7F7F7">
      <div class="ui-cancelled-sub-mollie-close-button" style="margin-left: auto" @click="onMollieFormClose">
        <close-circle-icon class="ui-cancelled-sub-mollie-close-button-icon pa-bk-secondary" :show-circle="false" :width="14" :height="14"></close-circle-icon>
      </div>
      <mollie
        v-if="selectedPlan"
        :selectedPlan="selectedPlan"
        :subscriptionPlanId="selectedPlan.id"
        :isModal="true"
        :supportedPaymentMethods="supportedPaymentMethods"
        :redirectUrl="mollieRedirectUrl"
      ></mollie>
    </connect-modal>
  </div>
</template>

<script>
const dayjs = require('dayjs')
import firebase from 'firebase/app'
import 'firebase/auth'
import { mapGetters, mapMutations } from 'vuex'
import { paddle } from '@/mixins/paddle'
import { commonFunctions } from '@/mixins/commonFunctions'
import Mollie from '@/views/pages/plans/Mollie.vue'
import MolliePricingPlans from '@/views/pages/plans/MolliePricingPlans.vue'
import ConnectModal from '@/components/ConnectModal.vue'
import CloseCircleIcon from '@/components/icons/CloseCircleIcon.vue'

export default {
  mixins: [commonFunctions, paddle],
  components: {
    Mollie,
    MolliePricingPlans,
    ConnectModal,
    CloseCircleIcon
  },
  data: (state) => ({
    isOpenedFeaturesList: false,
    isOpenedBasicFeaturesList: false,
    isOpenedProFeaturesList: false,
    isOpenedEnterpriseFeaturesList: false,

    supportedPaymentMethods: ['card', 'paypal'],
    selectedPlan: null,
    showMollieComponent: false,
    subscriptionPlanType: 'annual',
    featuresList: [
      {
        title: state.$i18n.t('features.generalFeatures'),
        items: [
          {
            name: state.$i18n.t('features.includedUsers'),
            description: state.$i18n.t('features.forIndividualsAndTeams'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: '5',
            customTextBasic: '5',
            customTextPro: '5',
            customTextEnterprise: state.$i18n.t('pricing.biggerTeams')
          },
          {
            name: state.$i18n.t('features.liveContactWidget'),
            description: state.$i18n.t('features.easyChatPhoneAndVideoCommunicationWithCustomers'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.emailSupport'),
            description: state.$i18n.t('features.getEmailSupportFromContactNowExperts'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactFormToCollectLeads'),
            description: state.$i18n.t('features.contactFormToCollectLeadsDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.screensharing'),
            description: state.$i18n.t('features.screensharingDescription'),
            isFree: false,
            isBasic: true,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.aiPoweredPreQualification'),
            description: state.$i18n.t('features.aiPoweredPreQualificationDescription'),
            isFree: true,
            isBasic: true,
            isPro: true,
            isEnterprise: true,
            isCustomField: true,
            customTextFree: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextBasic: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextPro: state.$i18n.t('features.vegaCostPerVisitor'),
            customTextEnterprise: state.$i18n.t('features.vegaCostPerVisitor')
          }
        ]
      },
      {
        title: state.$i18n.t('features.proSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.fullStackCalendar'),
            description: state.$i18n.t('features.appointmentSchedulingAndEasyBooking'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.onlineMeeting'),
            description: state.$i18n.t('features.setUpMeetingsWithMoreParticipants'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.contactViaQrCode'),
            description: state.$i18n.t('features.contactViaQrCodeDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.unlimitedVisitorsIdentification'),
            description: state.$i18n.t('features.unlimitedVisitorsIdentificationDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.ABTesting'),
            description: state.$i18n.t('features.ABTestingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.customBranding'),
            description: state.$i18n.t('features.customBrandingDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.fullLiveSupport'),
            description: state.$i18n.t('features.fullLiveSupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: true,
            isEnterprise: true
          }
        ]
      },
      {
        title: state.$i18n.t('features.enterpriseSolutionsBundle'),
        items: [
          {
            name: state.$i18n.t('features.routing'),
            description: state.$i18n.t('features.routingDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.expertAnalytics'),
            description: state.$i18n.t('features.expertAnalyticsDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          },
          {
            name: state.$i18n.t('features.prioritySupport'),
            description: state.$i18n.t('features.prioritySupportDescription'),
            isFree: false,
            isBasic: false,
            isPro: false,
            isEnterprise: true
          }
        ]
      }
    ]
  }),
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      callCenterUserWorkTime: 'call_center_user_worktime'
    }),
    mollieRedirectUrl() {
      return `${location.origin}/processing-payment`
    },
    IS_CALLCENTER_STATE_STARTED() {
      return (
        this.callCenterUserWorkTime &&
        (this.callCenterUserWorkTime.currentWorkState === 'started' ||
          this.callCenterUserWorkTime.currentWorkState === 'paused' ||
          this.callCenterUserWorkTime.currentWorkState === 'resumed')
      )
    },
    isOpenedFeaturesListOnMobile() {
      return this.isOpenedBasicFeaturesList || this.isOpenedProFeaturesList || this.isOpenedEnterpriseFeaturesList
    }
  },
  mounted() {
    this.$serverBus.$on('toggle-features-list', ({ type, value }) => {
      if (type === 'All') {
        this.isOpenedFeaturesList = value
      } else {
        this[`isOpened${type}FeaturesList`] = value
      }
    })
    this.$serverBus.$on('return-to-all-plans', () => {
      this.returnToAllPlans()
    })
  },
  beforeDestroy() {
    this.$serverBus.$off('toggle-features-list')
    this.$serverBus.$off('return-to-all-plans')
  },
  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY',
      updateChosenPlan: 'UPDATE_CHOSEN_PLAN'
    }),
    async onMollieFormClose() {
      this.selectedPlan = null
      this.showMollieComponent = false
      window.location.reload(true)
    },
    async selectPlan(name) {
      const plans = this.getBaseSubscriptionPlans()
      const plan = plans.find((el) => el.id === name)
      this.updateChosenPlan(plan)
      await this.subscribeToPlan(plan)
    },
    async subscribeToPlan(plan) {
      this.selectedPlan = plan
      if (plan.isFree) {
        await this.subscribeToFreePlan(plan)
      } else {
        this.showMollieComponent = true
        this.$serverBus.$emit('setup-mollie-components', 'card')
      }
    },
    async subscribeToFreePlan(plan) {
      await this.$vs.loading()

      const mollieFreeSubscription = this.$functions.httpsCallable('mollieFreeSubscription')
      const res = await mollieFreeSubscription({
        planId: plan.ids.find((x) => x.indexOf('FREE') > -1)
      })

      if (res && res.data && res.data.error) {
        this.$vs.notify({
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t(`error.${res.data.message}`),
          color: 'danger'
        })
      }

      await this.$vs.loading.close()
    },
    returnToAllPlans() {
      this.isOpenedBasicFeaturesList = false
      this.isOpenedProFeaturesList = false
      this.isOpenedEnterpriseFeaturesList = false
    },
    async logout() {
      const vm = this
      vm.$vs.loading()

      if (vm.IS_CALLCENTER_STATE_STARTED) {
        const date = dayjs().format('YYYYMMDD')
        const workTimeDoc = await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).get()
        const workTime = workTimeDoc.data()
        if (workTime) {
          await this.$db
            .collection('users')
            .doc(this.activeUserInfo.uid)
            .collection('work-times')
            .doc(date)
            .set({ endedWorkAt: new Date(), currentWorkState: 'ended' }, { merge: true })
        }
      }
      /* Remove user from realtime db */
      const ref = firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
      await ref.remove()
      // if user is logged in via firebase
      const firebaseCurrentUser = firebase.auth().currentUser

      if (firebaseCurrentUser) {
        await firebase.auth().signOut()
      }
      const lsEndpoint = localStorage.getItem('pa-push-notification')
      const lsAudio = localStorage.getItem('pa-audio')
      const lsVideo = localStorage.getItem('pa-video')
      const lsSpeaker = localStorage.getItem('pa-speaker')
      const paShopifyState = localStorage.getItem('pa-shopify-state')

      localStorage.clear()
      if (lsEndpoint) {
        localStorage.setItem('pa-push-notification', lsEndpoint)
      }
      if (lsAudio) {
        localStorage.setItem('pa-audio', lsAudio)
      }
      if (lsVideo) {
        localStorage.setItem('pa-video', lsVideo)
      }
      if (lsSpeaker) {
        localStorage.setItem('pa-speaker', lsSpeaker)
      }
      if (paShopifyState) {
        localStorage.setItem('pa-shopify-state', paShopifyState)
      }

      // const dbs = await window.indexedDB.databases()
      // if (dbs) {
      //   dbs.forEach((db) => {
      //     if (db && db.name && db.name === 'firebaseLocalStorageDb') {
      //       window.indexedDB.deleteDatabase(db.name)
      //     }
      //   })
      // }

      vm.$store.commit('REMOVE_USER_INFO', {
        closeAnimation: this.$vs.loading.close
      })

      setTimeout(() => {
        this.updateCompany(null)
        window.location.href = '/pages/login'
      }, 200)

      setTimeout(() => {
        this.$store.commit('UPDATE_SUBSCRIPTION_CANCELLED', false)
      }, 300)
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-cancelled-sub {
  background: white;
  position: relative;
  min-height: 100vh;
  z-index: 51001;
  &__logout-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 30px;
    padding-right: 30px;
  }
  &-mollie-close-button {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-left: -1px;
    }
  }
  .pa-bk-secondary {
    background: rgba(var(--vs-secondary), 1) !important;
    border-radius: 10px;
    color: #fff !important;
  }
  &__title {
    font-weight: 700;
    font-size: 54px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #262629;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.09px;
    color: #868686;
    margin-bottom: 40px;
  }
}
</style>
