<template>
  <div class="the-navbar__user-meta flex items-center" v-if="displayName">
    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ displayName }}</p>
      <small> {{ $t('vue.available') }}</small>
    </div>

    <vs-dropdown vs-custom-content :disabled="audioVideoStarted" vs-trigger-click class="cursor-pointer">
      <div class="con-img ml-3">
        <Avatar size="40px" :photoURL="activeUserInfo.photoURL" :name="displayName" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 12rem">
          <li
            v-if="activeUserInfo.impersonated"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="loginBack(activeUserInfo.ipuid)"
          >
            <feather-icon icon="LogInIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ activeUserInfo.ipname }}</span>
          </li>
          <vs-divider class="m-1" v-if="activeUserInfo.impersonated" />
          <li
            v-if="activeUserInfo.company && activeUserInfo.role !== 'financial'"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push('/user-settings').catch(() => {})"
          >
            <feather-icon icon="SettingsIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('vue.settings') }}</span>
          </li>
          <li v-if="activeUserInfo.superadmin && version" class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="updateVersion">
            <span>
              <vs-chip class="ag-grid-cell-chip" color="#275D73">
                <span> <feather-icon icon="ShareIcon" svgClasses="w-4 h-4" /> {{ $t('vue.version') }} {{ version }} </span>
              </vs-chip>
            </span>
          </li>

          <template v-if="!isMobile">
            <li
              v-if="!activeUserInfo.role || activeUserInfo.role === 'admin' || activeUserInfo.impersonated"
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/company-setup').catch(() => {})"
            >
              <feather-icon icon="BoxIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">{{ $t('routes.companySetup') }}</span>
            </li>
            <vs-divider
              class="m-1"
              v-if="
                activeUserInfo &&
                !['financial', 'agent', 'supervisor', 'view-only'].includes(activeUserInfo.role) &&
                company &&
                !company.isShopifyAccount &&
                !company.isAppSumoAccount
              "
            />
            <li
              v-if="
                activeUserInfo &&
                !['financial', 'agent', 'supervisor', 'view-only'].includes(activeUserInfo.role) &&
                company &&
                !company.isShopifyAccount &&
                !company.isAppSumoAccount
              "
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/payment').catch(() => {})"
            >
              <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">{{ $t('vue.paymentSettings') }}</span>
            </li>
            <vs-divider
              class="m-1"
              v-if="activeUserInfo && !['financial', 'agent', 'supervisor'].includes(activeUserInfo.role) && company && company.isAppSumoAccount"
            />
            <li
              v-if="activeUserInfo && !['financial', 'agent', 'supervisor'].includes(activeUserInfo.role) && company && company.isAppSumoAccount"
              class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
              @click="$router.push('/appsumo-billing').catch(() => {})"
            >
              <feather-icon icon="CreditCardIcon" svgClasses="w-4 h-4" />
              <span class="ml-2">{{ $t('routes.appsumoMyPlan') }}</span>
            </li>
          </template>

          <vs-divider class="m-1" />

          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.prevent="changeLanguage('de')">
            <feather-icon icon="FlagIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('vue.deutsch') }}</span>
          </li>
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click.prevent="changeLanguage('en')">
            <feather-icon icon="FlagIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('vue.english') }}</span>
          </li>
          <vs-divider class="m-1" v-if="activeUserInfo.company" />
          <li class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white" @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{ $t('vue.logout') }}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
/* eslint-disable */
const dayjs = require('dayjs')
import firebase from 'firebase/app'
import 'firebase/auth'
import { Validator } from 'vee-validate'
// Merge all defined languages in the dictionary and sets the current locale.
import validationDictionary from '../../../../validations/validationDictionary'
import { mapGetters, mapMutations, mapActions } from 'vuex'

import { muser } from '@/mixins/muser'

import Avatar from '@/components/Avatar'

export default {
  components: {
    Avatar
  },

  mixins: [muser],

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapGetters({
      audioVideoStarted: 'webrtc/audioVideoStarted',
      activeUserInfo: 'activeUser',
      company: 'company',
      callCenterUserWorkTime: 'call_center_user_worktime'
    }),
    displayName() {
      if (this.activeUserInfo && this.activeUserInfo.displayName) {
        return this.activeUserInfo.displayName
      }
      if (this.activeUserInfo && this.activeUserInfo.firstname) {
        let displayName = this.activeUserInfo.firstname
        if (this.activeUserInfo.lastname) {
          displayName = `${displayName} ${this.activeUserInfo.lastname}`
        }

        return displayName
      }

      if (this.activeUserInfo && this.activeUserInfo.email) {
        return this.activeUserInfo.email
      }

      return null
    },

    version() {
      const version = parseFloat(this.$store.state.version).toFixed(2)
      return !isNaN(version) ? version : null
    },
    IS_CALLCENTER_STATE_STARTED() {
      return (
        this.callCenterUserWorkTime &&
        (this.callCenterUserWorkTime.currentWorkState === 'started' ||
          this.callCenterUserWorkTime.currentWorkState === 'paused' ||
          this.callCenterUserWorkTime.currentWorkState === 'resumed')
      )
    }
  },
  created() {
    const userInfo = this.activeUserInfo
    if (userInfo && userInfo.language) {
      this.$i18n.locale = userInfo.language
    }
  },
  methods: {
    ...mapMutations({
      updateCompany: 'UPDATE_COMPANY'
    }),
    ...mapActions({
      resetMainStore: 'resetMainStore',
      resetInvoiceStore: 'invoice/resetInvoiceStore',
      resetDashboardStore: 'dashboard/resetDashboardStore'
    }),
    async updateVersion() {
      const vm = this
      const version = (parseFloat(vm.version) + 0.01).toFixed(2)
      vm.$store.commit('UPDATE_VERSION', version)
      vm.$db.collection('settings').doc('app').set({ version, last_updated: new Date() }, { merge: true })
    },
    async loginBack(uid) {
      const vm = this
      vm.$vs.loading({ type: 'point' })
      try {
        const getUserCustomToken = vm.$functions.httpsCallable('getUserCustomToken')
        const result = await getUserCustomToken({ uid })
        if (result.data && result.data.status === true) {
          const token = result.data.token
          await firebase.auth().signInWithCustomToken(token)
          location.reload(true)
        } else {
          const message = vm.$i18n.t(`error.${result.data.messageCode}`)
          vm.$vs.notify({
            time: 4000,
            title: vm.$i18n.t('vue.error'),
            text: message,
            color: 'danger',
            iconPack: 'feather',
            position: 'bottom-right',
            icon: 'icon-alert-circle'
          })
          vm.$vs.loading.close()
        }
      } catch (error) {
        const message = error.message
        vm.$vs.notify({
          time: 4000,
          title: vm.$i18n.t('vue.error'),
          text: message,
          color: 'danger',
          iconPack: 'feather',
          position: 'bottom-right',
          icon: 'icon-alert-circle'
        })
        vm.$vs.loading.close()
      }
    },
    async changeLanguage(lang) {
      await this.$vs.loading()
      this.$i18n.locale = lang
      Validator.localize(this.$i18n.locale, validationDictionary[this.$i18n.locale])

      this.$serverBus.$emit('route-title-update', lang)
      await this.$db.collection('users').doc(this.activeUserInfo.uid).set({ language: lang }, { merge: true })
      this.$store.commit('UPDATE_USER_INFO', {
        language: lang,
        closeAnimation: this.$vs.loading.close
      })
    },
    async logout() {
      const vm = this
      await vm.$vs.loading()

      if (vm.IS_CALLCENTER_STATE_STARTED) {
        const date = dayjs().format('YYYYMMDD')
        const workTimeDoc = await this.$db.collection('users').doc(this.activeUserInfo.uid).collection('work-times').doc(date).get()
        const workTime = workTimeDoc.data()
        if (workTime) {
          await this.$db
            .collection('users')
            .doc(this.activeUserInfo.uid)
            .collection('work-times')
            .doc(date)
            .set({ endedWorkAt: new Date(), currentWorkState: 'ended' }, { merge: true })
        }
      }
      /* Remove user from realtime db */
      const ref = firebase.database().ref(`/users/${this.activeUserInfo.company}/${this.activeUserInfo.uid}`)
      ref.remove()
      await this.USER_LOGOUT()
    }
  }
}
</script>
