var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      attrs: {
        width: _vm.width,
        height: _vm.height,
        viewBox: "0 0 28 18",
        fill: "none",
        xmlns: "http://www.w3.org/2000/svg",
      },
    },
    [
      _c("path", {
        attrs: {
          d: "M26.1429 3.28613L18.1429 9.00042L26.1429 14.7147V3.28613Z",
          stroke: _vm.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
      _c("path", {
        attrs: {
          d: "M15.8571 1H3.28571C2.02335 1 1 2.02335 1 3.28571V14.7143C1 15.9767 2.02335 17 3.28571 17H15.8571C17.1195 17 18.1429 15.9767 18.1429 14.7143V3.28571C18.1429 2.02335 17.1195 1 15.8571 1Z",
          stroke: _vm.color,
          "stroke-width": "2",
          "stroke-linecap": "round",
          "stroke-linejoin": "round",
        },
      }),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }