<template>
  <div class="upgrade-banners">
    <div v-if="canShowTrialBanner && bannerText" class="upgrade-banner">
      <div class="upgrade-banner__text" v-html="bannerTextLocalized" />
      <div style="margin-left: auto">
        <vs-button class="upgrade-banner__button" color="warning" @click.prevent="goToPayment">
          {{ $t('pricing.upgradeNow') }}
        </vs-button>
      </div>
    </div>
    <!-- show banner elements in case free.ongoing || free.limitReached  -->
    <div v-else-if="bannerText" class="upgrade-banner">
      <div class="upgrade-banner__text" v-html="bannerTextLocalized" />
      <div style="margin-left: auto">
        <vs-button class="upgrade-banner__button" color="warning" @click.prevent="goToPayment">
          {{ $t('pricing.upgradeNow') }}
        </vs-button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const isSameOrAfter = require('dayjs/plugin/isSameOrAfter')

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(isSameOrAfter)

export default {
  name: 'UpgradeBanner',
  data() {
    return {
      bannerText: null,
      allowedPaymentStatus: ['trial', 'on-trial']
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      company: 'company'
    }),
    bannerTextLocalized() {
      if (this.bannerText && this.company && this.company.paymentStatus && this.company.planType !== 'free') {
        const { trial, expired } = this.bannerText
        const expiresInDays = `<span style="color: #f0a941;">${this.expiresIn}</span>`
        const locale = this.$i18n.locale

        if (this.allowedPaymentStatus.includes(this.company.paymentStatus) && !this.isExpired) {
          return trial.text[locale].replace(/(<([^>]+)>)/gi, expiresInDays)
        } else if (this.isExpired || this.company.paymentStatus === 'expired') {
          return expired.text[locale].replace(/(<([^>]+)>)/gi, this.trialPeriod || '')
        }
      }

      if (
        this.bannerText &&
        this.company &&
        (this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'deleted') &&
        this.company.planType === 'free'
      ) {
        const { free } = this.bannerText
        const locale = this.$i18n.locale

        if (this.freePlanIncomingRequestsLimitReached) {
          let maxIncomingRequestsForFreePlan = 20
          if (this.bannerText && this.bannerText.maxIncomingRequestsForFreePlan) {
            maxIncomingRequestsForFreePlan = this.bannerText.maxIncomingRequestsForFreePlan
          }
          return free.limitReached.text[locale].replace('__MAX_INCOMING_REQUESTS__', maxIncomingRequestsForFreePlan)
        } else {
          return free.ongoing.text[locale].replace('__REMAINING_INCOMING_REQUESTS__', this.remainingIncomingRequests)
        }
      }
      return ''
    },
    expiresAt() {
      if (this.company && this.company.expiryDate) {
        return dayjs.unix(this.company.expiryDate.seconds)
      }
      return null
    },
    expiresIn() {
      if (this.expiresAt) {
        return dayjs(this.expiresAt).tz(this.$defaultTimezone).diff(dayjs(), 'day')
      }
      return null
    },
    isExpired() {
      if (this.expiresAt) {
        return dayjs().tz(this.$defaultTimezone).isSameOrAfter(this.expiresAt, 'day')
      }
      return false
    },
    trialPeriod() {
      if (this.company && this.company.created && this.company.expiryDate) {
        return dayjs.unix(this.company.expiryDate.seconds).tz(this.$defaultTimezone).diff(dayjs.unix(this.company.created.seconds), 'day')
      }
      return null
    },
    canShowTrialBanner() {
      if (this.company && this.company.paymentStatus) {
        switch (this.company.paymentStatus) {
          case 'trial':
          case 'expired':
            return true

          case 'on-trial':
            return this.company && !this.company.currentInvoiceId && !this.company.mollieSubscriptionId
          default:
            return false
        }
      }
      return false
    },
    canShowFreePlanBanner() {
      if (this.company && this.company.paymentStatus && this.company.planType) {
        if ((this.company.paymentStatus === 'subscription' || this.company.paymentStatus === 'deleted') && this.company.planType === 'free') {
          return true
        }
      }
      return false
    },
    freePlanIncomingRequestsLimitReached() {
      let maxIncomingRequestsForFreePlan = 20
      if (this.bannerText && this.bannerText.maxIncomingRequestsForFreePlan) {
        maxIncomingRequestsForFreePlan = this.bannerText.maxIncomingRequestsForFreePlan
      }

      let totalMonthlyIncomingRequests = 0
      if (this.company) {
        totalMonthlyIncomingRequests = this.company.totalMonthlyIncomingRequests || 0
      }

      return maxIncomingRequestsForFreePlan - totalMonthlyIncomingRequests <= 0
    },
    remainingIncomingRequests() {
      let maxIncomingRequestsForFreePlan = 20
      if (this.bannerText && this.bannerText.maxIncomingRequestsForFreePlan) {
        maxIncomingRequestsForFreePlan = this.bannerText.maxIncomingRequestsForFreePlan
      }

      let totalMonthlyIncomingRequests = 0
      if (this.company) {
        totalMonthlyIncomingRequests = this.company.totalMonthlyIncomingRequests || 0
      }
      const remaining = maxIncomingRequestsForFreePlan - totalMonthlyIncomingRequests

      return remaining >= 0 ? remaining : 0
    }
  },
  methods: {
    goToPayment() {
      this.$router.push('/payment')
    },

    async getBannerSettings() {
      const query = await this.$db.collection('settings').doc('banner').get()
      const data = query.data()

      this.bannerText = data
    }
  },
  mounted() {
    this.getBannerSettings()
  }
}
</script>

<style lang="scss" scoped>
.upgrade-banners {
  position: relative;
  padding: 7px 25px 7px 40px;
  width: 100%;
  display: flex;
  background: #3b86f7;
  min-height: 44px;
  @media (max-width: 767px) {
    display: none;
  }
  @media (max-width: 576px) {
    padding: 7px 1.2rem 7px 1.2rem;
    margin-left: -1.2rem;
    width: calc(100% + 2.4rem);
  }
  .upgrade-banner {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
    &__text {
      font-size: 16px;
      color: #fff;
      font-weight: 500;
      line-height: 20px;
      max-width: 950px;
      margin-right: 12px;
    }
    &__button:not(.vs-radius):not(.includeIconOnly):not(.small):not(.large) {
      font-size: 16px;
      color: #fff;
      font-weight: 600;
      padding: 0 24px;
      min-height: 30px;
      width: max-content;
    }
  }
}
</style>
