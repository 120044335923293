<template>
  <div v-show="hasVegaTrialExpired && isPopupDisplayed" class="vte-overlay" :key="updateKey">
    <div class="vte-overlay-content">
      <div class="vte-overlay-content-main">
        <div class="vte-overlay-content-main--message">{{ $t('vega.expired.header') }}</div>
        <div class="vte-overlay-content-main--description">{{ $t('vega.expired.description') }}</div>

        <div class="vte-overlay-content-main-action--activate" @click="onActivateVega">
          {{ $t('vega.activateVega') }}
          <img
            src="@/assets/images/register-funnel/forward-arrow.png"
            width="18px"
            height="16.2px"
            alt="Next"
            class="vte-overlay-content-main-action--activate--icon"
          />
        </div>

        <div class="vte-overlay-content-main-action--deactivate" @click="onDeactivateVega">{{ $t('vega.deactivateVega') }}</div>
        <div class="vte-overlay-content-main--analyze">
          <img src="@/assets/images/register-funnel/vega-analyze.png" width="285" height="207" alt="Vega analyze" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
dayjs.extend(utc)

export default {
  name: 'VegaTrialExpired',
  data() {
    return {
      isPopupDisplayed: true,
      updateKey: Math.random().toString(36).substring(2, 15)
    }
  },
  computed: {
    ...mapGetters({
      company: 'company',
      activeUserInfo: 'activeUser'
    }),
    hasVegaTrialExpired() {
      if (this.company && this.company.vegaIsOnTrial && this.company.vegaExpiryDate) {
        return this.company.vegaExpiryDate && dayjs().utc().isAfter(dayjs.unix(this.company.vegaExpiryDate.seconds).utc())
      }
      return false
    },
    timeUntilExpiration() {
      if (!this.company || !this.company.vegaExpiryDate || !this.company.vegaIsOnTrial) return null

      if (this.company.vegaExpiryDate && dayjs().utc().isBefore(dayjs.unix(this.company.vegaExpiryDate.seconds).utc())) {
        return dayjs.unix(this.company.vegaExpiryDate.seconds).diff(dayjs(), 'seconds')
      }

      return null
    }
  },
  methods: {
    updateUI() {
      this.updateKey = Math.random().toString(36).substring(2, 15)
    },
    onActivateVega() {
      this.isPopupDisplayed = false
      this.$router.push({ name: 'payment', params: { action: 'activate-vega' } }).catch((error) => {
        if (error.name !== 'NavigationDuplicated') {
          throw error
        }
      })
    },
    onDeactivateVega() {
      this.isPopupDisplayed = false
      this.$db.collection('company').doc(this.activeUserInfo.company).set({ vegaIsActivated: false, vegaIsOnTrial: false }, { merge: true })
    }
  },
  watch: {
    timeUntilExpiration() {
      if (this.timeUntilExpiration > 0) {
        setTimeout(() => {
          this.updateUI()
        }, this.timeUntilExpiration + 2000)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.vte-overlay {
  position: fixed;
  z-index: 65000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
  overflow-x: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);

  &-content {
    position: relative;
    margin: 0px auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &-main {
      width: 710px;
      height: 424px;
      border-radius: 30px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
      transition: all 0.3s ease;
      padding: 50px 100px;
      position: relative;
      overflow: hidden;

      &--analyze {
        position: absolute;
        bottom: -42px;
        right: -96px;
      }

      display: flex;
      background-color: #fff;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      &--message {
        font-family: 'Larsseit-Bold';
        font-style: normal;
        font-weight: 700;
        font-size: 40px;
        line-height: 54px;
        text-align: center;
        letter-spacing: -0.3px;
        color: #262629;
      }

      &--description {
        margin-top: 40px;
        font-family: 'Larsseit-Regular';
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 130%;
        text-align: center;
        letter-spacing: -0.09px;
        color: #868686;
      }

      &-action {
        &--activate {
          margin-top: 68px;
          font-family: 'Larsseit-Medium';
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 18px;
          letter-spacing: -0.111111px;
          color: #ffffff;
          background: #3034f7;
          border-radius: 8px;
          padding: 20px 21px 22px 30px;
          cursor: pointer;
          &:hover {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.4);
          }
        }
        &--deactivate {
          margin-top: 20px;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 22px;
          letter-spacing: -0.0833333px;
          color: #878894;
          cursor: pointer;
        }
      }
    }
  }
}

/* Responsive design - make the overlay and its content responsive to the screen size */
@media only screen and (max-width: 768px) {
  .vte-overlay-content {
    width: 100%;
    height: 100%;
    border-radius: 0px;
    box-shadow: none;
    &-main {
      width: 100%;
      height: 100%;
      border-radius: 0px;
      box-shadow: none;
    }
  }
}

.modal-enter {
  opacity: 0;
}
.modal-leave-active {
  opacity: 0;
}
.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.2s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
