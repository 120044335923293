var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.displayHelp &&
    _vm.activeUserInfo &&
    _vm.activeUserInfo.role !== "financial"
    ? _c(
        "button",
        {
          staticClass: "quick-guide-button",
          on: {
            click: function ($event) {
              $event.preventDefault()
              return _vm.startQuickGuide.apply(null, arguments)
            },
          },
        },
        [
          _c("img", {
            attrs: {
              src: require("@/assets/images/quick-guide/question-mark.svg"),
              alt: "question-mark",
            },
          }),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }