var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.notifications.length > 0
    ? _c(
        "vs-dropdown",
        {
          staticClass: "cursor-pointer",
          attrs: { "vs-custom-content": "", "vs-trigger-click": "" },
        },
        [
          _c("feather-icon", {
            staticClass: "cursor-pointer mt-1 sm:mr-6 mr-2",
            attrs: { icon: "BellIcon", badge: _vm.notifications.length },
          }),
          _c(
            "vs-dropdown-menu",
            {
              staticClass:
                "notification-dropdown dropdown-custom vx-navbar-dropdown",
            },
            [
              _c(
                "div",
                {
                  staticClass:
                    "notification-top text-center p-5 bg-primary text-white",
                },
                [
                  _c("h3", { staticClass: "text-white" }, [
                    _vm._v(
                      _vm._s(_vm.notifications.length) +
                        " " +
                        _vm._s(_vm.$t("vue.new"))
                    ),
                  ]),
                  _c("p", { staticClass: "opacity-75" }, [
                    _vm._v(_vm._s(_vm.$t("vue.appNotifications"))),
                  ]),
                ]
              ),
              _c(
                _vm.scrollbarTag,
                {
                  key: _vm.$vs.rtl,
                  ref: "mainSidebarPs",
                  tag: "component",
                  staticClass:
                    "scroll-area--nofications-dropdown p-0 mb-12 p-1",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "ul",
                    { staticClass: "bordered-items mb-5" },
                    _vm._l(_vm.notifications, function (ntf) {
                      return _c(
                        "li",
                        {
                          key: ntf.index,
                          staticClass:
                            "flex justify-between px-4 py-4 notification cursor-pointer",
                        },
                        [
                          _c("div", { staticClass: "flex w-full" }, [
                            _c(
                              "div",
                              { staticClass: "w-full mx-2" },
                              [
                                _c("feather-icon", {
                                  staticStyle: { float: "left" },
                                  attrs: {
                                    icon: ntf.icon,
                                    svgClasses: [
                                      "text-" + ntf.category,
                                      "stroke-current mr-1 h-6 w-6",
                                    ],
                                  },
                                }),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "font-medium block notification-title",
                                    class: ["text-" + ntf.category],
                                  },
                                  [_vm._v(_vm._s(_vm.$t(ntf.title)))]
                                ),
                                _c("p", { staticClass: "mt-2" }, [
                                  _c("small", [_vm._v(_vm._s(ntf.msg))]),
                                ]),
                                _c("p", { staticClass: "mt-2 ml-2" }, [
                                  _c("i", [
                                    _c(
                                      "small",
                                      {
                                        staticClass: "mt-1 whitespace-no-wrap",
                                      },
                                      [
                                        _vm._v(
                                          _vm._s(_vm.elapsedTime(ntf.date))
                                        ),
                                      ]
                                    ),
                                  ]),
                                ]),
                              ],
                              1
                            ),
                            _c(
                              "div",
                              { staticClass: "flex flex-column" },
                              [
                                _c("vs-button", {
                                  attrs: {
                                    color: "primary",
                                    type: "flat",
                                    icon: "close",
                                  },
                                  on: {
                                    click: function ($event) {
                                      return _vm.remove(ntf)
                                    },
                                  },
                                }),
                                _c(
                                  "vs-button",
                                  {
                                    staticClass: "mt-5 ml-4",
                                    attrs: {
                                      color: "primary",
                                      "icon-pack": "feather",
                                      icon: "icon-external-link",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.goToIncomingRequests(ntf)
                                      },
                                    },
                                  },
                                  [_vm._v(_vm._s(_vm.$t("vue.join")))]
                                ),
                              ],
                              1
                            ),
                          ]),
                        ]
                      )
                    }),
                    0
                  ),
                ]
              ),
              _c(
                "div",
                {
                  staticClass:
                    "checkout-footer fixed bottom-0 rounded-b-lg text-primary w-full p-2 font-semibold text-center border border-b-0 border-l-0 border-r-0 border-solid d-theme-border-grey-light cursor-pointer",
                },
                [
                  _c(
                    "vs-button",
                    {
                      attrs: { type: "line" },
                      on: {
                        click: function ($event) {
                          return _vm.clear()
                        },
                      },
                    },
                    [_vm._v(_vm._s(_vm.$t("vue.clear")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }