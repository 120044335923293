var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "in-app-messaging__wrapper" }, [
    _c(
      "div",
      {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value:
              _vm.bannersCarouselSettings &&
              (_vm.canShowSwiper ||
                _vm.canShowCCBanner ||
                _vm.canShowTrialBanner ||
                _vm.canShowFreePlanBanner),
            expression:
              "bannersCarouselSettings && (canShowSwiper || canShowCCBanner || canShowTrialBanner || canShowFreePlanBanner)",
          },
        ],
        ref: "inAppMessagingCarousel",
        staticClass: "swiper",
        attrs: { options: _vm.bannersCarouselSettings },
      },
      [
        _c(
          "div",
          { staticClass: "swiper-wrapper" },
          [
            _vm.canShowCCBanner && _vm.activeUserInfo
              ? _c(
                  "div",
                  { key: "cc-banner", staticClass: "swiper-slide" },
                  [_c("call-center-worktime-banner")],
                  1
                )
              : _vm._e(),
            (_vm.canShowTrialBanner || _vm.canShowFreePlanBanner) &&
            !_vm.isMobileApp
              ? _c(
                  "div",
                  { key: "upgrade-banner", staticClass: "swiper-slide" },
                  [_c("upgrade-banner")],
                  1
                )
              : _vm._e(),
            _vm._l(_vm.banners, function (banner) {
              return [
                _vm.canShowBanner(banner)
                  ? _c(
                      "div",
                      { key: banner.id, staticClass: "swiper-slide" },
                      [
                        _c("in-app-messaging-banner", {
                          attrs: { banner: banner },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }