var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "connect-modal",
    {
      attrs: {
        show: _vm.showNotification,
        width: "450px",
        height: "200px",
        bkcolor: "#F7F7F7",
      },
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-row browser-push-notification-delete" },
        [
          _c("div", [
            _c("h3", [
              _vm._v(_vm._s(_vm.$t("vue.addBrowserPushNotification"))),
            ]),
          ]),
          _c(
            "div",
            {
              staticStyle: { "margin-left": "auto", cursor: "pointer" },
              attrs: { disabled: "" },
              on: {
                click: function ($event) {
                  _vm.showNotification = false
                },
              },
            },
            [
              _c("close-circle-icon", {
                staticClass: "pa-bk-secondary",
                attrs: { "show-circle": false, width: 14, height: 14 },
              }),
            ],
            1
          ),
        ]
      ),
      _c("div", { staticClass: "mt-8" }, [
        _vm._v(_vm._s(_vm.$t("vue.addBrowserPushNotificationMessage"))),
      ]),
      _c(
        "div",
        { staticClass: "mt-8 flex flex-row space-between justify-center" },
        [
          _c(
            "div",
            {
              staticClass:
                "browser-push-notification-delete-button delete-button mr-2",
              on: { click: _vm.addBrowserPushNotification },
            },
            [_vm._v(_vm._s(_vm.$t("vue.yes")))]
          ),
          _c(
            "div",
            {
              staticClass:
                "browser-push-notification-delete-button cancel-button ml-2",
              on: { click: _vm.hideNotificationMessage },
            },
            [_vm._v(_vm._s(_vm.$t("vue.no")))]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }