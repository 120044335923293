<template>
  <svg :width="width" :height="height" viewBox="0 0 28 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M26.1429 3.28613L18.1429 9.00042L26.1429 14.7147V3.28613Z" :stroke="color" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
    <path
      d="M15.8571 1H3.28571C2.02335 1 1 2.02335 1 3.28571V14.7143C1 15.9767 2.02335 17 3.28571 17H15.8571C17.1195 17 18.1429 15.9767 18.1429 14.7143V3.28571C18.1429 2.02335 17.1195 1 15.8571 1Z"
      :stroke="color"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'VideoCallIcon',
  props: {
    color: {
      type: String,
      default: '#fff'
    },
    width: {
      type: Number,
      default: 35
    },
    height: {
      type: Number,
      default: 35
    }
  }
}
</script>
