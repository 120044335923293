var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "upgrade-banners" }, [
    _vm.canShowTrialBanner && _vm.bannerText
      ? _c("div", { staticClass: "upgrade-banner" }, [
          _c("div", {
            staticClass: "upgrade-banner__text",
            domProps: { innerHTML: _vm._s(_vm.bannerTextLocalized) },
          }),
          _c(
            "div",
            { staticStyle: { "margin-left": "auto" } },
            [
              _c(
                "vs-button",
                {
                  staticClass: "upgrade-banner__button",
                  attrs: { color: "warning" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goToPayment.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pricing.upgradeNow")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm.bannerText
      ? _c("div", { staticClass: "upgrade-banner" }, [
          _c("div", {
            staticClass: "upgrade-banner__text",
            domProps: { innerHTML: _vm._s(_vm.bannerTextLocalized) },
          }),
          _c(
            "div",
            { staticStyle: { "margin-left": "auto" } },
            [
              _c(
                "vs-button",
                {
                  staticClass: "upgrade-banner__button",
                  attrs: { color: "warning" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.goToPayment.apply(null, arguments)
                    },
                  },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("pricing.upgradeNow")) +
                      "\n      "
                  ),
                ]
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }