<template>
  <div></div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      unsubscribeAgencySettings: null,
      agency_settings: [],
      isAgency: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },
  async created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      const companyRef = await this.$db
        .collection('company')
        .doc(this.activeUserInfo.company)
        .get()

      this.isAgency = !!companyRef.data().isAgency

      this.subscribeAgencySettings()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeAgencySettings) {
      this.unsubscribeAgencySettings()
    }
  },
  methods: {
    ...mapMutations({
      updateAgencySettings: 'UPDATE_AGENCY_SETTINGS'
    }),
    subscribeAgencySettings() {
      const vm = this

      if (vm.unsubscribeAgencySettings) {
        vm.unsubscribeAgencySettings()
      }

      const keyForAgencySettings = this.isAgency ? 'agencyId' : 'customerId'

      const query = vm.$db.collection('agency-settings').where(keyForAgencySettings, '==', vm.activeUserInfo.company)
      vm.unsubscribeAgencySettings = query.onSnapshot(async (ref) => {
        vm.agency_settings = []
        ref.docs.forEach((doc) => {
          const agency_setting = doc.data()
          vm.agency_settings.push({
            ...agency_setting,
            uidAgencySettings: doc.id
          })
        })
        this.updateAgencySettings(vm.agency_settings)
      })
    }
  }
}
</script>
