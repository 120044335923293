<!-- =========================================================================================
  File Name: TheNavbar.vue
  Description: Navbar component
  Component Name: TheNavbar
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="relative">
    <div
      class="vx-navbar-wrapper pa-vx-navbar-wrapper"
      :class="{
        classObj,
        _locked: lockedUI
      }"
    >
      <in-app-messaging-carousel />
      <vs-navbar class="vx-navbar navbar-custom navbar-skelton" :color="navbarColorLocal" :class="textColor">
        <!-- SM - OPEN SIDEBAR BUTTON -->
        <call-center-settings />
        <agency-settings />
        <push-notification />
        <feather-icon class="sm:inline-flex xl:hidden cursor-pointer p-2" icon="MenuIcon" @click.stop="showSidebar" />
        <notify-in-app-messaging />
        <notify-pathadvice-invoices v-if="activeUserInfo && activeUserInfo.company" />
        <notify-visitors />
        <notify-meetings />
        <notify-campaigns />
        <notify-leads />
        <online-users v-if="!isMobile" />
        <assignment-rules />
        <user-groups />
        <dialogs />

        <bookmarks :navbarColor="navbarColor" v-if="windowWidth >= 992" />

        <vs-spacer />
        <tour-restart class="mr-4" />
        <!-- <search-bar class="mr-4" /> -->
        <visitor-notifications class="mr-4" />
        <download-notifications class="mr-4" v-if="activeUserInfo && activeUserInfo.superadmin" />
        <profile-drop-down :isMobile="isMobile" />

        <widgets-assigned v-if="activeUserInfo && activeUserInfo.role === 'agent' && activeUserInfo.company"></widgets-assigned>
      </vs-navbar>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CallCenterSettings from '@/layouts/components/navbar/components/CallCenterSettings.vue'
import AgencySettings from '@/layouts/components/navbar/components/AgencySettings.vue'
import PushNotification from '@/layouts/components/navbar/components/PushNotification.vue'
import NotifyVisitors from '@/layouts/components/navbar/components/NotifyVisitors.vue'
import NotifyMeetings from '@/layouts/components/navbar/components/NotifyMeetings.vue'
import NotifyCampaigns from '@/layouts/components/navbar/components/NotifyCampaigns.vue'
import NotifyPathadviceInvoices from '@/layouts/components/navbar/components/NotifyPathadviceInvoices.vue'

import NotifyLeads from '@/layouts/components/navbar/components/NotifyLeads.vue'
import AssignmentRules from '@/layouts/components/navbar/components/AssignmentRules.vue'
import UserGroups from '@/layouts/components/navbar/components/UserGroups.vue'
import Dialogs from '@/layouts/components/navbar/components/Dialogs.vue'
import OnlineUsers from '@/layouts/components/navbar/components/OnlineUsers.vue'
import Bookmarks from '@/layouts/components/navbar/components/Bookmarks.vue'
// import SearchBar from '@/layouts/components/navbar/components/SearchBar.vue'
import TourRestart from '@/layouts/components/navbar/components/TourRestart.vue'
import VisitorNotifications from '@/layouts/components/navbar/components/VisitorNotifications.vue'
import DownloadNotifications from '@/layouts/components/navbar/components/DownloadNotifications.vue'
import ProfileDropDown from '@/layouts/components/navbar/components/ProfileDropDown.vue'
import NotifyInAppMessaging from '@/layouts/components/navbar/components/NotifyInAppMessaging'
import WidgetsAssigned from '@/layouts/components/navbar/components/WidgetsAssigned.vue'
import InAppMessagingCarousel from '@/components/banners/InAppMessagingCarousel'

export default {
  name: 'the-navbar-vertical',
  props: {
    navbarColor: {
      type: String,
      default: '#fff'
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  },
  components: {
    AgencySettings,
    CallCenterSettings,
    PushNotification,
    Bookmarks,
    // SearchBar,
    VisitorNotifications,
    ProfileDropDown,
    OnlineUsers,
    TourRestart,
    NotifyInAppMessaging,
    InAppMessagingCarousel,
    NotifyVisitors,
    NotifyMeetings,
    NotifyCampaigns,
    NotifyPathadviceInvoices,
    NotifyLeads,
    AssignmentRules,
    UserGroups,
    Dialogs,
    DownloadNotifications,
    WidgetsAssigned
  },
  computed: {
    ...mapGetters({
      lockedUI: 'lockedUI',
      activeUserInfo: 'activeUser'
    }),
    navbarColorLocal() {
      return this.$store.state.theme === 'dark' && this.navbarColor === '#fff' ? '#10163a' : this.navbarColor
    },
    verticalNavMenuWidth() {
      return this.$store.state.verticalNavMenuWidth
    },
    textColor() {
      return {
        'text-white':
          (this.navbarColor !== '#10163a' && this.$store.state.theme === 'dark') || (this.navbarColor !== '#fff' && this.$store.state.theme !== 'dark')
      }
    },
    windowWidth() {
      return this.$store.state.windowWidth
    },

    // NAVBAR STYLE
    classObj() {
      if (this.verticalNavMenuWidth === 'default') return 'navbar-default'
      else if (this.verticalNavMenuWidth === 'reduced') return 'navbar-reduced'
      else if (this.verticalNavMenuWidth) return 'navbar-full'
      else return 'navbar-default'
    }
  },
  methods: {
    showSidebar() {
      this.$store.commit('TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE', true)
    }
  }
}
</script>

<style lang="scss">
// .pa-vx-navbar-wrapper {
//   @media only screen and (max-width: 1024px) {
//     padding: 1.2rem 20px !important;
//   }
// }
//
</style>
