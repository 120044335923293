<template>
  <div></div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default {
  data() {
    return {
      unsubscribePathadviceInvoices: null,
      unsubscribePathadvicePayments: null
    }
  },
  watch: {
    dateRange() {
      if (this.activeUserInfo && this.activeUserInfo.company) {
        this.resetInvoices()
        this.subscribePathadviceInvoices()
        this.subscribePathadvicePayments()
      }
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      pathadviceInvoices: 'invoice/pathadviceInvoices',
      dateRange: 'invoice/dateRange'
    }),
    ranges() {
      const today = new Date()
      today.setHours(0, 0, 0, 0)
      const todayNight = new Date()
      todayNight.setDate(today.getDate())
      todayNight.setHours(23, 59, 59, 999)

      const yesterday = new Date()
      yesterday.setDate(today.getDate() - 1)
      yesterday.setHours(0, 0, 0, 0)
      const yesterdayNight = new Date()
      yesterdayNight.setDate(today.getDate() - 1)
      yesterdayNight.setHours(23, 59, 59, 999)

      const lastWeek = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 7)

      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1)
      const thisMonthEnd = new Date(today.getFullYear(), today.getMonth() + 1, 0)
      thisMonthEnd.setHours(23, 59, 59, 999)

      return {
        Today: [today, todayNight],
        Yesterday: [yesterday, yesterdayNight],
        'Last week': [lastWeek, today],
        'This month': [thisMonthStart, thisMonthEnd],
        'This year': [new Date(today.getFullYear(), 0, 1), new Date(today.getFullYear(), 11, 31)],
        'Last month': [new Date(today.getFullYear(), today.getMonth() - 1, 1), new Date(today.getFullYear(), today.getMonth(), 0)],
        'Last year': [new Date(today.getFullYear() - 1, 0, 1), new Date(today.getFullYear() - 1, 11, 31)]
      }
    },
    startOfDay() {
      return dayjs(this.dateRange.startDate).startOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')
    },
    endOfDay() {
      return dayjs(this.dateRange.endDate).endOf('day').utc().format('YYYY-MM-DD HH:mm:ss UTC')
    }
  },
  created() {
    this.updateDateRange({
      endDate: dayjs(this.ranges['Today'][1]).utc().toDate(),
      startDate: dayjs(this.ranges['Last month'][0]).utc().toDate()
    })
  },
  beforeDestroy() {
    if (this.unsubscribePathadviceInvoices) {
      this.unsubscribePathadviceInvoices()
    }
    if (this.unsubscribePathadvicePayments) {
      this.unsubscribePathadvicePayments()
    }
  },
  methods: {
    ...mapActions({
      updateDateRange: 'invoice/updateDateRange',
      addInvoice: 'invoice/addInvoice',
      updateInvoice: 'invoice/updateInvoice',
      resetInvoices: 'invoice/resetInvoices'
    }),
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribePathadviceInvoices() {
      const vm = this

      if (!vm.activeUserInfo) {
        return
      }
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(vm.activeUserInfo.role)) {
        return
      }

      if (vm.unsubscribePathadviceInvoices) {
        vm.unsubscribePathadviceInvoices()
      }
      let query = vm.$db.collection('pathadvice-invoices')
      if (vm.activeUserInfo.superadmin) {
        query = query
          .where('createdAt', '>=', vm.dateRange.startDate)
          .where('createdAt', '<=', vm.dateRange.endDate)
          .where('valueGreaterThanZero', '==', true)
          .orderBy('createdAt', 'desc')
          .limit(500)
      } else {
        query = query.where('companyId', '==', vm.activeUserInfo.company).orderBy('createdAt', 'desc').limit(500)
      }

      vm.unsubscribePathadviceInvoices = query.onSnapshot(async (ref) => {
        ref.docs.forEach((doc) => {
          const invoice = doc.data()
          invoice.id = doc.id
          invoice.method = 'invoice'
          if (invoice.previewUrl && invoice.previewUrl.length > 0) {
            invoice.generatingInvoice = false
          }
          const index = vm.pathadviceInvoices.findIndex((x) => x.id === invoice.id)
          if (index === -1) {
            vm.addInvoice(invoice)
          } else {
            vm.updateInvoice(invoice)
          }
        })
      })
    },
    subscribePathadvicePayments() {
      const vm = this
      if (vm.unsubscribePathadvicePayments) {
        vm.unsubscribePathadvicePayments()
      }

      let query = vm.$db.collection('mollie-payments')
      if (vm.activeUserInfo.superadmin) {
        query = query
          .where('createdAtTS', '>=', vm.dateRange.startDate)
          .where('createdAtTS', '<=', vm.dateRange.endDate)
          .where('valueGreaterThanZero', '==', true)
          .orderBy('createdAtTS', 'desc')
          .limit(500)
      } else {
        query = query.where('companyId', '==', vm.activeUserInfo.company).orderBy('createdAtTS', 'desc').limit(500)
      }

      vm.unsubscribePathadvicePayments = query.onSnapshot(async (ref) => {
        ref.docs.forEach((doc) => {
          const payment = doc.data()
          const invoice = payment.transaction
          invoice.id = doc.id
          invoice.companyId = payment.companyId
          invoice.createdAt = payment.createdAtTS
          invoice.createdAtTS = payment.createdAtTS
          invoice.method = payment.transaction.method
          invoice.selectedPlanId = invoice.metadata && invoice.metadata.planId ? invoice.metadata.planId : ''
          invoice.company = invoice.companyInfo && invoice.companyInfo.name ? invoice.companyInfo.name : ''
          if (invoice.previewUrl && invoice.previewUrl.length > 0) {
            invoice.generatingInvoice = false
          }
          const index = vm.pathadviceInvoices.findIndex((x) => x.id === invoice.id)
          if (index === -1) {
            vm.addInvoice(invoice)
          } else {
            vm.updateInvoice(invoice)
          }
        })
      })
    }
  }
}
</script>
