var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "t-container" }, [
    _c("div", { staticClass: "t-container-header fc-white heading" }, [
      _c("div", [_vm._v(_vm._s(_vm.visitorRequestText))]),
      _vm.requestType === "chat"
        ? _c("div", [_c("chat-icon", { attrs: { width: 20, height: 20 } })], 1)
        : _vm._e(),
      _vm.requestType === "audio"
        ? _c(
            "div",
            [_c("microphone-icon", { attrs: { width: 20, height: 20 } })],
            1
          )
        : _vm._e(),
      _vm.requestType === "video"
        ? _c(
            "div",
            [_c("video-call-icon", { attrs: { width: 20, height: 20 } })],
            1
          )
        : _vm._e(),
    ]),
    _c("div", { staticClass: "t-container-place fc-white mt-2 mb-2" }, [
      _vm._v(_vm._s(_vm.visitor)),
    ]),
    _c("div", { staticClass: "t-container-action mt-2 mb-2" }, [
      _c(
        "button",
        {
          staticClass: "t-container-action--cancel",
          on: { click: _vm.declineCall },
        },
        [_vm._v(_vm._s(_vm.declineButtonText))]
      ),
      _c(
        "button",
        {
          ref: "acceptCallButton",
          staticClass: "t-container-action--accept",
          attrs: { disabled: _vm.isButtonDisabled },
          on: { click: _vm.acceptCall },
        },
        [_vm._v(_vm._s(_vm.acceptButtonText))]
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }