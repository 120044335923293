const plans = [
  {
    id: 'APPSUMO_BASIC',
    appsumo_planid: 'letsconnect_tier1',
    name: 'Basic',
    description: 'License Tier 1',
    title: 'License Tier 1',
    category: 'basic',
    users: 3,
    price: '69.00',
    enabled: true,
    level: 1,
    options: [
      {
        description: 'appsumo.features.unlimitedWidgets'
      },
      {
        description: 'appsumo.features.unlimitedInstalls'
      },
      {
        description: 'appsumo.features.unlimitedCommunication'
      }
    ]
  },
  {
    id: 'APPSUMO_PROFESSIONAL',
    appsumo_planid: 'letsconnect_tier2',
    name: 'Professional',
    category: 'professional',
    description: 'License Tier 2',
    title: 'License Tier 2',
    users: 10,
    price: '139.00',
    enabled: true,
    level: 10,
    options: [
      {
        description: 'appsumo.features.unlimitedWidgets'
      },
      {
        description: 'appsumo.features.unlimitedInstalls'
      },
      {
        description: 'appsumo.features.unlimitedCommunication'
      },
      {
        description: 'appsumo.features.routing'
      },
      {
        description: 'appsumo.features.expertAnalytics'
      }
    ]
  },
  {
    id: 'APPSUMO_ENTERPRISE',
    appsumo_planid: 'letsconnect_tier3',
    name: 'Enterprise',
    category: 'enterprise',
    description: 'License Tier 3',
    title: 'License Tier 3',
    price: '209.00',
    users: 50,
    enabled: true,
    level: 20,
    options: [
      {
        description: 'appsumo.features.unlimitedWidgets'
      },
      {
        description: 'appsumo.features.unlimitedInstalls'
      },
      {
        description: 'appsumo.features.unlimitedCommunication'
      },
      {
        description: 'appsumo.features.routing'
      },
      {
        description: 'appsumo.features.expertAnalytics'
      }
    ]
  }
]

exports.plans = plans
