<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      unsubscribeCampaigns: null,
      campaigns: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    const vm = this
    if (vm.activeUserInfo && vm.activeUserInfo.company) {
      vm.subscribeCampaigns()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeCampaigns) {
      this.unsubscribeCampaigns()
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeCampaigns() {
      const vm = this
      if (!vm.activeUserInfo) {
        return
      }
      const disallowedRoles = ['view-only']
      if (disallowedRoles.includes(vm.activeUserInfo.role)) {
        return
      }

      if (vm.unsubscribeCampaigns) {
        vm.unsubscribeCampaigns()
      }
      const query = vm.$db.collection('campaigns').where('company', '==', vm.activeUserInfo.company).orderBy('created', 'desc').limit(100)
      vm.unsubscribeCampaigns = query.onSnapshot(async (ref) => {
        vm.campaigns = []
        ref.docs.forEach((doc) => {
          const campaign = doc.data()
          campaign.id = doc.id
          vm.campaigns.push(campaign)
        })
        vm.$store.commit('UPDATE_CAMPAIGNS', vm.campaigns)
      })
    }
  }
}
</script>
