<template>
  <div class="flex user-online" v-if="activeUserInfo && activeUserInfo.company" :class="{ 'user-online--mobile': isMobile }">
    <div v-for="(user, index) in previewListOfOnlineUsers" :key="index" class="user-online-avatar-wrapper">
      <vx-tooltip class="user-online-avatar" :text="user.displayName" position="bottom">
        <template>
          <Avatar :size="isMobile ? '44px' : '40px'" :photoURL="getUserImage(user.userId)" :name="user.displayName" />

          <div
            class="user-online-status"
            :class="{
              _online: user.state === 'online',
              _offline: user.state === 'offline'
            }"
          >
            {{ user.state }}
          </div>
        </template>
      </vx-tooltip>
    </div>

    <div v-if="isMobile && onlineUsers.length > 4" class="user-online-button-more" @click="isOpenedOnlineAgentsOverview = true">
      <feather-icon icon="PlusIcon" svgClasses="stroke-current w-5 h-5" @click="isOpenedOnlineAgentsOverview = true" />
    </div>

    <div v-if="isOpenedOnlineAgentsOverview" class="user-online-overview">
      <div class="user-online-overview__header">
        <button class="user-online-overview__header__button-back" @click.prevent="isOpenedOnlineAgentsOverview = false">
          <arrow-icon :color="'#275D73'" :direction="'left'" :width="18" :height="28" @click="isOpenedOnlineAgentsOverview = false" />
        </button>

        <div class="user-online-overview__header__title">{{ $t('vue.liveAgents') }}</div>
      </div>

      <div class="user-online-overview__list">
        <div v-for="(user, index) in onlineUsers" :key="index" class="user-online-avatar-wrapper">
          <vx-tooltip class="user-online-avatar" :text="user.displayName" position="bottom">
            <template>
              <Avatar :size="isMobile ? '44px' : '40px'" :photoURL="getUserImage(user.userId)" :name="user.displayName" />

              <div
                class="user-online-status"
                :class="{
                  _online: user.state === 'online',
                  _offline: user.state === 'offline'
                }"
              >
                {{ user.state }}
              </div>
            </template>
          </vx-tooltip>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import Avatar from '@/components/Avatar'
import ArrowIcon from '@/components/icons/ArrowIcon'

export default {
  components: {
    Avatar,
    ArrowIcon
  },

  props: {
    isMobile: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      onlineUsers: [],
      onlineUserStatusRef: null,
      isOpenedOnlineAgentsOverview: false
    }
  },
  computed: {
    ...mapGetters({
      users: 'users'
    }),
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    },
    previewListOfOnlineUsers() {
      return this.isMobile ? this.onlineUsers.slice(0, 4) : this.onlineUsers
    }
  },
  created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      this.onlineUserStatusRef = this.$firebase.database().ref(`/users/${this.activeUserInfo.company}`)
    }
  },
  async mounted() {
    const vm = this

    if (vm.onlineUserStatusRef && this.activeUserInfo && this.activeUserInfo.company) {
      /* Query realtime db for users online status */
      vm.onlineUserStatusRef.on('value', async (snapshot) => {
        const data = snapshot.val()
        const now = this.$firebase.firestore.Timestamp.now().seconds * 1000
        if (data) {
          for (const key of Object.keys(data)) {
            const entry = data[key]
            const user = {
              userId: key,
              displayName: entry.displayName ? entry.displayName : `${entry.firstname} ${entry.lastname}`,
              state: entry.state,
              time: (now - entry.last_changed) / 1000
            }

            if (user && user.userId && user.userId !== this.activeUserInfo.uid) {
              const index = vm.onlineUsers.findIndex((x) => x.userId === user.userId)

              /* Add or update user in onlineUsers list if the user is online */
              if (user.state === 'online' && user.time < 300) {
                if (index === -1) {
                  /* Add user to the onlineUsers List */
                  vm.onlineUsers.push(user)
                  const indexUser = vm.onlineUsers.findIndex((x) => x.userId === user.userId)
                  const userRef = await vm.$db.collection('users').doc(user.userId).get()
                  const userData = userRef.data()
                  if (userData) {
                    user.photoURL = userData.photoURL
                    user.displayName = userData.displayName || `${userData.firstname} ${userData.lastname}`
                  }
                  vm.$set(vm.onlineUsers, indexUser, user)
                } else {
                  // const photoURL = vm.onlineUsers[]
                  vm.$set(vm.onlineUsers, index, user)
                }
              }

              /* Remove from onlineUsers list if user is offline */
              if (user.state === 'offline' || user.time > 300) {
                if (vm.onlineUsers.length > 0) {
                  if (index > -1) {
                    vm.onlineUsers.splice(index, 1)
                  }
                }
              }
            }
          }
        }
      })

      /* Remove user from the list when user logs out */
      vm.onlineUserStatusRef.on('child_removed', function (snapshot) {
        if (vm.onlineUsers.length > 0) {
          const index = vm.onlineUsers.findIndex((x) => x.userId === snapshot.key)
          if (index > -1) {
            vm.onlineUsers.splice(index, 1)
          }
        }
        /* Detach listner of the user */
        if (vm.activeUserInfo && vm.activeUserInfo.company) {
          const userListener = vm.$firebase.database().ref(`/users/${vm.activeUserInfo.company}/${snapshot.key}`)
          if (userListener && userListener.off) {
            userListener.off()
          }
        }
      })
    }
  },
  beforeDestroy() {
    if (this.onlineUserStatusRef && this.onlineUserStatusRef.off) {
      this.onlineUserStatusRef.off()
    }
  },
  methods: {
    getUserImage(agentId) {
      return this.users.filter((x) => x.id === agentId).length > 0 ? this.users.filter((x) => x.id === agentId)[0].image : null
    }
  }
}
</script>
<style lang="scss">
.user-online {
  &-avatar {
    display: flex;
    position: relative;
  }
  &-status {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    overflow: hidden;
    border: 1px solid rgb(195, 195, 195);
    position: absolute;
    bottom: 5%;
    left: 3%;
    &._online {
      background: #1c8439;
    }
    &._offline {
      background: rgb(255, 71, 87);
    }
  }

  &-button-more {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 3px;
    width: 44px;
    height: 44px;
    border-radius: 50%;
    background: #ebf5fe;
    cursor: pointer;

    .feather-icon {
      margin: 0px !important;
      color: #275D73 !important;
    }

    &:hover {
      color: rgba(40, 167, 249, 0.8) !important;
    }
  }

  &-overview {
    position: fixed;
    top: 0;
    left: 0;
    padding: 30px;
    width: 100%;
    height: 100%;
    background: rgb(224, 238, 250);
    z-index: 9999;

    &__header {
      display: flex;
      align-items: center;
      margin-bottom: 45px;

      &__button-back {
        display: flex;
        background: none;
        border: none;
      }

      &__title {
        margin-left: 20px;
        color: rgb(38, 38, 40);
        font-size: 15px;
        font-weight: 700;
        line-height: 18px;
      }
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin-left: -6px;

      .user-online-avatar {
        &-wrapper {
          margin-top: 8px;
          margin-left: 6px !important;

          &:first-of-type {
            margin-left: 6px !important;
          }
        }
      }
    }
  }

  &--mobile {
    margin: 10px 0;
    padding: 0 18px 0 24px;

    .user-online-avatar {
      &-wrapper {
        margin-left: 3px;

        &:first-of-type {
          margin-left: 0px;
        }
      }
    }
  }
}
</style>
