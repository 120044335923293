var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "ui-cancelled-sub" },
    [
      _c(
        "div",
        { staticClass: "ui-cancelled-sub__logout-wrapper" },
        [
          _c(
            "vs-button",
            {
              attrs: { color: "primary", type: "filled" },
              on: { click: _vm.USER_LOGOUT },
            },
            [_vm._v(" " + _vm._s(_vm.$t("vue.logout")))]
          ),
        ],
        1
      ),
      _c("div", { staticClass: "flex flex-col items-center w-full" }, [
        _c("div", { staticClass: "ui-cancelled-sub__title" }, [
          _vm._v(_vm._s(_vm.$t("appsumo.appSumoAccountRefunded"))),
        ]),
        _c("div", { staticClass: "ui-cancelled-sub__subtitle" }, [
          _vm._v(_vm._s(_vm.$t("vue.pleaseChoosePlansToProceed"))),
        ]),
      ]),
      _c("AppSumoPlan"),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }