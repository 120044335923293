<template>
  <div></div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
export default {
  data() {
    return {
      unsubscribeCallCenterSettings: null,
      call_center_settings: [],
      isCallCenter: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser'
    })
  },
  async created() {
    if (this.activeUserInfo && this.activeUserInfo.company) {
      const companyRef = await this.$db
        .collection('company')
        .doc(this.activeUserInfo.company)
        .get()

      this.isCallCenter = !!companyRef.data().isCallCenter

      this.subscribeCallCenterSettings()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeCallCenterSettings) {
      this.unsubscribeCallCenterSettings()
    }
  },
  methods: {
    ...mapMutations({
      updateCallCenterSettings: 'UPDATE_CALL_CENTER_SETTINGS'
    }),
    subscribeCallCenterSettings() {
      const vm = this

      if (vm.unsubscribeCallCenterSettings) {
        vm.unsubscribeCallCenterSettings()
      }

      const keyForCallCenterSettings = this.isCallCenter ? 'callCenterId' : 'customerId'

      const query = vm.$db.collection('call-center-settings').where(keyForCallCenterSettings, '==', vm.activeUserInfo.company)
      vm.unsubscribeCallCenterSettings = query.onSnapshot(async (ref) => {
        vm.call_center_settings = []
        ref.docs.forEach((doc) => {
          const call_center_setting = doc.data()
          vm.call_center_settings.push({
            ...call_center_setting,
            uidCallCenterSettings: doc.id
          })
        })
        this.updateCallCenterSettings(vm.call_center_settings)
      })
    }
  }
}
</script>
