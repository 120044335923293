<template>
  <div></div>
</template>
<script>
export default {
  data() {
    return {
      unsubscribeDialogs: null,
      dialogs: []
    }
  },
  computed: {
    activeUserInfo() {
      return this.$store.state.AppActiveUser
    }
  },
  created() {
    const vm = this
    if (vm.activeUserInfo && vm.activeUserInfo.company) {
      vm.subscribeDialogs()
    }
  },
  beforeDestroy() {
    if (this.unsubscribeDialogs) {
      this.unsubscribeDialogs()
    }
  },
  methods: {
    translate(code) {
      return this.$i18n.t(code)
    },
    subscribeDialogs() {
      const vm = this
      if (vm.unsubscribeDialogs) {
        vm.unsubscribeDialogs()
      }

      let query = vm.$db.collection('dialogs')

      if (this.activeUserInfo.allowedCompanies && this.activeUserInfo.allowedCompanies.length) {
        query = query.where('company', 'in', this.activeUserInfo.allowedCompanies).limit(100)
      } else {
        query = query.where('company', '==', vm.activeUserInfo.company).limit(100)
      }

      vm.unsubscribeDialogs = query.onSnapshot(async (ref) => {
        vm.dialogs = []
        ref.docs.forEach((doc) => {
          const dialog = doc.data()
          dialog.id = doc.id
          if (dialog.visibility === 'permission') {
            dialog.isEnabled = false
          } else {
            dialog.isEnabled = true
          }
          vm.dialogs.push(dialog)
        })
        vm.$store.commit('UPDATE_DIALOGS', vm.dialogs)
      })
    }
  }
}
</script>
