var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return !_vm.subscriptionCancelled
    ? _c(
        "div",
        { staticClass: "parentx" },
        [
          _c(
            "vs-sidebar",
            {
              directives: [
                {
                  name: "hammer",
                  rawName: "v-hammer:swipe",
                  value: _vm.onMenuSwipe,
                  expression: "onMenuSwipe",
                  arg: "swipe",
                },
              ],
              ref: "verticalNavMenu",
              staticClass: "v-nav-menu items-no-padding",
              attrs: {
                "default-index": "-1",
                "click-not-close": _vm.clickNotClose,
                "reduce-not-rebound": _vm.reduceNotRebound,
                parent: _vm.parent,
                hiddenBackground: _vm.clickNotClose,
                reduce: _vm.reduce,
              },
              model: {
                value: _vm.isVerticalNavMenuActive,
                callback: function ($$v) {
                  _vm.isVerticalNavMenuActive = $$v
                },
                expression: "isVerticalNavMenuActive",
              },
            },
            [
              _c(
                "div",
                {
                  class: {
                    _locked: _vm.lockedUI,
                  },
                  on: {
                    mouseenter: _vm.mouseEnter,
                    mouseleave: _vm.mouseLeave,
                  },
                },
                [
                  _c(
                    "div",
                    {
                      staticClass:
                        "header-sidebar flex items-end justify-between",
                      attrs: { slot: "header" },
                      slot: "header",
                    },
                    [
                      _c("router-link", {
                        staticClass: "vx-logo cursor-pointer flex items-center",
                        attrs: { to: "/", custom: "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function (ref) {
                                var navigate = ref.navigate
                                var href = ref.href
                                return [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "cursor-pointer flex items-center",
                                      attrs: { href: href },
                                      on: { click: navigate },
                                    },
                                    [
                                      _vm.title
                                        ? [
                                            _c("img", {
                                              staticClass:
                                                "mr-1 fill-current vega-logo",
                                              attrs: {
                                                src: require("@/assets/images/vega-logo/vega_logo_black.png"),
                                              },
                                            }),
                                          ]
                                        : [
                                            _c("img", {
                                              staticClass:
                                                "mr-1 fill-current vega-logo",
                                              attrs: {
                                                src: require("@/assets/images/vega-logo/vega_logo_black.png"),
                                              },
                                            }),
                                          ],
                                    ],
                                    2
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          1495271989
                        ),
                      }),
                      false
                        ? _c(
                            "div",
                            [
                              _vm.showCloseButton
                                ? [
                                    _c("feather-icon", {
                                      staticClass: "m-0 cursor-pointer",
                                      attrs: { icon: "XIcon" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.$store.commit(
                                            "TOGGLE_IS_VERTICAL_NAV_MENU_ACTIVE",
                                            false
                                          )
                                        },
                                      },
                                    }),
                                  ]
                                : !_vm.showCloseButton &&
                                  !_vm.verticalNavMenuItemsMin
                                ? [
                                    _c("feather-icon", {
                                      staticClass: "mr-0 cursor-pointer",
                                      attrs: {
                                        id: "btnVNavMenuMinToggler",
                                        icon: _vm.reduce
                                          ? "CircleIcon"
                                          : "DiscIcon",
                                        "svg-classes":
                                          "stroke-current text-primary",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.toggleReduce(!_vm.reduce)
                                        },
                                      },
                                    }),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("div", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.showShadowBottom,
                        expression: "showShadowBottom",
                      },
                    ],
                    staticClass: "shadow-bottom",
                  }),
                  _vm.isMobile
                    ? _c("online-users", { attrs: { isMobile: _vm.isMobile } })
                    : _vm._e(),
                  _c(
                    _vm.scrollbarTag,
                    {
                      key: _vm.$vs.rtl,
                      ref: "verticalNavMenuPs",
                      tag: "component",
                      staticClass: "scroll-area-v-nav-menu pt-2",
                      attrs: { settings: _vm.settings },
                      on: {
                        "ps-scroll-y": _vm.psSectionScroll,
                        scroll: _vm.psSectionScroll,
                      },
                    },
                    [
                      _vm._l(
                        _vm.filteredDashboardNavMenuItemsLocal,
                        function (item, index) {
                          return [
                            item.header && !_vm.verticalNavMenuItemsMin
                              ? _c(
                                  "span",
                                  {
                                    key: "header-" + index + "-" + item.slug,
                                    staticClass: "navigation-header truncate",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.header) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : !item.header &&
                                (item.disallowedRoles
                                  ? !item.disallowedRoles.find(function (x) {
                                      return x === _vm.activeUserInfo.role
                                    })
                                  : true)
                              ? [
                                  !item.submenu
                                    ? _c(
                                        "v-nav-menu-item",
                                        {
                                          key:
                                            "item-" + index + "-" + item.slug,
                                          attrs: {
                                            featherIcon: item.featherIcon,
                                            index: index,
                                            to:
                                              item.slug !== "external"
                                                ? item.url
                                                : null,
                                            href:
                                              item.slug === "external"
                                                ? item.url
                                                : null,
                                            icon: item.icon,
                                            iconPack: item.iconPack,
                                            target: item.target,
                                            isDisabled:
                                              _vm.disableMenu(item) ||
                                              _vm.disableMenuItems,
                                            slug: item.slug,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.verticalNavMenuItemsMin,
                                                  expression:
                                                    "!verticalNavMenuItemsMin",
                                                },
                                              ],
                                              staticClass: "truncate",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(item.name)) + " "
                                              ),
                                            ]
                                          ),
                                          item.tag &&
                                          (_vm.isMouseEnter || !_vm.reduce)
                                            ? _c(
                                                "vs-chip",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    color: item.tagColor,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tag))]
                                              )
                                            : _vm._e(),
                                          item.showNewChip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vmenu--newfeature",
                                                },
                                                [_vm._v("New")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("v-nav-menu-group", {
                                          key:
                                            "group-" + index + "-" + item.slug,
                                          attrs: {
                                            openHover: _vm.openGroupHover,
                                            group: item,
                                            groupIndex: index,
                                            open: _vm.isGroupActive(item),
                                          },
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        }
                      ),
                      _c(
                        "div",
                        { staticClass: "navigation-header__group-heading" },
                        [_vm._v("Communication")]
                      ),
                      _vm._l(
                        _vm.filteredCommunicationNavMenuItemsLocal,
                        function (item, index) {
                          return [
                            item.header && !_vm.verticalNavMenuItemsMin
                              ? _c(
                                  "span",
                                  {
                                    key: "header-" + index + "-" + item.slug,
                                    staticClass: "navigation-header truncate",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.header) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : !item.header &&
                                (item.disallowedRoles
                                  ? !item.disallowedRoles.find(function (x) {
                                      return x === _vm.activeUserInfo.role
                                    })
                                  : true)
                              ? [
                                  !item.submenu
                                    ? _c(
                                        "v-nav-menu-item",
                                        {
                                          key:
                                            "item-" + index + "-" + item.slug,
                                          attrs: {
                                            featherIcon: item.featherIcon,
                                            index: index,
                                            to:
                                              item.slug !== "external"
                                                ? item.url
                                                : null,
                                            href:
                                              item.slug === "external"
                                                ? item.url
                                                : null,
                                            icon: item.icon,
                                            iconPack: item.iconPack,
                                            target: item.target,
                                            isDisabled:
                                              _vm.disableMenu(item) ||
                                              _vm.disableMenuItems,
                                            slug: item.slug,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.verticalNavMenuItemsMin,
                                                  expression:
                                                    "!verticalNavMenuItemsMin",
                                                },
                                              ],
                                              staticClass: "truncate",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(item.name)) + " "
                                              ),
                                            ]
                                          ),
                                          item.tag &&
                                          (_vm.isMouseEnter || !_vm.reduce)
                                            ? _c(
                                                "vs-chip",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    color: item.tagColor,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tag))]
                                              )
                                            : _vm._e(),
                                          item.showNewChip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vmenu--newfeature",
                                                },
                                                [_vm._v("New")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("v-nav-menu-group", {
                                          key:
                                            "group-" + index + "-" + item.slug,
                                          attrs: {
                                            openHover: _vm.openGroupHover,
                                            group: item,
                                            groupIndex: index,
                                            open: _vm.isGroupActive(item),
                                          },
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        }
                      ),
                      _vm.activeUserInfo &&
                      !["view-only", "vega-analyze"].includes(
                        _vm.activeUserInfo.role
                      )
                        ? _c(
                            "div",
                            { staticClass: "navigation-header__group-heading" },
                            [_vm._v("\n          Configuration\n        ")]
                          )
                        : _vm._e(),
                      _vm._l(
                        _vm.filteredConfigurationAndOtherNavMenuItemsLocal,
                        function (item, index) {
                          return [
                            item.header && !_vm.verticalNavMenuItemsMin
                              ? _c(
                                  "span",
                                  {
                                    key: "header-" + index + "-" + item.slug,
                                    staticClass: "navigation-header truncate",
                                  },
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(item.header) +
                                        "\n          "
                                    ),
                                  ]
                                )
                              : !item.header &&
                                (item.disallowedRoles
                                  ? !item.disallowedRoles.find(function (x) {
                                      return x === _vm.activeUserInfo.role
                                    })
                                  : true)
                              ? [
                                  !item.submenu
                                    ? _c(
                                        "v-nav-menu-item",
                                        {
                                          key:
                                            "item-" + index + "-" + item.slug,
                                          attrs: {
                                            featherIcon: item.featherIcon,
                                            index: index,
                                            to:
                                              item.slug !== "external"
                                                ? item.url
                                                : null,
                                            href:
                                              item.slug === "external"
                                                ? item.url
                                                : null,
                                            icon: item.icon,
                                            iconPack: item.iconPack,
                                            target: item.target,
                                            isDisabled:
                                              _vm.disableMenu(item) ||
                                              _vm.disableMenuItems,
                                            slug: item.slug,
                                          },
                                        },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "show",
                                                  rawName: "v-show",
                                                  value:
                                                    !_vm.verticalNavMenuItemsMin,
                                                  expression:
                                                    "!verticalNavMenuItemsMin",
                                                },
                                              ],
                                              staticClass: "truncate",
                                              attrs: { id: item.id },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(_vm.$t(item.name)) + " "
                                              ),
                                            ]
                                          ),
                                          item.tag &&
                                          (_vm.isMouseEnter || !_vm.reduce)
                                            ? _c(
                                                "vs-chip",
                                                {
                                                  staticClass: "ml-auto",
                                                  attrs: {
                                                    color: item.tagColor,
                                                  },
                                                },
                                                [_vm._v(_vm._s(item.tag))]
                                              )
                                            : _vm._e(),
                                          item.showNewChip
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "vmenu--newfeature",
                                                },
                                                [_vm._v("New")]
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      )
                                    : [
                                        _c("v-nav-menu-group", {
                                          key:
                                            "group-" + index + "-" + item.slug,
                                          attrs: {
                                            openHover: _vm.openGroupHover,
                                            group: item,
                                            groupIndex: index,
                                            open: _vm.isGroupActive(item),
                                          },
                                        }),
                                      ],
                                ]
                              : _vm._e(),
                          ]
                        }
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          ),
          !_vm.isVerticalNavMenuActive
            ? _c("div", {
                directives: [
                  {
                    name: "hammer",
                    rawName: "v-hammer:swipe",
                    value: _vm.onSwipeAreaSwipe,
                    expression: "onSwipeAreaSwipe",
                    arg: "swipe",
                  },
                ],
                staticClass: "v-nav-menu-swipe-area",
              })
            : _vm._e(),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }