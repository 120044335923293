<template>
  <div class="ui-cancelled-sub">
    <div class="ui-cancelled-sub__logout-wrapper">
      <vs-button color="primary" type="filled" @click="USER_LOGOUT"> {{ $t('vue.logout') }}</vs-button>
    </div>
    <div class="flex flex-col items-center w-full">
      <div class="ui-cancelled-sub__title">{{ $t('appsumo.appSumoAccountRefunded') }}</div>
      <div class="ui-cancelled-sub__subtitle">{{ $t('vue.pleaseChoosePlansToProceed') }}</div>
    </div>
    <AppSumoPlan />
  </div>
</template>
<script>
import { muser } from '@/mixins/muser'
import AppSumoPlan from '@/views/pages/appsumo/AppSumoPlan.vue'
export default {
  components: {
    AppSumoPlan
  },
  mixins: [muser]
}
</script>

<style lang="scss">
.ui-cancelled-sub {
  background: white;
  position: relative;
  min-height: 100vh;
  z-index: 51001;
  &__logout-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    padding-top: 30px;
    padding-right: 30px;
  }
  &-mollie-close-button {
    width: 15px;
    height: 15px;
    display: flex;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    &-icon {
      margin-left: -1px;
    }
  }
  .pa-bk-secondary {
    background: rgba(var(--vs-secondary), 1) !important;
    border-radius: 10px;
    color: #fff !important;
  }
  &__title {
    font-weight: 700;
    font-size: 54px;
    line-height: 54px;
    text-align: center;
    letter-spacing: -0.3px;
    color: #262629;
    margin-bottom: 20px;
    margin-top: 50px;
  }
  &__subtitle {
    font-weight: 400;
    font-size: 16px;
    line-height: 21px;
    text-align: center;
    letter-spacing: -0.09px;
    color: #868686;
    margin-bottom: 40px;
  }
}
</style>
